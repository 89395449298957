import kebabCase from "lodash/kebabCase";
import {
  StyledField,
  StyledLoading,
  StyledFieldIcon,
  StyledFieldError,
} from "../styles";
import Label from "@/components/uielements/label";

interface Props {
  isLoading?: boolean;
  fieldType: string;
  label: string;
  icon?: string;
  children?: any;
  error?: string | JSX.Element;
  styles?: object;
}

export const SecureField = ({
  isLoading = false,
  fieldType,
  label,
  icon,
  children,
  error,
  styles,
}: Props) => (
  <StyledField style={styles}>
    {isLoading && <StyledLoading data-testid="loading" />}
    <Label visuallyHidden={true} htmlFor={fieldType}>
      {label}
    </Label>
    {/* children - should be input element.
    if input element is not passed insert div to follow PCI proxy documentation
    https://docs.pci-proxy.com/collect-and-store-cards/capture-iframes*/}
    {children ? children : <div id={fieldType}></div>}
    {icon && <StyledFieldIcon icon={icon} type="placeholder" />}
    <StyledFieldError
      tabIndex={0}
      role="alert"
      id={`${kebabCase(fieldType)}-secure-field-error-message`}
      hidden={!error}
    >
      {error}
    </StyledFieldError>
  </StyledField>
);
