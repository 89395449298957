import styled from "styled-components";
import { LinkHoverCss } from "@/components/uielements/css";
import { LAYOUT } from "@/constants/styles";

export const StyledForm = styled.form`
  h2 {
    font-family: ${({ theme }) => theme.primary_font.medium.font_family};
    font-size: 24px;
    margin: 0;
    padding: 0 ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER}px;

    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 900;
      padding: ${LAYOUT.GUTTER / 2}px;
    }
  }

  > p {
    font-size: 15px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    margin: ${LAYOUT.GUTTER / 2}px;
    padding: ${LAYOUT.GUTTER}px;
  }
`;

export const StyledCityStateZip = styled.div`
  display: flex;

  > div {
    flex: 1;
    margin-left: ${LAYOUT.GUTTER}px;

    &:first-child {
      flex: 2;
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    display: block;
    > div {
      margin-left: 0;
    }
  }
`;

export const StyledInputResetButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: ${LAYOUT.GUTTER / 4}px;
  top: 0px;
  width: 22px;
  color: ${({ theme }) => theme.forms.placeholder_color};
  cursor: pointer;
  font-size: 20px;
  height: 100%;
`;

export const StyledAddAddressButton = styled.button`
  display: flex;
  background: none;
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  color: ${({ theme }) => theme.store_detail.active_color};
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin: ${LAYOUT.GUTTER / 1.5}px 0 ${LAYOUT.GUTTER}px 0;
  ${LinkHoverCss}
`;

export const StyledAutocompleteInstructions = styled.p`
  margin-top: 0;
  text-align: left;
`;
