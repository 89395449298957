import styled from "styled-components";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { Space } from "@/components/uielements/space/space";
import { Stack } from "@/components/uielements/stack/stack";
import { useDialogContext } from "@/utils/useDialogContext";

export const EmptyCart = () => {
  const { onCancel } = useDialogContext();

  return (
    <>
      <Space />

      <Stack
        align="center"
        position="center"
        direction="vertical"
        overrides={{
          borderBlockStart: "1px solid var(--sidecart__divider-color)",
          height: "100%",
          padding: "var(--size-6)",
        }}
      >
        <StringAccessor tag={Title} accessor="empty_cart.header" html={true} />
        <StringAccessor
          tag={Description}
          accessor="empty_cart.subheader"
          html={true}
        />

        <Button
          onClick={onCancel}
          arial-label="Close Basket"
          buttonTheme="secondary"
        >
          <StringAccessor accessor="empty_cart.back_cta" />
        </Button>
      </Stack>
    </>
  );
};

const Title = styled.h2`
  color: var(--sidecart__secondary-foreground-color);
  font-family: var(--config-secondary-font-family__bold);
  font-size: 2rem;
  line-height: 1.1;
  margin: 0;
  text-align: center;
`;

const Description = styled.span``;

const Button = styled(StyledPrimaryButton)`
  margin-top: var(--size-2);
  width: 100%;
`;
