import styled from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export const NavigationListOverrides = styled.div(({ theme }) => {
  const sectionProperties = getFontStyles(theme.android_drawer.section_font);
  return {
    "--navigation-item__border-color": theme.android_drawer.separator,
    "--navigation-item__color": sectionProperties.color,
    "--navigation-item__font-family": sectionProperties.fontFamily,
  };
});
