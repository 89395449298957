import { Facebook } from "./facebook";
import { Instagram } from "./instagram";
import { Tiktok } from "./tiktok";
import { Twitter } from "./twitter";
import { Youtube } from "./youtube";

export const Social = {
  Facebook,
  Instagram,
  Tiktok,
  Twitter,
  Youtube,
};
