import { type WebConfig } from "@koala/sdk";
import styled, { type StyledProps } from "styled-components";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { getSafeHoverColor } from "@/components/uielements/primaryButton/utils";
import { LAYOUT, ORDER, SPACING_SCALE } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

/**
 * @param   {string|object} arg
 * @param   {object}        [breakpoints]
 * @returns {*}
 */
const dietaryPreferencesButton = (isActive: boolean, theme: WebConfig) => {
  const { buttons } = theme;

  const fontStyles = getFontStyles(buttons.knockout_font, {
    color: (value) =>
      isActive ? buttons.knockout_button_text_hover_color : value,
  });

  return {
    backgroundColor: isActive
      ? buttons.knockout_button_background_hover_color
      : buttons.knockout_button_background_color,
    borderWidth: "1px",
    borderColor: isActive
      ? getSafeHoverColor(
          buttons.knockout_button_border_hover_color,
          buttons.knockout_button_border_color
        )
      : buttons.knockout_button_border_color,
    borderRadius: `${buttons.knockout_button_border_radius}px`,
    ...fontStyles,

    "@media (min-width: 768px)": {
      "&:hover": !isActive
        ? {
            background: buttons.knockout_button_background_hover_color,
            borderColor: getSafeHoverColor(
              buttons.knockout_button_border_hover_color,
              buttons.knockout_button_border_color
            ),
            color: buttons.knockout_button_text_hover_color,
          }
        : {},
    },
  };
};

interface StyledAllergenContainerProps {
  displayOnMobile: boolean;
}

export const StyledAllergenContainer = styled.div<StyledAllergenContainerProps>`
  margin: 0 0 0 ${LAYOUT.GUTTER}px;

  @media (max-width: 767px) {
    ${({ displayOnMobile }: StyledProps<StyledAllergenContainerProps>) =>
      !displayOnMobile &&
      `
      display: none;
    `}
  }
`;

export const StyledAllergens = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${LAYOUT.GUTTER / 2}px;
  margin: 0 auto;
  padding: ${LAYOUT.GUTTER}px 0 ${LAYOUT.GUTTER * 2}px;
  top: 0px;
  max-width: 770px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: ${LAYOUT.GUTTER / 2}px;
    padding: ${LAYOUT.GUTTER}px;
    div:first-child {
      button {
        border-width: 0;
      }
    }
  }
`;

export const StyledAllergensIntro = styled.p`
  border: 1px solid
    ${({ theme }) => theme.dietary_preferences_modal.border_color};
  border-left: none;
  border-right: none;
  display: none;
  margin: 0;
  padding: ${LAYOUT.GUTTER}px;

  @media (max-width: 767px) {
    display: block;
  }
`;

interface StyledAllergenButtonProps {
  isActive: boolean;
}

export const StyledAllergenButton = styled.button<StyledAllergenButtonProps>`
  transition: all 0.2s linear;

  ${({ isActive, theme }) => dietaryPreferencesButton(isActive, theme)}

  cursor: pointer;
  min-height: 60px;
  padding: 0 ${LAYOUT.GUTTER / 2}px;
  position: relative;
  width: calc(20% - 12px);

  svg {
    display: none;
  }

  @media (max-width: 767px) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    padding: ${LAYOUT.GUTTER / 2}px ${LAYOUT.GUTTER}px;
    text-align: left;
    width: 100%;

    svg {
      display: ${(props: { isActive: boolean }) =>
        props.isActive ? "block" : "none"};
    }
  }
`;

export const StyledGenericModal = styled.div`
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ORDER.MIGRATE_CART};
`;

export const StyledGenericModalContent = styled.div`
  background: ${({ theme }) =>
    theme.dietary_preferences_modal.background_color};
  border-radius: ${({ theme }) =>
    theme.dietary_preferences_modal.border_radius}px;
  margin: 0 auto;
  max-width: ${(props: { maxWidth?: number }) => props.maxWidth || 500}px;
  position: relative;
  top: 10%;
  width: 100%;
  max-height: 90%;
  padding: 20px 0 0 0;
  overflow: hidden;
  overflow-y: auto;

  @media (max-width: 767px) {
    height: 100vh;
    top: 0;
    max-width: none;
    max-height: none;
  }
`;

export const StyledCopy = styled.div(({ theme }) => ({
  padding: "20px",
  "h1, h3, p": {
    margin: "0",
    textAlign: "center",
  },
  "h3, p": {
    ...getFontStyles(theme.dietary_preferences_modal.font, [
      "color",
      "font_family",
      "font_size",
    ]),
  },
  h3: { paddingBottom: "40px" },
  h1: {
    ...getFontStyles(theme.dietary_preferences_modal.secondary_font, [
      "color",
      "font_family",
      "font_size",
    ]),
  },
  "@media (max-width: 767px)": {
    textAlign: "left",
    h1: {
      ...getFontStyles(
        theme.dietary_preferences_modal.secondary_font,
        ["font_size"],
        { font_size: (val) => (typeof val === "number" ? val / 1.5 : val) }
      ),
    },
    h3: { padding: "20px 0" },
  },
}));

export const StyledButton = styled(StyledPrimaryButton)`
  width: 100%;
`;

interface StyledLinkProps {
  isActive: boolean;
}

export const StyledLink = styled(StyledPrimaryButton)<StyledLinkProps>(
  ({ theme, isActive }) => {
    const { color } = getFontStyles(theme.buttons.knockout_font);

    return {
      ...dietaryPreferencesButton(isActive, theme),
      flex: 1,
      minWidth: `${SPACING_SCALE.XL}px`,
      overflow: "hidden",

      svg: { marginRight: "10px" },

      "@media (max-width: 767px)": {
        border: "none",
        padding: "0 10px",
        borderRadius: "50%",
        zIndex: 1,

        svg: {
          marginRight: 0,
        },

        span: {
          color: "transparent",
          position: "absolute",
          width: "1px",
        },

        "&:hover": {
          boxShadow: "none",
        },
      },

      "@media (min-width: 768px)": {
        "&:hover": {
          background: isActive
            ? theme.buttons.knockout_button_background_color
            : theme.buttons.knockout_button_background_hover_color,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: isActive
            ? theme.buttons.knockout_button_border_color
            : theme.buttons.knockout_button_border_hover_color ??
              theme.buttons.knockout_button_border_color,
          color: isActive
            ? color
            : theme.buttons.knockout_button_text_hover_color,
        },
      },
    };
  }
);

export const StyledFooter = styled.div(({ theme }) => ({
  padding: "20px 60px 20px",
  background: "rgba(#255, 0.3)",
  p: {
    ...getFontStyles(theme.dietary_preferences_modal.font, [
      "color",
      "font_family",
    ]),
    fontSize: "12px",
    margin: 0,
    paddingBottom: "20px",
  },
  "@media (max-width: 767px)": {
    padding: "20px 20px 120px",
  },
}));
