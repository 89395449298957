import { type Category, type MenuProduct } from "@koala/sdk";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import {
  Item,
  Title,
  Image,
  Units,
  Description,
  Details,
  QuickAddButton,
} from "./item.styles";
import Icon from "@/components/uielements/icon";
import { PRODUCT_LOCATION_LABELS } from "@/constants/checkout";
import commerceActions from "@/redux/commerce/actions";
import customizeActions from "@/redux/customize/actions";
import { toDollars } from "@/utils/basket";
import { getProductImageSrc, hasProductImage } from "@/utils/imageHelper";

interface Props {
  id: number;
  item: MenuProduct;
  upsellId: string | undefined;
  menuCategories: Category[];
  onView: (product: MenuProduct) => void;
}

export const Upsell = ({
  id,
  item,
  upsellId,
  onView,
  menuCategories,
}: Props) => {
  const dispatch = useDispatch();

  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      onView(item);
    }
  }, [inView]);

  const isImageShown = hasProductImage(item);

  return (
    <Item
      ref={ref}
      hasImage={isImageShown}
      data-id={item.id}
      id={`Cross-Sell-Item-${id}`}
      aria-label={`Click to view ${item.name} details`}
    >
      {isImageShown && (
        <Image
          onClick={() => {
            dispatch(commerceActions.commerceItemCrossSellClick(item, id));
            dispatch(
              customizeActions.setProduct({
                product: item,
                label: PRODUCT_LOCATION_LABELS.CROSS_SELL,
                upsellId,
                menuCategories,
              })
            );
          }}
          alt={item.name}
          src={getProductImageSrc(item, {
            height: 160,
            width: 160,
          })}
        />
      )}

      <Details
        // @ts-expect-error styled components issue
        hasImage={!!isImageShown}
      >
        <Title
          onClick={() => {
            dispatch(commerceActions.commerceItemCrossSellClick(item, id));
            dispatch(
              customizeActions.setProduct({
                product: item,
                menuCategories,
                label: PRODUCT_LOCATION_LABELS.CROSS_SELL,
                upsellId,
              })
            );
          }}
        >
          {item.name}
        </Title>

        <Description>{item.description}</Description>

        <Units>
          {item.cost !== 0 && <h3>{`$${toDollars(item.cost)}`}</h3>}
          {item.pretty_calories && <span>{item.pretty_calories}</span>}
        </Units>
      </Details>

      <QuickAddButton
        data-testid="add-cross-sell-item"
        onClick={() => {
          dispatch(commerceActions.commerceItemCrossSellClick(item, id));
          dispatch(
            customizeActions.setCrossSellQuickAdd(
              item,
              menuCategories,
              upsellId
            )
          );
        }}
      >
        <Icon.Plus title={item.name} />
      </QuickAddButton>
    </Item>
  );
};
