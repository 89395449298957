import { styled } from "@/components/content/stitches";
import { useConfigOverride } from "@/features/configs/configs";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface Props {
  children: React.ReactNode;
  label: string;
  name: string;
  required?: boolean;
  error?: string;
}

export function Base(props: Props) {
  const { font } = useConfigOverride("forms");
  const { children, label, name, required = false, error } = props;

  return (
    <Wrapper htmlFor={name}>
      {children}

      <Label
        css={{
          ...getFontStyles(font, [
            "color",
            "font_family",
            "font_weight",
            "font_style",
          ]),
        }}
      >
        {label}
        {required && "*"}
      </Label>

      {error ? (
        <Error
          css={{
            ...getFontStyles(font, ["font_family", "font_weight"]),
          }}
        >
          {error}
        </Error>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled("label", {
  alignItems: "center",
  display: "flex",
  position: "relative",
  width: "100%",
});

const Label = styled("span", {
  bottom: "1px",
  color: "$shade600",
  cursor: "text",
  fontFamily: "$primary",
  fontSize: "$text300",
  left: "1px",
  margin: 0,
  padding: "$3",
  position: "absolute",
  pointerEvents: "none",
  top: "1px",
  transformOrigin: "0 0",
  transition: "all .2s ease",
  zIndex: "$20",
});

export const Field = styled("input", {
  alignItems: "center",
  appearance: "none",
  backgroundColor: "transparent",
  border: "1px solid $shade300",
  borderRadius: "$md",
  boxSizing: "border-box",
  color: "$shade800",
  cursor: "text",
  flex: 1,
  fontFamily: "$primary",
  fontSize: "$text300",
  height: "$12",
  lineHeight: 1,
  margin: 0,
  minWidth: 0,
  padding: "$5-5 $3 $2",
  position: "relative",
  transition: "all .2s ease",
  zIndex: "$10",

  "&:focus": {
    borderColor: "$accentPrimary",
    outline: "none",
  },

  [`&:focus ~${Label}, &:not(:placeholder-shown) ~ ${Label}`]: {
    transform: "scale(.75) translate3d(5px, -7px, 0)",
  },
});

const Error = styled("span", {
  bottom: "1px",
  color: "$accentError",
  cursor: "text",
  fontFamily: "$primary",
  fontSize: "$text300",
  margin: 0,
  padding: "$3",
  position: "absolute",
  right: "1px",
  top: "1px",
  transform: "scale(.75) translate3d(-5px, -7px, 0)",
  transformOrigin: "100% 0",
  transition: "all .2s ease",
  zIndex: "$20",
});
