import { useRouter } from "next/router";
import { useEffect } from "react";
import LoadingBar from "react-redux-loading-bar";
import {
  AccessibilityAid,
  GlobalStyle,
  StyledContent,
  StyledLayout,
} from "./styles";
import { COMPONENT_CONFIG } from "@/brand-components";
import LoyaltyNav from "@/components/account/loyaltyNav";
import { Cart } from "@/components/cart/cart";
import { ContextualCart } from "@/components/cart/contextual-cart";
import { Footer } from "@/components/footer/footer";
import FulfillmentManager from "@/components/fulfillmentManager";
import { Header } from "@/components/header/header";
import { ReorderModal } from "@/components/reorders/modal";
import Toast from "@/components/toast";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { ROUTES } from "@/constants/routes";
import { ORDER } from "@/constants/styles";
import { Customizer } from "@/features/product-customizer";
import { useSelector } from "@/redux";
import { isAndroidShell, setAndroidCurrentPage } from "@/utils/android";
import { isBreakpointSizeMin } from "@/utils/breakpoints";

interface Props {
  backgroundOverride?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  pageName?: string;
}

export default function Layout({
  children,
  backgroundOverride,
  disabled,
  pageName,
}: Props) {
  const router = useRouter();
  const { slug } = useSelector((state) => state.app.organization.organization);
  const { webConfig } = useSelector((state) => state.app.cmsConfig);
  const { content } = useSelector((state) => state.app.basket);

  function navigateBackOnAndroid() {
    setAndroidCurrentPage(router.pathname, router.back, router.push);
  }

  useEffect(() => {
    if (isAndroidShell()) {
      window.KoalaAndroidShell.canGoBack(
        router.pathname === ROUTES.ANDROID ? false : true
      );
      window.addEventListener("android/navigateBack", navigateBackOnAndroid);
    }
    return () => {
      // we don't need to know about the android shell to remove an event tied to it
      window.removeEventListener("android/navigateBack", navigateBackOnAndroid);
    };
  }, []);

  const GlobalFloaterComponent = COMPONENT_CONFIG.GLOBAL_FLOATING[slug];
  const onLoyaltyPage = router.pathname.includes(ROUTES.ACCOUNT);
  const inCheckoutFlow = [
    ROUTES.CHECKOUT,
    ROUTES.ORDER_CONFIRMATION,
    ROUTES.ORDER_DETAIL,
  ].includes(router.pathname);
  const onAndroidSplashScreen = router.pathname.includes(ROUTES.ANDROID);
  const activeLoyaltyTab = router.pathname;

  return (
    <StyledLayout>
      <LoadingBar
        scope="page-transition"
        style={{
          backgroundColor: webConfig.global.loading_bar_color,
          left: 0,
          position: "fixed",
          top: 0,
          zIndex: ORDER.LOADING,
        }}
      />
      <AccessibilityAid href="#start-of-content">
        Skip to content
      </AccessibilityAid>
      <Header />
      {/* Only display loyalty nav on loyalty dashboard */}
      {onLoyaltyPage && <LoyaltyNav activeLoyaltyTab={activeLoyaltyTab} />}
      {/* Inject Global Styles */}
      <GlobalStyle
        hasContextualCart={content?.basket_items?.length > 0 ? true : false}
      />
      {/* Content gets inject here */}
      <StyledContent
        backgroundOverride={backgroundOverride}
        id="start-of-content"
        className={
          pageName &&
          `${CSS_CLASSES.GLOBAL.STYLED_CONTENT_CONTAINER}-${pageName}`
        }
      >
        {!disabled ? <div>{children}</div> : <span />}
      </StyledContent>
      {/* Generic Banner Toast */}
      <Toast />
      {/* Global Side Cart */}
      {typeof window !== "undefined" && <Cart />}
      <Customizer />
      {/* Global 'floating' Component */}
      {GlobalFloaterComponent && <GlobalFloaterComponent />}
      {/* Modal to reorder a past order */}
      <ReorderModal />
      {/* Modal to confirm delivery address or pickup */}
      <FulfillmentManager />
      {/* Conditional Footer */}
      {!isAndroidShell() && <Footer />}
      {!isBreakpointSizeMin("medium") &&
        !inCheckoutFlow &&
        !onAndroidSplashScreen && <ContextualCart />}
    </StyledLayout>
  );
}
