import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { AccountLink } from "./account-link";
import { Notification } from "./notification";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents } from "@/analytics/events/constants";
import LoyaltyAccessor from "@/components/account/loyaltyAccessor";
import { ConnectedPersonIcon } from "@/components/account/uielements";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { styled } from "@/components/content/stitches";
import { Signin } from "@/components/header/sign-in";
import { Box, Stack } from "@/components/ui";
import Icon from "@/components/uielements/icon";
import { LOYALTY_ACCESSOR_TYPES, LOYALTY_FEATURES } from "@/constants/loyalty";
import { LOYALTY_ROUTES, ROUTES } from "@/constants/routes";
import { useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { deriveReferrer } from "@/utils/global";
import { useAccount } from "@/utils/hooks";
import { safelyGetString } from "@/utils/stringHelpers";

interface Props {
  showDetailsByDefault: boolean;
  onSignOut: () => void;
}

export function AccountDetails({
  showDetailsByDefault = false,
  onSignOut,
}: Props) {
  // referrer setup
  const router = useRouter();
  const referrer = deriveReferrer(router);
  const cancelRedirect = router?.pathname.includes(ROUTES.ACCOUNT);
  const { strings, webConfig } = useSelector((state) => state.app.cmsConfig);
  const { color: fontColor } = getFontStyles(webConfig.modals.font, ["color"]);

  // account details
  const {
    loggedIn,
    userDetails,
    loyalty,
    points,
    availableRewards,
    unreadMessages,
    logout,
  } = useAccount();

  const [showDetails, toggleDetails] = useState(showDetailsByDefault);

  return (
    <Container
      css={{
        ...getFontStyles(webConfig.header.font, ["font_size"]),
        color: fontColor,

        "& button": {
          color: fontColor,
        },
      }}
    >
      {!loggedIn && (
        <Link href={`${ROUTES.LOGIN}?referrer=${referrer}`}>
          <a
            onClick={() =>
              genericEventHandler(GlobalEvents.AUTH__CLICK_SIGN_IN)
            }
          >
            <Signin>
              {safelyGetString(strings, "global_header.sign_in_cta")}
            </Signin>
          </a>
        </Link>
      )}

      {loggedIn && (
        <>
          <ToggleLinks
            css={{
              color: fontColor,
            }}
            onClick={() => {
              if (!showDetailsByDefault) {
                toggleDetails(!showDetails);
              }
            }}
          >
            <Stack data-css-override="StyledLinkWithIcon">
              <ConnectedPersonIcon /> Hi, {userDetails?.first_name}
            </Stack>

            {!showDetailsByDefault && (
              <Box
                css={{
                  height: "$6",
                  transform: showDetails ? "rotate(-90deg)" : "rotate(90deg)",
                  width: "$6",
                }}
              >
                <Icon.Caret title="Down arrow" />
              </Box>
            )}
          </ToggleLinks>

          <Box
            css={{
              paddingLeft: "$9",
            }}
          >
            <LoyaltyAccessor
              checkType={LOYALTY_ACCESSOR_TYPES.FEATURE}
              checkName={LOYALTY_FEATURES.SHOW_LOYALTY_STATE}
              component={
                <Stack
                  align="leading"
                  direction="vertical"
                  gap="0"
                  css={{ fontSize: ".875em" }}
                >
                  {loyalty?.membership_level_name && (
                    <span>{loyalty?.membership_level_name}</span>
                  )}

                  {points.value > 0 && (
                    <StringAccessor
                      accessor="rewards.account_menu_points_subheader"
                      html={true}
                      tag="span"
                      dataObj={{
                        number: points.value.toLocaleString(),
                        counter: points.message,
                      }}
                    />
                  )}
                </Stack>
              }
            />

            <Stack
              direction="vertical"
              align="leading"
              css={{
                height: showDetails ? "auto" : 0,
                overflow: "hidden",
                padding: "$4 0 0",
              }}
            >
              <AccountLink
                accessor={LOYALTY_ROUTES.REWARDS}
                href={LOYALTY_ROUTES.REWARDS}
                aria-label="Go to Rewards page"
              >
                <Stack>
                  <StringAccessor accessor="rewards.title" />

                  {availableRewards > 0 && (
                    <Notification>{availableRewards}</Notification>
                  )}
                </Stack>
              </AccountLink>

              <AccountLink
                accessor={LOYALTY_ROUTES.INBOX}
                href={LOYALTY_ROUTES.INBOX}
                aria-label="Go to Inbox page"
              >
                <StringAccessor accessor="inbox.title" />
                {unreadMessages > 0 && (
                  <Notification>{unreadMessages}</Notification>
                )}
              </AccountLink>

              <AccountLink
                accessor={LOYALTY_ROUTES.FAVORITES}
                href={LOYALTY_ROUTES.FAVORITES}
                aria-label="Go to Favorites page"
              >
                <StringAccessor accessor="favorites.title" />
              </AccountLink>

              <AccountLink
                accessor={LOYALTY_ROUTES.ORDER_HISTORY}
                href={LOYALTY_ROUTES.ORDER_HISTORY}
                aria-label="Go to Order History page"
              >
                <StringAccessor accessor="order_history.title" />
              </AccountLink>

              <AccountLink
                accessor={LOYALTY_ROUTES.ACCOUNT}
                href={LOYALTY_ROUTES.ACCOUNT}
                aria-label="Go to Account page"
              >
                <StringAccessor accessor="account.title" />
              </AccountLink>

              <AccountLink
                href="#"
                aria-label="Click to Sign Out"
                onClick={(e) => {
                  e.preventDefault();
                  logout(cancelRedirect);
                  onSignOut();
                }}
              >
                <StringAccessor accessor="account.sign_out_button" />
              </AccountLink>
            </Stack>
          </Box>
        </>
      )}
    </Container>
  );
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  fontFamily: "$primaryBold",
  fontSize: "$primary",
  justifyContent: "flex-end",
  margin: "-$12 0 0",
  padding: "$24 $4 $4 $3-5",
});

const ToggleLinks = styled("button", {
  alignItems: "center",
  background: "none",
  border: "none",
  display: "flex",
  justifyContent: "space-between",
  padding: 0,
});
