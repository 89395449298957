import * as Dialog from "@radix-ui/react-dialog";
import { readableColor, transparentize } from "polished";
import styled, { type StyledProps } from "styled-components";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { MODIFIER_IMAGE_DISPLAY_TYPES } from "@/constants/productOptions";
import { COLORS, LAYOUT, ORDER } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safePolished } from "@/utils/safePolished";

export const StyledCustomizeModal = styled.div`
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform-style: preserve-3d;
  z-index: ${ORDER.CUSTOMIZE};
`;

export const Overlay = styled(Dialog.Overlay)`
  background: rgba(26, 26, 26, 0.16);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s ease;
  z-index: 0;
`;

export const CustomizeModalContent = styled(Dialog.Content)`
  height: 100%;
  padding: 0 0 env(safe-area-inset-bottom);
  width: 100%;
`;

export const StyledAddDetails = styled.div.attrs({
  "data-css-override": "StyledAddDetails",
})`
  align-items: center;
  flex-direction: row-reverse;
  display: flex;
  justify-content: right;
  margin: 0 0 ${LAYOUT.GUTTER}px;

  > p {
    flex: 1;
    font-family: ${({ theme }) => theme.primary_font.medium.font_family};
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    text-align: right;
  }

  > span {
    font-size: 16px;
    margin: 0 ${LAYOUT.GUTTER}px;
  }

  @media (max-width: 767px) {
    margin-bottom: ${LAYOUT.GUTTER / 2}px;
  }
`;

export const StyledAddToBag = styled(StyledPrimaryButton)`
  width: 100%;

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
    cursor: not-allowed;
    font-style: italic;
  `}

  @media (min-width: 768px) {
    height: 60px;
    line-height: 60px;
  }
`;

export const StyledCustomizeContainer = styled.div(({ theme }) => {
  return {
    background: theme.modals.background_color ?? "white",
    borderRadius: `${theme.modals.border_radius ?? 0}px`,
    height: "100%",
    maxHeight: `calc(100vh - ${LAYOUT.GUTTER * 4}px)`,
    margin: "40px auto",
    maxWidth: `${LAYOUT.CUSTOMIZE_MODAL_MAX_WIDTH}px`,
    overflow: "hidden",
    position: "relative",
    width: "100%",
    ...getFontStyles(theme.modals.font, ["color"]),
    "@media (max-width: 767px)": {
      borderRadius: 0,
      margin: 0,
      maxHeight: "100vh",
    },
  };
});

const FIXED_MOBILE_FOOTER_HEIGHT = 110;
export const StyledCustomizeScrollContent = styled.div`
  height: auto;
  max-height: calc(100vh - ${LAYOUT.GUTTER * 4 + 150}px);
  margin-bottom: ${LAYOUT.CUSTOMIZE_FOOTER_MAX_HEIGHT}px;
  min-height: 400px;
  overflow-y: scroll;
  padding-bottom: 40px; // This accounts for mobile browser footers that sometimes appear

  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
  -webkit-overflow-scrolling: touch;

  @media (max-width: 767px) {
    height: 100vh;
    max-height: calc(100vh - ${FIXED_MOBILE_FOOTER_HEIGHT}px);
    padding-bottom: ${FIXED_MOBILE_FOOTER_HEIGHT}px;
  }
`;

export const StyledCustomizeFooter = styled.div`
  ${({ theme }) => `
    background: ${theme.modals.footer_color || theme.modals.background_color};
    border-top: 1px solid ${theme.modals.border_color};
    box-shadow: 0 0 20px 0 ${theme.modals.border_color};
  `}

  bottom: 0px;
  // Ensure box shadow only applies to the top of the footer
  clip-path: inset(-20px 0px 0px 0px);
  padding: ${LAYOUT.GUTTER}px;
  position: absolute;
  width: 100%;

  // Remove top padding if toast error is visible
  ${(props: { validationErrorIsVisible: boolean }) =>
    props.validationErrorIsVisible &&
    `
    padding-top: 0px;
  `}

  @media (max-width: 767px) {
    padding: ${LAYOUT.GUTTER / 2}px;
  }
`;

export const StyledToastError = styled.div`
  color: ${COLORS.ERROR_RED};
  background-color: ${COLORS.ERROR_RED_BACKGROUND};
  height: 50px;
  text-align: center;
  font-size: 14px;
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};
  display: ${(props: { isVisible: boolean }) =>
    props.isVisible ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  margin: ${`0 -${LAYOUT.GUTTER}px ${LAYOUT.GUTTER}px -${LAYOUT.GUTTER}px`};
`;

export const StyledPdpCloseButton = styled.button`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: ${LAYOUT.GUTTER}px;
  top: ${LAYOUT.GUTTER / 2}px;
  vertical-align: middle;
  width: 40px;
  z-index: 1;
`;

export const StyledTopOptionContainer = styled.div`
  margin: 0 ${LAYOUT.GUTTER}px 0;
  position: relative;
`;

export const StyledNestedOptionContainer = styled.div`
  width: 100%;

  &:first-child {
    > div {
      border: none;
    }
  }
`;

export const StyledOptionGroupHeader = styled.div(({ theme }) => {
  return {
    borderTop: `1px solid ${theme.global.primary_border_color}`,
    padding: "20px 0",
    marginBottom: "5px",
    "h3,p": {
      ...getFontStyles(theme.modals.font, [
        "color",
        "font_family",
        "font_weight",
        "line_height",
      ]),
    },
    h3: {
      fontSize: "18px",
      margin: 0,
      textAlign: "left",

      // 'Required' text
      span: {
        color: theme.global.primary_active_color,
        fontSize: "12px",
        paddingLeft: "10px",
      },
    },

    p: {
      fontSize: "14px",
      margin: "5px 0 0",
    },
  };
});

export const StyledOptionGroupHelperText = styled.div`
  color: ${COLORS.ERROR_RED};
  position: absolute;
  margin-top: ${LAYOUT.GUTTER / 4}px;
`;

export const StyledAdvNestedModOptionGroupHelperText = styled.div`
  color: ${COLORS.ERROR_RED};
  margin-top: ${LAYOUT.GUTTER / 4}px;
  align-self: flex-start;
`;

export const StyledOptionQuantityHelperText = styled.div.attrs({
  "data-css-override": "StyledOptionQuantityHelperText",
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

interface StyledOptionContainerProps {
  groupHasImages?: boolean;
  is_inverted: boolean;
  is_adv_nested_modifier_group: boolean;
  selected: boolean;
  bleedConfigWithImagesInGroup?: boolean;
}

export const StyledOptionContainer = styled.div.attrs<StyledOptionContainerProps>(
  ({ groupHasImages, theme }) => {
    // Modifier image is in 'bleed' configuration, and images exist for that group
    const bleedConfigWithImagesInGroup =
      theme.product_detail.modifier_image_layout ===
        MODIFIER_IMAGE_DISPLAY_TYPES.BLEED && groupHasImages;

    return { bleedConfigWithImagesInGroup };
  }
)<StyledOptionContainerProps>`
  ${({ is_inverted, selected, theme, is_adv_nested_modifier_group }) =>
    is_adv_nested_modifier_group
      ? `
    background: ${
      selected
        ? theme.buttons.primary_button_background_color
        : theme.buttons.knockout_button_background_color
    };
    button p {
      color: ${
        selected
          ? safePolished(readableColor)(
              theme.buttons.knockout_button_text_color
            )
          : safePolished(readableColor)(theme.buttons.primary_button_text_color)
      };
    }

    border: ${selected ? "2px" : "1px"} solid ${
          (is_inverted ? !selected : selected)
            ? theme.modals.active_border_color
            : theme.modals.border_color
        };
    border-radius: ${theme.modals.border_radius}px;
    padding: ${selected ? "0px" : "1px"};
  `
      : `
    background: ${theme.modals.background_color};
    border: ${selected ? "2px" : "1px"} solid ${
          (is_inverted ? !selected : selected)
            ? theme.modals.active_border_color
            : theme.modals.border_color
        };
    border-radius: ${theme.modals.border_radius}px;
    padding: ${selected ? "0px" : "1px"};
  `}

  box-shadow: 0 2px 0 0 rgba(237, 237, 237, 0.6);
  margin: 0 ${LAYOUT.GUTTER / 2}px ${LAYOUT.GUTTER}px;
  position: relative;
  text-align: left;
  width: calc(33.33% - ${(LAYOUT.GUTTER * 2) / 3}px);
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    ${({
      bleedConfigWithImagesInGroup,
    }: StyledProps<StyledOptionContainerProps>) =>
      !bleedConfigWithImagesInGroup &&
      `
      &:nth-of-type(3n + 4) {
        margin-left: 0;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    `}

    ${({
      bleedConfigWithImagesInGroup,
    }: StyledProps<StyledOptionContainerProps>) =>
      bleedConfigWithImagesInGroup &&
      `
      &:nth-of-type(4n + 5) {
        margin-left: 0;
      }
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    `}
  }

  // Bleed w/ images
  ${({ bleedConfigWithImagesInGroup }) =>
    bleedConfigWithImagesInGroup &&
    `
    width: calc(25% - ${(LAYOUT.GUTTER * 3) / 4}px);
  `}

  // Bleed & Inline
  @media (max-width: 767px) {
    width: calc(50% - ${LAYOUT.GUTTER / 4}px);
    margin: 0 ${LAYOUT.GUTTER / 4}px ${LAYOUT.GUTTER / 2}px;

    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: ${LAYOUT.GUTTER}px;
    }

    &:nth-of-type(even) {
      margin-right: 0;
    }
    &:nth-of-type(odd) {
      margin-left: 0;
    }
  }

  @media (max-width: 577px) {
    &:nth-last-child(2) {
      margin-bottom: ${LAYOUT.GUTTER / 2}px;
    }

    // Inline || Bleed w/ images
    ${({ groupHasImages, theme }: StyledProps<StyledOptionContainerProps>) =>
      (theme.product_detail.modifier_image_layout ===
        MODIFIER_IMAGE_DISPLAY_TYPES.INLINE ||
        (theme.product_detail.modifier_image_layout ===
          MODIFIER_IMAGE_DISPLAY_TYPES.BLEED &&
          !groupHasImages)) &&
      `
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
    `}
  }
`;

interface StyledQuantitySelectorProps {
  selected: boolean;
}

export const StyledQuantitySelector = styled.div<StyledQuantitySelectorProps>`
  background: none;
  display: flex;
  min-height: ${LAYOUT.QUANTITY_SELECTOR_HEIGHT}px;
  max-width: 100%;
  justify-content: center;
  border-top: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.modals.active_border_color : COLORS.LIGHTGRAY};
  align-items: center;
`;

interface StyledChangeQuantityButtonProps {
  increment: boolean;
}

export const StyledChangeQuantityButton = styled.button<StyledChangeQuantityButtonProps>`
  border: none;
  background: none;
  color: ${({ disabled, theme }) =>
    disabled ? COLORS.LIGHTGRAY : theme.modals.active_border_color};
  cursor: pointer;
  margin: 0 ${LAYOUT.GUTTER}px;
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  font-size: 24px;
  flex: 1;
  text-align: ${({ increment }) => (increment ? "left" : "right")};
`;

interface StyledOptionCardProps {
  groupHasImages?: boolean;
  bleedConfigWithImagesInGroup?: boolean;
  hasAdvancedModOptions?: boolean;
}

export const StyledOptionCard = styled.button.attrs<StyledOptionCardProps>(
  ({ groupHasImages, theme }) => {
    // Modifier image is in 'bleed' configuration, and images exist for that group
    const bleedConfigWithImagesInGroup =
      theme.product_detail.modifier_image_layout ===
        MODIFIER_IMAGE_DISPLAY_TYPES.BLEED && groupHasImages;

    return { bleedConfigWithImagesInGroup };
  }
)<StyledOptionCardProps>`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex: auto;
  text-align: left;
  width: 100%;

  ${({ hasAdvancedModOptions }) =>
    hasAdvancedModOptions &&
    `
    flex-direction: column;
  `}

  // Bleed w/ images
  ${({ bleedConfigWithImagesInGroup }) =>
    bleedConfigWithImagesInGroup &&
    `
    align-items: stretch;
    flex-direction: column;
    padding: 0;
  `}
`;

interface StyledModifierImageProps {
  url?: string;
  supportsQuantitySelection: boolean;
}

export const StyledModifierImage = styled.div<StyledModifierImageProps>`
  // Conditionally render background image
  ${({ url }) => url && `background-image: url(${url})`};
  background-repeat: no-repeat;
  ${({ url }) => !url && `background-color: ${COLORS.BACKGROUNDGRAY}`};
  background-size: cover;
  background-position: center center;

  // Default Bleed
  ${({ theme }) =>
    theme.product_detail.modifier_image_layout ===
      MODIFIER_IMAGE_DISPLAY_TYPES.BLEED &&
    `
    border-radius: ${theme.global.border_radius}px ${theme.global.border_radius}px 0 0;
    width: 100%;
    height: 150px;
  `}

  // Default Inline
  ${({ theme }) =>
    theme.product_detail.modifier_image_layout ===
      MODIFIER_IMAGE_DISPLAY_TYPES.INLINE &&
    `
    border-radius: ${theme.global.border_radius}px 0 0 ${theme.global.border_radius}px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 80px;
  `}
`;

interface StyledOptionWrapperProps {
  groupHasImages?: boolean;
  bleedConfigWithImagesInGroup?: boolean;
}

export const StyledOptionWrapper = styled.div.attrs<StyledOptionWrapperProps>(
  ({ groupHasImages, theme }) => {
    // Modifier image is in 'bleed' configuration, and images exist for that group
    const bleedConfigWithImagesInGroup =
      theme.product_detail.modifier_image_layout ===
        MODIFIER_IMAGE_DISPLAY_TYPES.BLEED && groupHasImages;

    return { bleedConfigWithImagesInGroup };
  }
)<StyledOptionWrapperProps>`
  width: 100%;
  position: relative;

  // Bleed w/ images
  ${({ bleedConfigWithImagesInGroup }) =>
    bleedConfigWithImagesInGroup ? `display: block` : `display: flex`};
`;

interface StyledOptionContentProps {
  groupHasImages?: boolean;
  selected?: boolean;
  is_inverted?: boolean;
}

export const StyledOptionContent = styled.div.attrs({
  "data-css-override": "StyledOptionContent",
})<StyledOptionContentProps>`
  padding: ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER}px
    ${LAYOUT.GUTTER / 1.25}px;
  position: relative;
  width: 100%;

  // Bleed
  ${({ theme }) =>
    theme.product_detail.modifier_image_layout ===
      MODIFIER_IMAGE_DISPLAY_TYPES.BLEED &&
    `
    // Nothing fancy occurs here
  `}

  // Inline
  ${({ groupHasImages, theme }) =>
    theme.product_detail.modifier_image_layout ===
      MODIFIER_IMAGE_DISPLAY_TYPES.INLINE &&
    groupHasImages &&
    `
    margin-left: 80px;
  `}

  h4 {
    color: ${({ selected, is_inverted, theme }) =>
      (is_inverted ? !selected : selected)
        ? theme.modals.active_font_color
        : theme.modals.font.color};
    font-family: ${({ theme }) => theme.primary_font.medium.font_family};
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.modals.font.color};
    margin: 5px 0 0;
  }
`;

export const StyledOptionName = styled.div`
  display: flex;
  p {
    font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  }
`;

export const StyledAdvancedOptionGroup = styled.div`
  display: flex;
  width: 100%;
  margin: auto ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER}px;

  ${StyledOptionContainer} {
    flex: 1 1 0px;
    margin: 0;
    border-radius: 0 0 0 0;

    &:first-child {
      border-radius: ${({ theme }) =>
        `${theme.buttons.primary_button_border_radius}px 0 0 ${theme.buttons.primary_button_border_radius}px `};
    }

    &:last-child {
      border-radius: ${({ theme }) =>
        `0 ${theme.buttons.primary_button_border_radius}px ${theme.buttons.primary_button_border_radius}px 0`};
    }
  }

  ${StyledOptionCard} {
    padding: 2px;
    text-align: center;
  }

  ${StyledOptionContent} {
    padding: 0;
    text-align: center;

    ${StyledOptionName} {
      text-align: center;
      display: inline;

      p {
        margin: 10px 0;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        box-orient: vertical;
      }
    }
  }
`;

export const StyledOptionLabel = styled.div`
  img {
    height: 15px;
    margin: 2px 0 0;
    padding-left: 6px;
  }
`;

export const StyledOptionGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${LAYOUT.GUTTER / 4}px;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const StyledWarningLabel = styled.div`
  background: ${({ theme }) =>
    safePolished(transparentize)(".8", theme.modals.font.color)};
  padding: ${LAYOUT.GUTTER}px;

  div {
    display: flex;
    margin: 0 0 ${LAYOUT.GUTTER / 2}px;

    &:last-of-type {
      margin: 0;
    }
  }

  p {
    color: ${({ theme }) => theme.modals.font.color};
    margin: 0;
  }

  img {
    margin: 3px ${LAYOUT.GUTTER / 4}px 0 0;
    max-height: 15px;
  }
`;

interface StyledCustomizeProductDetailProps {
  customizable: boolean;
}

export const StyledCustomizeProductDetail = styled.div<StyledCustomizeProductDetailProps>`
  border-bottom: solid 1px
    ${({ customizable, theme }) =>
      customizable ? theme.modals.border_color : "transparent"};

  // Full-bleed image layout
  ${({ theme }) =>
    theme.product_detail.image_layout === "bleed" &&
    `
    display: block;
  `}

  // Inline image layout - only applies to desktop/tablet
  ${({ theme }) =>
    theme.product_detail.image_layout === "inline" &&
    `
    display: flex;
  `}

  @media (max-width: 577px) {
    display: block;
  }
`;

export const StyledCustomizeProductInfo = styled.div(({ theme }) => {
  return {
    padding: "20px",
    width: "100%",

    "& > div": {
      marginBottom: "10px",
    },

    "& div": {
      alignItems: "baseline",
      display: "flex",

      "& > h2": {
        flex: 1,
      },
    },

    h2: {
      fontSize: `${
        theme.product_detail.image_layout === "bleed" ? "32px" : "24px"
      }`,
      margin: "0 40px 0 0",
      textAlign: "left",
      ...getFontStyles(theme.modals.secondary_font, [
        "color",
        "font_family",
        "font_weight",
        "font_style",
        "line_height",
      ]),
    },

    p: {
      margin: "10px 0 0",
      ...getFontStyles(theme.modals.font),

      a: {
        textDecoration: "underline",
        transition: "opacity 0.2s linear",

        "&:hover": {
          opacity: 0.7,
        },
      },
    },
  };
});

interface ProductImageProps {
  src: string;
}

export const StyledProductImage = styled.div.attrs({
  "data-css-override": "StyledProductImage",
})<ProductImageProps>`
  width: 100%;
  height: 320px;
  background-image: ${({ src }) => src && `url(${src})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  // Full-bleed image layout
  ${({ theme }) =>
    theme.product_detail.image_layout === "bleed" &&
    `
    background-color: #d8d8d8;
  `}

  @media (min-width: 576px) {
    // Inline image layout - only applies to desktop/tablet
    ${({ theme }) =>
      theme.product_detail.image_layout === "inline" &&
      `
      background-position: top center;
      background-size: contain;
      border: ${LAYOUT.GUTTER}px solid white;
      border-right: none;
      max-width: 300px;
  `}
  }
`;

export const StyledWarningLabels = styled.span`
  img {
    height: 20px;
    padding-left: 5px;
  }
`;
