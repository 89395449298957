import Link from "next/link";
import { Fragment, useState } from "react";
import styled from "styled-components";
import { CaretIcon } from "./icons/caret";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { Box, Stack } from "@/components/ui";
import { Render } from "@/components/uielements/render";
import { ROUTES } from "@/constants/routes";
import { useOrganization } from "@/features/configs/organization";
import { useLocation } from "@/features/locations/service";
import { PhoneIcon } from "@/features/menu-availability/icons/phone";
import { useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { selectConveyance } from "@/redux/conveyanceMode/reducer";
import {
  deriveWeeklyHours,
  getOperatingHoursForLocation,
  googleMapsUrl,
} from "@/utils/locations";

export function LocationDetails() {
  const [showDetails, setShowDetails] = useState(false);
  const { location } = useSelector(selectConveyance);
  const { data, isSuccess } = useLocation(location);
  const { data: orgData } = useOrganization();
  const isSingleLocationEnabled = Boolean(
    orgData?.locations.features.isSingleLocation.enabled
  );

  if (!isSuccess) {
    return null;
  }

  const { street_address, city, zip_code } = data.data;
  const hours = deriveWeeklyHours(
    getOperatingHoursForLocation(data.data),
    data.data.cached_data?.utc_offset ?? 0
  );

  return (
    <>
      <ToggleLocationDetails
        onClick={() => {
          setShowDetails(!showDetails);
        }}
      >
        <Address>
          <Stack position="between">
            <Location>{data.data.label}</Location>

            <Box
              css={{
                flex: "0 0 .875rem",
                transform: `rotate(${showDetails ? "180deg" : "0deg"})`,
              }}
            >
              <CaretIcon direction="down" />
            </Box>
          </Stack>

          <ViewAddress
            target="_blank"
            href={googleMapsUrl(data.data.cached_data)}
          >
            {street_address}, {city}, {data.data.cached_data?.state}, {zip_code}
          </ViewAddress>
        </Address>
      </ToggleLocationDetails>

      <Box
        css={{
          display: showDetails ? "block" : "none",
          margin: "1rem 0 0",
        }}
      >
        {data.data.phone_number && (
          <Phone href={`tel:${data.data.phone_number}`}>
            {data.data.phone_number}

            <PhoneIcon />
          </Phone>
        )}

        <Hours>
          {hours?.map((item) => (
            <Fragment key={item.weekday}>
              <Weekday isSelected={item.isSelected}>{item.weekday}</Weekday>

              <Time isSelected={item.isSelected}>
                {!Boolean(item.slots.length) && <li>Closed</li>}

                {item.slots.map((slot, index) => {
                  if (!slot.end) {
                    return <li key={index}>Closed</li>;
                  }

                  return (
                    <Fragment key={index}>
                      <li>
                        <time dateTime={slot.start}>{slot.start}</time>
                        {" – "}
                        <time dateTime={slot.end}>{slot.end}</time>
                      </li>
                    </Fragment>
                  );
                })}
              </Time>
            </Fragment>
          ))}
        </Hours>
      </Box>

      <Render
        condition={!isSingleLocationEnabled && data.data.supports_pick_up}
      >
        <Link passHref href={ROUTES.LOCATIONS}>
          <ChangeLocation>
            <StringAccessor accessor="cart_checkout.change_location_cta" />
          </ChangeLocation>
        </Link>
      </Render>
    </>
  );
}

const ToggleLocationDetails = styled.button({
  all: "unset",
  outline: "revert",
  width: "100%",
});

const Location = styled.span(({ theme }) => ({
  fontWeight: 700,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  ...getFontStyles(theme.header.font, [
    "font_family",
    "font_size",

    "line_height",
  ]),
  ...getFontStyles(theme.modals.font, ["color"]),
}));

const Address = styled.address(({ theme }) => ({
  all: "unset",
  fontSize: ".875rem",
  ...getFontStyles(theme.header.font, ["font_family", "line_height"]),
  ...getFontStyles(theme.modals.font, ["color"]),
}));

const ViewAddress = styled.a({
  color: "inherit",
  textDecoration: "underline",
  transition: "all .2s ease",

  "&:hover": {
    opacity: 0.6,
  },
});

const ChangeLocation = styled.a(({ theme }) => ({
  display: "block",
  color: theme.global.primary_active_color,
  margin: "1rem 0 0",
  fontWeight: 700,
  ...getFontStyles(
    theme.header.font,
    ["font_family", "font_size", "font_style"],
    {
      font_size: (value) => {
        if (typeof value === "number") {
          // shrink the size to 87.5% of the primary font size
          // but ensure a minimum of .875rem (14pt)
          return `max(0.875rem, ${value * 0.875}px)`;
        }

        return value;
      },
    }
  ),

  "&:hover": {
    textDecoration: "underline",
  },
}));

const Phone = styled.a(({ theme }) => ({
  ...getFontStyles(theme.header.font, [
    "font_size",
    "font_style",
    "font_family",
    "font_weight",
    "line_height",
  ]),
  ...getFontStyles(theme.modals.font, ["color"]),
  display: "flex",
  justifyContent: "space-between",

  "&:hover": {
    textDecoration: "underline",
  },
}));

const Hours = styled.div(({ theme }) => ({
  ...getFontStyles(theme.header.font, [
    "font_size",
    "font_style",
    "font_family",
    "font_weight",
    "line_height",
  ]),
  ...getFontStyles(theme.modals.font, ["color"]),
  display: "grid",
  gridTemplateColumns: "minmax(min-content, auto) max-content",
  gap: ".5rem",
  margin: "1.5rem 0 0",
}));

const Weekday = styled.span<{ isSelected: boolean }>(
  ({ isSelected = false }) => ({
    fontWeight: isSelected ? 700 : 400,
  })
);

const Time = styled.ul<{ isSelected: boolean }>(({ isSelected = false }) => ({
  fontWeight: isSelected ? 700 : 400,
  listStyleType: "none",
  margin: 0,
  padding: 0,
}));
