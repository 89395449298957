import { CONVEYANCE_TYPES } from "@koala/sdk/v4";
import * as Popover from "@radix-ui/react-popover";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "./button";
import { ConveyanceDetails } from "./conveyance-details";
import { DeliveryIcon } from "./icons/delivery";
import { PickupIcon } from "./icons/pickup";
import { LocationDetails } from "./location-details";
import { TimePicker } from "./time-picker";
import { Stack } from "@/components/ui";
import { DATE_FORMAT } from "@/constants/dates";
import { API_CONVEYANCE_TYPES } from "@/constants/events";
import { useLocation } from "@/features/locations/service";
import { useDispatch, useSelector } from "@/redux";
import conveyanceModeActions from "@/redux/conveyanceMode/actions";
import { selectConveyance } from "@/redux/conveyanceMode/reducer";
import { formatDate, isDaySame } from "@/utils/dates";

export function getFulfillmentTime(time: string | undefined) {
  if (!time || time === "asap") {
    return "Today, ASAP";
  }

  if (isDaySame(new Date(), time)) {
    return `Today, ${formatDate(time, DATE_FORMAT.HOURS_WITH_MINUTES)}`;
  }

  return formatDate(time, DATE_FORMAT.DAY_MONTH_TIME);
}

export function Conveyance() {
  const [popoverVisible, setPopoverVisibility] = useState(false);
  const { type, address, time_wanted, location } =
    useSelector(selectConveyance);
  const { data, isSuccess } = useLocation(location);
  const dispatch = useDispatch();

  /*
   * Setting delivery conveyance without adding an address can cause
   * a visual bug where the handoff UI shows a loading state
   *
   * This happens both when closing the popover and refreshing (both without
   * setting an address). We check for this unique pattern with a `useEffect`
   * that resets the conveyance mode if we find ourselves in this state.
   */
  useEffect(() => {
    if (
      !popoverVisible &&
      type === CONVEYANCE_TYPES.DELIVERY &&
      !address?.street_address &&
      isSuccess
    ) {
      dispatch(conveyanceModeActions.clearDeliveryAddress(data.data.id));

      dispatch(
        conveyanceModeActions.conveyanceModeSet(
          {
            type: API_CONVEYANCE_TYPES.PICKUP,
            details: [],
          },
          time_wanted
        )
      );
    }
  });

  return (
    <Popover.Root
      modal={true}
      onOpenChange={(open) => {
        setPopoverVisibility(open);
      }}
    >
      <Trigger>
        <Stack gap="0" css={{ cursor: "pointer" }}>
          <Button as="div">
            {type === CONVEYANCE_TYPES.DELIVERY ? (
              <>
                <DeliveryIcon />
                Delivery
              </>
            ) : (
              <>
                <PickupIcon />
                Pickup
              </>
            )}
          </Button>

          {/**
           * &nbsp;&mdash;&nbsp; is the same as ( — ) (excluding parentheses) and is
           * required to create equal left and right spacing and character formatting
           *
           */}
          <FulfillmentTime>
            &nbsp;&mdash;&nbsp;
            {getFulfillmentTime(time_wanted)}
          </FulfillmentTime>
        </Stack>
      </Trigger>

      <Popover.Portal>
        <Content sideOffset={5}>
          <Section>
            <ConveyanceDetails />
          </Section>
          {/**
           * Non-pickup locations show the store details below the delivery address
           * form to ensure proper context for where you are ordering from.
           */}
          {!Boolean(data?.data?.supports_pick_up) && (
            <>
              <Divider />
              <Section>
                <LocationDetails />
              </Section>
            </>
          )}

          <Divider />
          <Section>
            <TimePicker />
          </Section>
          <Arrow />
        </Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const FulfillmentTime = styled.span({
  whiteSpace: "nowrap",
});

const Trigger = styled(Popover.Trigger)({
  all: "unset",

  ":focus-visible": {
    outline: "revert",
  },
});

const Content = styled(Popover.Content)(({ theme }) => ({
  animationName: "slideUpAndFade",
  animationDuration: "400ms",
  animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
  background: theme.modals.background_color ?? "white",
  borderRadius: ".5rem",
  filter: "drop-shadow(0px -1px 9px rgba(0,0,0,0.05))",
  transformOrigin: "var(--radix-popover-content-transform-origin)",
  willChange: "transform, opacity",
  width: "100vw",
  zIndex: 100,

  "@media (min-width: 640px)": {
    width: "24rem",
  },

  "@keyframes slideUpAndFade": {
    "0%": { opacity: 0, transform: "translateY(0.25rem)" },
    "100%": { opacity: 1, transform: "translateY(0)" },
  },

  ":focus": {
    outline: "none",
  },
}));

const Section = styled.div({
  padding: "1.5rem",
});

const Divider = styled.hr(({ theme }) => ({
  background: theme.global.primary_border_color ?? "#e5e5e5",
  border: "none",
  height: "1px",
  margin: 0,
}));

const Arrow = styled(Popover.Arrow)({
  visibility: "hidden",
  fill: "white",
  height: "9px",
  width: "18px",

  "@media (min-width: 640px)": {
    visibility: "visible",
  },
});
