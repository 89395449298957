import styled from "styled-components";
import { Conveyance } from "./conveyance";
import { Destination } from "./destination";
import { FindAlocation } from "./find-a-location";
import { Stack } from "@/components/ui";
import { useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { selectConveyance } from "@/redux/conveyanceMode/reducer";

export function MenuAvailability() {
  const { location } = useSelector(selectConveyance);

  return (
    <Container>
      <Stack align="center" position="center">
        {!location ? <FindAlocation /> : <Conveyance />}
        <Destination />
      </Stack>
    </Container>
  );
}

const Container = styled.div(({ theme }) => ({
  ...getFontStyles(theme.header.font, {
    font_size: (value) => {
      if (typeof value !== "number") {
        return value;
      }

      if (value * 0.75 < 14) {
        return 14;
      }

      return value * 0.75;
    },
  }),
  maxWidth: "100%",

  "@media (min-width: 768px)": {
    ...getFontStyles(theme.header.font, ["font_size"]),
  },
}));
