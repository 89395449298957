import Link from "next/link";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import {
  StyledLoyaltyButton,
  StyledLoyaltyNav,
  StyledLoyaltyNavContainer,
  StyledLoyaltyNavNumber,
  StyledNavItemAndNumberContainer,
} from "./styles";
import LoyaltyAccessor from "@/components/account/loyaltyAccessor";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { LOYALTY_ACCESSOR_TYPES } from "@/constants/loyalty";
import { LOYALTY_ROUTES } from "@/constants/routes";
import { type RootState } from "@/types/app";
import {
  getActiveRewardsAndOffersLength,
  getUnreadPersonalMessagesLength,
} from "@/utils/loyalty";

interface Props extends ReduxProps {
  activeLoyaltyTab: string;
}

class LoyaltyNav extends Component<Props> {
  render() {
    const {
      activeLoyaltyTab,
      myMessages,
      myOffers,
      myRedeemables,
      loyaltyState,
    } = this.props;

    // Count unread personal messages
    const unreadPersonalMessagesLength =
      getUnreadPersonalMessagesLength(myMessages);

    // Combine available rewards and offers
    const currentPoints = loyaltyState?.points;
    const combinedRewardsLength = getActiveRewardsAndOffersLength(
      myRedeemables,
      myOffers,
      currentPoints ?? 0
    );

    return (
      <StyledLoyaltyNavContainer>
        <StyledLoyaltyNav>
          <LoyaltyAccessor
            checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
            checkName={LOYALTY_ROUTES.REWARDS}
            component={
              <Link href={LOYALTY_ROUTES.REWARDS} passHref={true}>
                <StyledLoyaltyButton
                  active={activeLoyaltyTab === LOYALTY_ROUTES.REWARDS}
                  aria-label="Go to Rewards page"
                  className={`${CSS_CLASSES.LOYALTY.NAV_BUTTON} ${
                    activeLoyaltyTab === LOYALTY_ROUTES.REWARDS && "active"
                  }`}
                >
                  <StyledNavItemAndNumberContainer>
                    <StringAccessor accessor="rewards.title" />
                    {combinedRewardsLength > 0 && (
                      <StyledLoyaltyNavNumber>
                        {combinedRewardsLength}
                      </StyledLoyaltyNavNumber>
                    )}
                  </StyledNavItemAndNumberContainer>
                </StyledLoyaltyButton>
              </Link>
            }
          />
          <LoyaltyAccessor
            checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
            checkName={LOYALTY_ROUTES.INBOX}
            component={
              <Link href={LOYALTY_ROUTES.INBOX} passHref={true}>
                <StyledLoyaltyButton
                  active={activeLoyaltyTab === LOYALTY_ROUTES.INBOX}
                  aria-label="Go to Inbox page"
                  className={`${CSS_CLASSES.LOYALTY.NAV_BUTTON} ${
                    activeLoyaltyTab === LOYALTY_ROUTES.INBOX && "active"
                  }`}
                >
                  <StyledNavItemAndNumberContainer>
                    <StringAccessor accessor="inbox.title" />
                    {unreadPersonalMessagesLength > 0 && (
                      <StyledLoyaltyNavNumber>
                        {unreadPersonalMessagesLength}
                      </StyledLoyaltyNavNumber>
                    )}
                  </StyledNavItemAndNumberContainer>
                </StyledLoyaltyButton>
              </Link>
            }
          />
          <LoyaltyAccessor
            checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
            checkName={LOYALTY_ROUTES.FAVORITES}
            component={
              <Link href={LOYALTY_ROUTES.FAVORITES} passHref={true}>
                <StyledLoyaltyButton
                  active={activeLoyaltyTab === LOYALTY_ROUTES.FAVORITES}
                  aria-label="Go to Favorites page"
                  className={`${CSS_CLASSES.LOYALTY.NAV_BUTTON} ${
                    activeLoyaltyTab === LOYALTY_ROUTES.FAVORITES && "active"
                  }`}
                >
                  <StringAccessor accessor="favorites.title" />
                </StyledLoyaltyButton>
              </Link>
            }
          />
          <LoyaltyAccessor
            checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
            checkName={LOYALTY_ROUTES.ORDER_HISTORY}
            component={
              <Link href={LOYALTY_ROUTES.ORDER_HISTORY} passHref={true}>
                <StyledLoyaltyButton
                  active={activeLoyaltyTab === LOYALTY_ROUTES.ORDER_HISTORY}
                  aria-label="Go to Order History page"
                  className={`${CSS_CLASSES.LOYALTY.NAV_BUTTON} ${
                    activeLoyaltyTab === LOYALTY_ROUTES.ORDER_HISTORY &&
                    "active"
                  }`}
                >
                  <StringAccessor accessor="order_history.title" />
                </StyledLoyaltyButton>
              </Link>
            }
          />

          <LoyaltyAccessor
            checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
            checkName={LOYALTY_ROUTES.ACCOUNT}
            component={
              <Link href={LOYALTY_ROUTES.ACCOUNT} passHref={true}>
                <StyledLoyaltyButton
                  active={activeLoyaltyTab === LOYALTY_ROUTES.ACCOUNT}
                  aria-label="Go to Account page"
                  className={`${CSS_CLASSES.LOYALTY.NAV_BUTTON} ${
                    activeLoyaltyTab === LOYALTY_ROUTES.ACCOUNT && "active"
                  }`}
                >
                  <StringAccessor accessor="account.title" />
                </StyledLoyaltyButton>
              </Link>
            }
          />
        </StyledLoyaltyNav>
      </StyledLoyaltyNavContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  myMessages: state.app.me.messages,
  myOffers: state.app.me.myOffers,
  myRedeemables: state.app.me.redeemables,
  loyaltyState: state.app.me.loyaltyState,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(LoyaltyNav);
