import {
  sdkUtils,
  type MenuProductOption,
  type MenuProductOptionGroup,
} from "@koala/sdk";
import {
  StyledAdvancedOptionGroup,
  StyledChangeQuantityButton,
  StyledOptionCard,
  StyledOptionContainer,
  StyledQuantitySelector,
  StyledAdvNestedModOptionGroupHelperText,
  StyledOptionWrapper,
} from "../styles";
import { Option } from "./option";
import { useDispatch } from "@/redux";
import customizeActions from "@/redux/customize/actions";
import { doesGroupHaveImages } from "@/utils/menu";

interface Props {
  group: MenuProductOptionGroup;
  option: MenuProductOption;
  displayOptionsErrors: boolean;
}

export function OptionCard({ group, option, displayOptionsErrors }: Props) {
  const dispatch = useDispatch();
  const isOptionDisabled =
    group.is_max_aggregate_quantity_reached || option.is_max_selections_reached;
  const isOptionIncrementDisabled =
    /** @TODO ensure that `total_aggregate_quantity` and `max_aggregate_quantity` are defined. */
    isOptionDisabled ||
    // @ts-expect-error
    group.total_aggregate_quantity + group.choice_quantity_increment >
      // @ts-expect-error
      group.max_aggregate_quantity;

  const groupHasImages = doesGroupHaveImages(group);

  return (
    <OptionContainer option={option} group={group}>
      <OptionCardContainer option={option} group={group}>
        <StyledOptionWrapper groupHasImages={groupHasImages}>
          <Option
            option={option}
            supportsQuantitySelection={group.supports_quantity_selection}
            isAdvancedModOption={false}
          />
        </StyledOptionWrapper>
        {option.contains_adv_nested_modifiers && (
          <AdvancedOptionGroup
            displayOptionsErrors={displayOptionsErrors}
            option={option}
          />
        )}
      </OptionCardContainer>
      {group.supports_quantity_selection && (
        <StyledQuantitySelector selected={!!option.selected}>
          <StyledChangeQuantityButton
            onClick={() =>
              dispatch(customizeActions.decrementOption(option.id))
            }
            disabled={option.quantity === 0}
            increment={false}
            aria-label={`Decrement ${option.name} quantity by ${
              group.choice_quantity_increment || 1
            }`}
          >
            -
          </StyledChangeQuantityButton>
          <div>{option.quantity}</div>
          <StyledChangeQuantityButton
            onClick={() =>
              dispatch(customizeActions.incrementOption(option.id))
            }
            // Disable if we're already at maxAgg, or if option quantity is max_choice_quantity or 99
            disabled={isOptionIncrementDisabled}
            increment={true}
            aria-label={`Increment ${
              option.name
            } quantity by ${sdkUtils.calculateIncrementor(option, group)}`}
          >
            +
          </StyledChangeQuantityButton>
        </StyledQuantitySelector>
      )}
    </OptionContainer>
  );
}

interface AdvancedOptionGroupProps {
  option: MenuProductOption;
  displayOptionsErrors: boolean;
}

function AdvancedOptionGroup({
  option,
  displayOptionsErrors,
}: AdvancedOptionGroupProps) {
  const advancedOptionGroup: MenuProductOptionGroup | undefined =
    option.option_groups.find(
      (group: MenuProductOptionGroup) =>
        group.is_adv_nested_modifier && !group.is_hidden
    );

  // We only show the group if the option is selected, and it has an advanced option group.
  const showAdvancedOptionGroup = option.selected && advancedOptionGroup;

  if (!showAdvancedOptionGroup) {
    return null;
  }

  return (
    <>
      {displayOptionsErrors && !advancedOptionGroup.valid && (
        <StyledAdvNestedModOptionGroupHelperText>
          Selection required
        </StyledAdvNestedModOptionGroupHelperText>
      )}
      <StyledAdvancedOptionGroup>
        {advancedOptionGroup.options.map((opt: MenuProductOption) => {
          return (
            <OptionContainer option={opt} group={advancedOptionGroup}>
              <OptionCardContainer option={opt} group={advancedOptionGroup}>
                <Option
                  option={opt}
                  supportsQuantitySelection={
                    advancedOptionGroup.supports_quantity_selection
                  }
                  isAdvancedModOption={true}
                />
              </OptionCardContainer>
            </OptionContainer>
          );
        })}
      </StyledAdvancedOptionGroup>
    </>
  );
}

interface OptionContainerProps {
  children: React.ReactNode;
  option: MenuProductOption;
  group: MenuProductOptionGroup;
}

/**
 * A container for the option that handles the styling of the option card.
 */
function OptionContainer({ children, option, group }: OptionContainerProps) {
  const groupHasImages = doesGroupHaveImages(group);

  return (
    <StyledOptionContainer
      data-testid="option-container"
      selected={!!option.selected}
      is_adv_nested_modifier_group={Boolean(group.is_adv_nested_modifier)}
      is_inverted={!!option.is_inverted}
      className={`koala__ui-item-option${groupHasImages ? "-image" : ""}`}
      data-global-id={option.global_option_id}
      groupHasImages={groupHasImages}
    >
      {children}
    </StyledOptionContainer>
  );
}

/**
 * A wrapper around the option card that handles the click event for selecting/deselecting an option.
 */
function OptionCardContainer({
  children,
  option,
  group,
}: OptionContainerProps) {
  const dispatch = useDispatch();
  const isOptionDisabled =
    group.is_max_aggregate_quantity_reached || option.is_max_selections_reached;

  const groupHasImages = doesGroupHaveImages(group);
  // An option is selected or completely deselected by clicking on it.
  function toggleSelectedOption() {
    option.selected
      ? dispatch(customizeActions.decrementOption(option.id, true))
      : dispatch(customizeActions.incrementOption(option.id));
  }

  return (
    <StyledOptionCard
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        toggleSelectedOption();
      }}
      // If we're at our max and option is not currently selected, disable selection
      // Do not disable if maxAgg is 0 - this is Olo's single product mode
      disabled={isOptionDisabled}
      groupHasImages={groupHasImages}
      hasAdvancedModOptions={option.contains_adv_nested_modifiers}
      aria-label={`${option.selected ? "Deselect" : "Select"} ${option.name}`}
      aria-pressed={option.selected}
    >
      {children}
    </StyledOptionCard>
  );
}
