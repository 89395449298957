import styled from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export const AncillaryNavigation = styled.nav(({ theme }) => ({
  ...getFontStyles(theme.android_drawer.bottom_section_font, [
    "color",
    "font_family",
  ]),
  display: "flex",
  flexDirection: "column",
  fontSize: "var(--config-text__primary-text-size)",
  fontWeight: 400,
  margin: "auto 0 var(--size-8)",
}));

export const AncillaryItem = styled.span(({ theme }) => ({
  display: "block",
  padding: "var(--size-1) var(--size-4)",
  ...getFontStyles(theme.android_drawer.bottom_section_font, ["color"]),
}));

export const Version = styled.span`
  display: block;
  opacity: 0.5;
  padding: var(--size-1) var(--size-4);
`;

export const Logout = styled.button(({ theme }) => ({
  appearance: "none",
  background: "none",
  border: "none",
  display: "block",
  padding: "var(--size-1) var(--size-4)",
  textAlign: "left",
  ...getFontStyles(theme.android_drawer.bottom_section_font, ["color"]),
}));
