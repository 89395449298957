import {
  sdkUtils,
  sdkStorage,
  type BasketItem,
  ConversionTrackingHelper,
} from "@koala/sdk";
import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { Footer } from "./footer";
import { ProductDetail } from "./product-detail";
import { ProductOptions } from "./product-options";
import { Recipient } from "./recipient";
import { SpecialInstructions } from "./special-instructions";
import {
  StyledCustomizeContainer,
  StyledCustomizeFooter,
  StyledCustomizeScrollContent,
  StyledPdpCloseButton,
  StyledToastError,
} from "./styles";
import { WarningLabels } from "./warning-labels";
import { XSvg } from "@/components/uielements/X";
import { PRODUCT_LOCATION_LABELS } from "@/constants/checkout";
import { MAX_LENGTH } from "@/constants/global";
import { useMenu } from "@/features/menu/service";
import { useDispatch, useSelector } from "@/redux";
import basketActions from "@/redux/basket/actions";
import actions from "@/redux/customize/actions";
import { getAllProductOptionAllergens } from "@/utils/menu";

export function ProductEditor() {
  const dispatch = useDispatch();
  const basket = useSelector(({ app }) => app.basket.content);
  const locationDetail = useSelector(({ app }) => app.locations.detail);
  const { webConfig } = useSelector(({ app }) => app.cmsConfig);
  const { address, time_wanted } = useSelector(({ app }) => app.conveyanceMode);
  const { categories } = useMenu({
    id: locationDetail?.id ?? 0,
    wantedAt: time_wanted,
  });

  const {
    canonicalProduct,
    final,
    index,
    label,
    options,
    product,
    quantity,
    toggleToastOptionsError,
    upsellId,
  } = useSelector(({ app }) => app.customize);

  /**
   * @REFACTOR
   * The basket item should be selected by ID instead of array index,
   */
  // @ts-expect-error
  const basketItem: BasketItem = basket.basket_items[index];
  const editing = typeof index === "number";

  // Store item recipient and special instructions.
  const [recipient, setRecipient] = useState(basketItem?.recipient ?? "");
  const [specialInstructions, setSpecialInstructions] = useState(
    basketItem?.special_instructions ?? ""
  );

  if (!locationDetail || !final || !product) {
    return null;
  }

  const dietaryPreferencesEnabled = webConfig.menu.dietary_preferences_enabled;
  const specialInstructionsEnabled =
    webConfig.product_detail.special_instructions_enabled;
  const itemRecipientEnabled = locationDetail.supports_item_recipients;
  const specialInstructionsMaxLength =
    locationDetail.special_instructions_max_length;

  // Merge allergies into the final customized product.
  const finalWithAllergies = {
    ...final,
    allergens: dietaryPreferencesEnabled
      ? getAllProductOptionAllergens(product, options)
      : final.allergens,
  };

  /**
   * Show validation errors for basket items with required options.
   * @TODO change to scrolling via an element ref instead of `getElementById`.
   */
  function showValidationErrors() {
    dispatch(actions.displayOptionsErrors(true));
    dispatch(actions.triggerToastOptionsError());

    // Find first invalid option group.
    const firstInvalidGroup = final?.surfaceable_option_groups?.find(
      (group) => !group.valid
    );

    // ...and scroll to its corresponding DOM element.
    const el = document.getElementById(`option-group-${firstInvalidGroup?.id}`);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  /**
   * Validates the selected item options and adds it to cart.
   */
  function addToCart() {
    if (!product || !canonicalProduct) {
      return;
    }

    let basketItemId: string | undefined = undefined;
    let location = locationDetail;

    // Edited items should retain the original location, items added from a new location will still reinit the cart.
    if (typeof index === "number") {
      /** @TODO ensure that the basket includes the entire location record. */
      // @ts-expect-error
      location = basket.location;
      basketItemId = sdkStorage.basket.get().content.basket_items[index].id;
    }

    // If there is no location object passed (we are likely off the menu page on e.g. upsell in sidecart and there is nothing in redux)
    // grab the location id from the current basket. TODO - maybe it's better to explicitly check to see if it's an upsell item before
    // reassigning the location. Are there cases where we'd be adding an item without a location detail object and intentionally want
    // to trigger a re-init of the basket?
    if (location && !location.id) {
      /** @TODO ensure that the basket includes the entire location record. */
      // @ts-expect-error
      location = basket.location;
    }

    // Grab category ID and label for Koala Analytics
    const productCategory = sdkUtils.findProductCategory(
      product.id,
      categories
    );
    const productCategoryLabel = productCategory?.name;
    const productCategoryId = productCategory?.global_id;

    const basketProduct = {
      id: basketItemId,
      product: {
        id: product.id,
        global_id: product.global_id,
        name: product.name,
        cross_sell_id: upsellId || null,
        cost: canonicalProduct.cost * quantity, // as per Koala Analytics specs
        category_id: productCategoryId || null,
        category_label: productCategoryLabel || null,
        filter_tags: product?.filter_tags,
      },
      options,
      quantity,
      special_instructions: specialInstructions,
      recipient,
    };

    // If some option groups are invalid, show an error.
    if (final?.surfaceable_option_groups?.some((g) => !g.valid)) {
      showValidationErrors();
      return;
    }

    // Null or mismatched location ids require an init/reinit of the basket
    // Unless the mismatch is because we are adding a cross-sell item from a basket
    // associated with a different location than the current menu we've landed on.
    // In that case we should be able to proceed adding the item to the basket normally
    if (!basket.location.id || basket.location.id !== location?.id) {
      if (label !== PRODUCT_LOCATION_LABELS.CROSS_SELL) {
        const referralTrackingCode =
          new ConversionTrackingHelper().getTrackingKey();
        /**
         * If a delivery address exists, add it to localStorage when creating a basket
         *
         * @FIXME there are some issues with this action type:
         * 1. The location is nullable, which is an issue with the Location slice's empty state.
         * 2. The categories are nullable, again an issue with the Menu slice's empty state.
         * 3. The address null|undefined inconsistencies.
         */
        dispatch(
          basketActions.initBasket(
            // @ts-expect-error TODO: make sure location is defined
            location,
            categories,
            undefined,
            address ?? undefined,
            time_wanted,
            referralTrackingCode ?? undefined
          )
        );
      }
    }

    /**
     * Add the item to the user's basket and close the modal.
     *
     * @FIXME there is an issue with this action type:
     * 1. The label is nullable, which is an issue with the Customize slice's empty state.
     */
    dispatch(basketActions.addItem(basketProduct, label!, index));
    dispatch(actions.clearProduct());
  }

  return (
    <StyledCustomizeContainer data-testid="product-details-modal">
      <Dialog.Close asChild>
        <StyledPdpCloseButton
          onClick={() => dispatch(actions.clearProduct())}
          aria-label={`Click to close ${product?.name} detail modal`}
        >
          <XSvg />
        </StyledPdpCloseButton>
      </Dialog.Close>
      <StyledCustomizeScrollContent>
        <ProductDetail
          product={finalWithAllergies}
          locationDetail={locationDetail}
        />
        <ProductOptions product={product} />
        {/* Special Instructions form if enabled and if there's a max length */}
        {specialInstructionsEnabled && specialInstructionsMaxLength && (
          <SpecialInstructions
            maxLength={specialInstructionsMaxLength}
            onChange={(value) => setSpecialInstructions(value)}
            value={specialInstructions}
          />
        )}

        {/* Item Recipient form if enabled */}
        {itemRecipientEnabled && MAX_LENGTH.ITEM_RECIPIENT && (
          <Recipient
            maxLength={MAX_LENGTH.ITEM_RECIPIENT}
            onChange={(value) => setRecipient(value)}
            title={locationDetail.item_recipient_label ?? "Item Recipient"}
            value={recipient}
          />
        )}

        {/* Product + modifier warning labels */}
        {product.warningLabels && (
          <WarningLabels labels={product.warningLabels} />
        )}
      </StyledCustomizeScrollContent>
      <StyledCustomizeFooter validationErrorIsVisible={toggleToastOptionsError}>
        <StyledToastError role="alert" isVisible={toggleToastOptionsError}>
          Please make all required selections to add order to your basket
        </StyledToastError>
        <Footer
          product={final}
          addToOrder={addToCart}
          quantity={quantity}
          toggleQuantity={(quantity) =>
            dispatch(actions.toggleQuantity(quantity))
          }
          editing={editing}
          disabled={!locationDetail.provider_enabled}
        />
      </StyledCustomizeFooter>
    </StyledCustomizeContainer>
  );
}
