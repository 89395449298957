import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { Container, Title } from "./header.styles";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents, EventNames } from "@/analytics/events/constants";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { FeatureAccessor } from "@/components/featureAccessor";
import { Navigation } from "@/components/uielements/navigation";
import { FEATURE_FLAGS } from "@/constants/features";
import { ROUTES, LOYALTY_ROUTES } from "@/constants/routes";
import { type RootState } from "@/types/app";
import { deriveReferrer } from "@/utils/global";
import { useRewardsState, useAccount } from "@/utils/hooks";
import { safelyGetString } from "@/utils/stringHelpers";

export const Header = () => {
  const router = useRouter();
  const referrer = deriveReferrer(router);
  const { strings } = useSelector((state: RootState) => state.app.cmsConfig);
  const { currentPoints } = useRewardsState();
  const { loggedIn, userDetails } = useAccount();
  const loginWithReferrer = `${ROUTES.LOGIN}?referrer=${referrer}`;

  return (
    <Container>
      {loggedIn && (
        <>
          <Title>
            <StringAccessor
              html={true}
              accessor="android_drawer.user_title"
              dataObj={{
                user: userDetails?.name,
                first: userDetails?.first_name,
              }}
              tag="span"
            />
          </Title>

          <FeatureAccessor featureFlag={FEATURE_FLAGS.ACCOUNT__REWARDS}>
            <Navigation.Item
              href={LOYALTY_ROUTES.REWARDS}
              onClick={() => {
                genericEventHandler(GlobalEvents.VIEW__REWARDS, {
                  name: EventNames.ANDROID_NAVIGATION,
                  details: "Reedem points",
                });
              }}
            >
              <StringAccessor
                html={true}
                accessor="android_drawer.user_points_total"
                dataObj={{
                  points: currentPoints,
                }}
                tag="span"
              />
            </Navigation.Item>
          </FeatureAccessor>
        </>
      )}

      {!loggedIn && (
        <>
          <Title>
            {safelyGetString(strings, "android_drawer.guest_title")}
          </Title>

          <FeatureAccessor
            featureFlag={FEATURE_FLAGS.ACCOUNT__REWARDS}
            renderFallback={
              <Navigation.Item href={loginWithReferrer}>
                {safelyGetString(
                  strings,
                  "android_drawer.guest_subtitle_no_loyalty"
                )}
              </Navigation.Item>
            }
          >
            <Navigation.Item href={loginWithReferrer}>
              {safelyGetString(strings, "android_drawer.guest_subtitle")}
            </Navigation.Item>
          </FeatureAccessor>
        </>
      )}
    </Container>
  );
};
