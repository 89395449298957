import * as Dialog from "@radix-ui/react-dialog";
import { useModalContext } from "./context";
import { CancelButton, Footer } from "./footer.styles";

export const ModalCancel = ({
  children,
  ...otherProps
}: React.HTMLProps<HTMLButtonElement>) => {
  const { onCancel } = useModalContext();

  return (
    <Dialog.Close asChild>
      {/* @ts-expect-error type narrowing error on button `type`. */}
      <CancelButton {...otherProps} onClick={onCancel}>
        {children}
      </CancelButton>
    </Dialog.Close>
  );
};

export const ModalFooter = ({
  children,
  ...otherProps
}: React.HTMLProps<HTMLDivElement>) => (
  <>
    {/* @ts-expect-error ref mismatch */}
    <Footer {...otherProps}>{children}</Footer>
  </>
);
