import { NavigationListOverrides } from "./links.styles";
import { genericEventHandler } from "@/analytics/events";
import { EventNames, GlobalEvents } from "@/analytics/events/constants";
import { FeatureAccessor } from "@/components/featureAccessor";
import { Navigation } from "@/components/uielements/navigation";
import { FEATURE_FLAGS } from "@/constants/features";
import { LOYALTY_ROUTES, ROUTES } from "@/constants/routes";
import { useSelector } from "@/redux";
import { useAccount } from "@/utils/hooks";
import { safelyGetString } from "@/utils/stringHelpers";

export const Links = () => {
  const { strings } = useSelector((state) => state.app.cmsConfig);
  const { loggedIn } = useAccount();
  return (
    <NavigationListOverrides>
      <Navigation.List>
        <FeatureAccessor featureFlag={FEATURE_FLAGS.ACCOUNT__FAVORITES}>
          <Navigation.Item
            href={LOYALTY_ROUTES.FAVORITES}
            onClick={() => {
              genericEventHandler(GlobalEvents.VIEW__FAVORITES, {
                name: EventNames.ANDROID_NAVIGATION,
              });
            }}
          >
            {safelyGetString(strings, "android_drawer.favorites")}
          </Navigation.Item>
        </FeatureAccessor>

        <Navigation.Item
          href={ROUTES.LOCATIONS}
          onClick={() => {
            genericEventHandler(GlobalEvents.VIEW__STORE_LOCATOR, {
              name: EventNames.ANDROID_NAVIGATION,
            });
          }}
        >
          {safelyGetString(strings, "android_drawer.locations")}
        </Navigation.Item>

        <Navigation.Item
          href={LOYALTY_ROUTES.ORDER_HISTORY}
          onClick={() => {
            genericEventHandler(GlobalEvents.VIEW__ORDER_HISTORY, {
              name: EventNames.ANDROID_NAVIGATION,
            });
          }}
        >
          {safelyGetString(strings, "android_drawer.order_history")}
        </Navigation.Item>

        {loggedIn && (
          <Navigation.Item href={LOYALTY_ROUTES.ACCOUNT}>
            {safelyGetString(strings, "android_drawer.account")}
          </Navigation.Item>
        )}
      </Navigation.List>
    </NavigationListOverrides>
  );
};
