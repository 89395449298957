export const CSS_CLASSES = {
  GLOBAL_HEADER: {
    CONTAINER: "koala__ui-global_header-nav",
    ORDER_NOW: "koala__ui-global_header-order_now-button",
    AUTH_NOTIFICATION_COUNTER:
      "koala__ui-global_header-auth-notification-counter-div",
    AUTH_SIGN_IN: "koala__ui-global_header-auth-sign-in-div",
    AUTH_USERNAME: "koala__ui-global_header-auth-signed-in-span",
  },
  GLOBAL: {
    STYLED_CONTENT_CONTAINER: "koala__ui-global_styled-content-container",
  },
  CHECKOUT: {
    HEADER: "koala__ui-checkout_header-div",
    CONTAINER: "koala__ui-checkout_container-div",
    STEP_HEADER_CONTAINER: "koala__ui-checkout-step_header_container-div",
    COMPONENT_TAXES_AND_FEES_TOOLTIP:
      "koala__ui-checkout-component_taxes_and_fees-tooltip",
  },
  CHECKOUT_HANDOFF_TOGGLE: {
    RADIO_ROW_DINEIN: "koala__ui-checkout_handoff-toggle-radio-row-dinein-div",
  },
  CHECKOUT_PAYMENT_DETAILS: {
    PAY_IN_STORE: "koala__ui-checkout_payment_details-pay_in_store-div",
    NEW_CREDIT_CARD: "koala__ui-checkout_payment_details-new_credit_card-div",
  },
  LOCATION_LIST: {
    CONTAINER: "koala__ui-location_list-div",
    HANDOFF_TOGGLE: "koala__ui-location_list-handoff_toggle-div",
  },
  LOCATION_CARD: {
    CONTAINER: "koala__ui-location-card",
    PICKUP_BUTTON: "koala__ui-location_card-pickup-button",
  },
  SIDECART: {
    CONTAINER: "koala__ui-side_cart-div",
    ITEMS_HEADER: "koala__ui-side_cart-items-header-h3",
  },
  STORE_MENU: {
    DESKTOP_NAV: "koala__ui-store_menu-desktop_nav_items-div",
    MOBILE_NAV: "koala__ui-store_menu-mobile_nav_items-div",
    ACTIVE_NAV_BUTTON: "koala__ui-store_menu-active_nav_items-a",
    PRODUCT_CARD: "koala__ui-store_menu-product_card-div",
    PRODUCT_CARD_META_INFO: "koala__ui-store_menu-product_card_meta_info-div",
    PRODUCT_OPTION_GROUP_HEADER:
      "koala__ui-store_menu-product_option_group_header",
    MENU_SEARCH: "koala__ui-store_menu-menu_search-div",
    MENU_SEARCH_MOBILE: "koala__ui-store_menu-menu_search_mobile-div",
  },
  STORE_DETAIL: {
    INFO_CARD: "koala__ui-store_detail-info_card-div",
    SEE_LOCATION_INFO_CTA:
      "koala__ui-store_detail-see-location-info-cta-button",
    CONVEYANCE_TOGGLE: "koala__ui-store_detail-conveyance_toggle-div",
    LOCATION_NAME: "koala__ui-store_detail-location_name-button",
  },
  PRODUCT_DETAIL: {
    CONTAINER: "koala__ui-product_detail-div",
    PRODUCT_INFO: "koala__ui-product_detail-product_info-div",
    SPECIAL_INSTRUCTION_HEADER:
      "koala__ui-product_detail-special_instruction-header",
    RECIPIENT_INSTRUCTIONS_HEADER:
      "koala__ui-product_detail-recipient_instruction-header",
  },
  FOOTER: {
    CONTAINER: "koala__ui-footer_container-div",
  },
  STORE_LOCATOR: {
    VIEW_ALL_LOCATION_LINK: "koala__ui-store_locator-view_all_location-link",
    SEARCH_HEADING: "koala__ui-store_locator-search-heading-h4",
  },
  AUTH: {
    CHECKOUT_AS_GUEST_LINK: "koala__ui-auth-checkout_as_guest-link-button",
  },
  MENU_HANDOFF_ADDRESS: {
    DELIVERY: "koala__ui-menu_handoff-address_delivery-button",
    PICKUP: "koala__ui-menu_handoff-address_pickup-button",
  },
  LOYALTY_REWARDS_FOOTER: {
    POINTS_HISTORY: "koala__ui-loyalty_rewards_footer-points-history",
    RECEIPT_BARCODE: "koala__ui-loyalty_rewards_footer-receipt-barcode",
  },
  LOYALTY: {
    NAV_BUTTON: "koala__ui-loyalty-nav-button",
    DROPDOWN_BUTTON: "koala__ui-loyalty-dropdown-button",
    PAGE_HEADER: "koala__ui-loyalty-page-header-h2",
    LOCATIONS_REWARDS_SUMMARY:
      "koala__ui-loyalty_locations_rewards_summary-div",
    LOCATIONS_REWARDS_SUMMARY_LINK:
      "koala__ui-loyalty_locations_rewards_summary-link-a",
  },
};
