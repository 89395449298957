import styled from "styled-components";

export const Loading = styled.div(({ theme }) => ({
  "--background": theme.header.background_color ?? "white",
  "--container-color": `color-mix(
    in srgb,
    var(--background),
    rgb(160 160 160) 40%
  )`,

  backgroundColor: "var(--container-color)",
  inset: 0,
  position: "absolute",
  zIndex: 10,

  "&:before": {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    content: "''",
    display: "block",
    height: "100%",
    position: "relative",
    width: "7.5rem",
    zIndex: 2,
  },

  "&:after": {
    animation: "moveLoadingIndicator 0.8s ease infinite",
    background: `linear-gradient(
      to right,
      var(--container-color) 0%,
      color-mix(in srgb, var(--background), rgb(255 255 255) 40%) 47.4%,
      
      var(--container-color) 100%
    )`,
    content: "''",
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    transform: "translateX(-100%)",
    width: "100%",
    zIndex: 1,
  },
}));

export const LoadingPlaceholder = styled(Loading)`
  @keyframes moveLoadingIndicator {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(100%);
    }
  }
`;
