import { type Placement, type Direction } from "./stack.helpers";
import { Container } from "./stack.styles";

interface Props {
  children: React.ReactNode;
  align?: Placement;
  position?: Placement;
  overrides?: React.CSSProperties;
  direction?: Direction;
  gap?: string;
}

export const Stack = ({
  align = "center",
  position = "leading",
  children,
  overrides = {},
  direction = "horizontal",
  gap = "var(--size-2)",
}: Props) => (
  <Container
    align={align}
    position={position}
    direction={direction}
    styles={{ ...overrides, gap }}
  >
    {children}
  </Container>
);
