import {
  LOYALTY_ACCESSOR_TYPES,
  type LOYALTY_FEATURES,
  LOYALTY_ROUTE_FEATURE_MAP,
} from "@/constants/loyalty";
import { type LOYALTY_ROUTES } from "@/constants/routes";
import { useSelector } from "@/redux";
import { allowedLoyaltyRoutes } from "@/utils/loyalty";

interface Props {
  component: JSX.Element;
  checkType: LOYALTY_ACCESSOR_TYPES.FEATURE | LOYALTY_ACCESSOR_TYPES.ROUTE;
  checkName: LOYALTY_FEATURES | LOYALTY_ROUTES;
}

const LoyaltyAccessor = ({ checkType, checkName, component }: Props) => {
  const organization = useSelector((state) => state.app.organization);
  const featureBag = useSelector((state) => state.app.global.featureBag);

  const loyaltyDriverID = organization.organization?.loyalty_driver_id;
  let functionalitySupported = false;

  if (checkType === LOYALTY_ACCESSOR_TYPES.ROUTE) {
    functionalitySupported =
      allowedLoyaltyRoutes(loyaltyDriverID).includes(checkName) &&
      // @ts-expect-error `checkName` can't index `LOYALTY_ROUTE_FEATURE_MAP`.
      featureBag[LOYALTY_ROUTE_FEATURE_MAP[checkName]] !== false;
  } else {
    // @ts-expect-error `checkName` can't index `features`.
    functionalitySupported = organization.loyalty?.features?.[checkName];
  }

  // Only return component if loyalty feature is supported
  return !functionalitySupported ? null : component;
};

export default LoyaltyAccessor;
