import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import Icon from "@/components/uielements/icon";
import { useDialogContext } from "@/utils/useDialogContext";

export const CloseCart = (props: React.HTMLProps<HTMLButtonElement>) => {
  const { onCancel } = useDialogContext();

  return (
    <Dialog.Close asChild>
      {/* @ts-ignore */}
      <Button onClick={onCancel} {...props}>
        <Icon.Close title="Close cart" />
      </Button>
    </Dialog.Close>
  );
};

const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: var(--sidecart__foreground-color);
  display: flex;
  height: var(--size-12);
  justify-content: center;
  flex: none;
  position: absolute;
  // Move close button to left top corner if mobile view
  left: var(--size-1);
  top: var(--size-1);
  width: var(--size-12);
  z-index: var(--layer-1);

  @media (min-width: 768px) {
    background-color: var(--sidecart__background-color);
    border-radius: 100%;
    box-shadow: 0px 0px 8px 0px rgba(15, 15, 15, 0.19);
    right: var(--size-6);
    top: var(--size-6);
    left: auto;
  }
`;
