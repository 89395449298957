import {
  ReorderModalCancelButton,
  ReorderModalConfirmDescription,
  StyledReorderForm,
  StyledReorderFormHeader,
  StyledReorderFormTitle,
  StyledReorderModalButton,
  StyledReorderModalButtonWrapper,
} from "./styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";

interface Props {
  basketLoading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ReorderConfirmMissingItems = ({
  basketLoading,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <StyledReorderForm>
      <StyledReorderFormHeader>
        <StyledReorderFormTitle>
          Some items are unavailable
        </StyledReorderFormTitle>
      </StyledReorderFormHeader>

      <ReorderModalConfirmDescription>
        Heads up! One or more items in this order are not available. Unavailable
        items will not be included if you proceed with re-order.
      </ReorderModalConfirmDescription>

      <StyledReorderModalButtonWrapper>
        <StyledReorderModalButton
          buttonTheme="primary"
          submitting={basketLoading}
          type="button"
          onClick={onConfirm}
        >
          <StringAccessor accessor="reorders.add_to_cart_cta" />
        </StyledReorderModalButton>

        <ReorderModalCancelButton onClick={onCancel}>
          Cancel
        </ReorderModalCancelButton>
      </StyledReorderModalButtonWrapper>
    </StyledReorderForm>
  );
};
