import { type SVGProps } from "react";
import { type IconProps } from "./types";

export const Ellipsis = ({
  title,
  ...otherProps
}: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M6.61523 11.6631C6.61523 10.6875 5.82422 9.90527 4.83105 9.90527C3.86426 9.90527 3.07324 10.6875 3.07324 11.6631C3.07324 12.6387 3.86426 13.4209 4.83105 13.4209C5.82422 13.4209 6.61523 12.6387 6.61523 11.6631ZM13.1191 11.6631C13.1191 10.6875 12.3369 9.90527 11.3613 9.90527C10.3945 9.90527 9.6123 10.6875 9.6123 11.6631C9.6123 12.6387 10.3945 13.4209 11.3613 13.4209C12.3369 13.4209 13.1191 12.6387 13.1191 11.6631ZM19.6494 11.6631C19.6494 10.6875 18.8672 9.90527 17.8916 9.90527C16.8984 9.90527 16.1162 10.6875 16.1162 11.6631C16.1162 12.6387 16.8984 13.4209 17.8916 13.4209C18.8672 13.4209 19.6494 12.6387 19.6494 11.6631Z"
      fill="currentColor"
    />
  </svg>
);
