import Link from "next/link";
import { useRouter } from "next/router";
import { type UrlField } from "@/components/content/modules/types";
import { styled } from "@/components/content/stitches";
import { useConfigOverride } from "@/redux/cmsConfig";

interface Props {
  links: {
    order: string[];
    links: Record<string, UrlField>;
  };
  onDismiss?: () => void;
  inlineMenu?: boolean;
}

export function Links({ links, onDismiss, inlineMenu = false }: Props) {
  const { font_size } = useConfigOverride("header");
  const router = useRouter();

  return (
    <>
      {links.order.map((id) => {
        const link = links.links[id];

        const {
          type = "external",
          label = "",
          url = "#",
          newWindow = false,
        } = link?.reference ?? {};

        const active = type === "local" && url === router.query.id?.[0];

        return (
          <Link href={type === "local" ? `/${url}` : url} passHref>
            <Element
              active={active}
              position={inlineMenu ? "inline" : "drawer"}
              target={newWindow || type !== "local" ? "_blank" : "_self"}
              onClick={function () {
                onDismiss && onDismiss();
              }}
              css={{
                fontSize: `${font_size}px`,
              }}
            >
              <span>{link.label ?? label}</span>
            </Element>
          </Link>
        );
      })}
    </>
  );
}

const Element = styled("a", {
  fontFamily: "$primaryBold",
  fontSize: "$primary",
  padding: "$4 0",
  transform: "translateX(0)",
  transition: "all .25s ease-in-out",

  span: {
    borderBottom: "2px solid transparent",
    transition: "all .25s ease-in-out",
  },

  "&:active": {
    opacity: 0.7,
  },

  variants: {
    active: {
      true: {
        span: {
          borderColor: "currentColor",
        },
      },
    },
    position: {
      inline: {
        padding: "0",
        "&:hover": {
          transform: "translateY(-0.25rem)",
        },
      },
      drawer: {
        "&:hover": {
          transform: "translateX(0.25rem)",
        },
      },
    },
  },
});
