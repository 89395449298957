import { type MenuProduct } from "@koala/sdk";
import {
  StyledNestedOptionContainer,
  StyledOptionGroup,
  StyledTopOptionContainer,
} from "../styles";
import { OptionCard } from "./option-card";
import { OptionGroupHeader } from "./option-group-header";
import { useSelector } from "@/redux";

interface Props {
  product: MenuProduct;
}

export function ProductOptions({ product }: Props) {
  const { final, displayOptionsErrors } = useSelector(
    ({ app }) => app.customize
  );
  // If the product has no option groups, don't show anything.
  if (!product.surfaceable_option_groups || !final) {
    return null;
  }
  return (
    <StyledTopOptionContainer>
      {product.surfaceable_option_groups.map((group) => {
        // Don't display hidden option groups or advanced nested modifier groups.
        if (group.is_hidden || group.is_adv_nested_modifier) {
          return null;
        }
        return (
          <StyledNestedOptionContainer
            key={group.id}
            data-global-id={group.global_id}
          >
            <OptionGroupHeader
              displayOptionsErrors={displayOptionsErrors}
              final={final}
              group={group}
            />
            <StyledOptionGroup data-testid="option-group">
              {group.options.map((opt) => (
                <OptionCard
                  key={opt.id}
                  option={opt}
                  group={group}
                  displayOptionsErrors={displayOptionsErrors}
                />
              ))}
            </StyledOptionGroup>
          </StyledNestedOptionContainer>
        );
      })}
    </StyledTopOptionContainer>
  );
}
