import { forwardRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Footer } from "./footer";
import { Header } from "./header";
import { Links } from "./links";
import {
  Button,
  Drawer,
  Overlay,
  Panel,
  ScrollContainer,
} from "./navigation-drawer.styles";
import Icon from "@/components/uielements/icon";
import { useAnimatedVisibility } from "@/utils/useAnimatedVisibility";

interface AndroidNavigationDrawer {
  onDismiss: () => void;
  isVisible: boolean;
}

const AndroidNavigationDrawer = forwardRef(
  ({ onDismiss, isVisible = false }: AndroidNavigationDrawer, ref) => {
    /*
     * Per the useState documentation:
     * The initialState argument is the state used during the initial render. In subsequent renders, it is disregarded.
     * [...] you may provide a function instead, which will be executed only on the initial render
     *
     * https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
     *
     * we also need the process.browser check here so this doesn't run on the server
     */
    const [container] = useState(() =>
      process.browser ? document.createElement("div") : null
    );

    useEffect(() => {
      if (container) {
        document.body.appendChild(container);
      }

      return () => {
        container && document.body.removeChild(container);
      };
    }, []);

    return container
      ? createPortal(
          <Drawer>
            <Overlay isVisible={isVisible} onClick={() => onDismiss()} />
            {/* @ts-expect-error mismatched ref type */}
            <Panel ref={ref} isVisible={isVisible}>
              <Header />

              <ScrollContainer>
                <Links />

                <Footer onDismiss={onDismiss} />
              </ScrollContainer>
            </Panel>
          </Drawer>,
          container
        )
      : null;
  }
);

const AndroidNavigation = () => {
  const { isMounted, shouldDisplay, toggle, ref } = useAnimatedVisibility();

  return (
    <>
      <Button onClick={() => toggle()}>
        {shouldDisplay ? (
          <Icon.Close title="Close Navigation Drawer" />
        ) : (
          <Icon.Menu title="Open Navigation Drawer" />
        )}
      </Button>

      {isMounted && (
        <AndroidNavigationDrawer
          ref={ref}
          isVisible={shouldDisplay}
          onDismiss={() => toggle()}
        />
      )}
    </>
  );
};

export default AndroidNavigation;
