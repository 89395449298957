import styled from "styled-components";

type Direction = "up" | "right" | "down" | "left";

interface Props {
  css?: React.CSSProperties;
  direction?: Direction;
}

export function CaretIcon({ css = {}, direction = "right" }: Props) {
  return (
    <Icon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      direction={direction}
      css={css}
    >
      <title>Caret Icon</title>
      <path
        d="M5.25 2.625L9.625 7L5.25 11.375"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

function getRotation(direction: Direction) {
  switch (direction) {
    case "up":
      return "-90deg";
    case "down":
      return "90deg";
    case "left":
      return "180deg";
    default:
      return "0deg";
  }
}

export const Icon = styled.svg<Props>(({ direction = "right", css = {} }) => ({
  flex: "1 0 .875rem",
  transform: `rotate(${getRotation(direction)})`,
  width: ".875rem",
  ...css,
}));
