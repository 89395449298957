import { Main } from "./content.styles";

export const ModalContent = ({
  children,
  ...otherProps
}: React.HTMLProps<HTMLDivElement>) => (
  <>
    {/* @ts-expect-error ref mismatch */}
    <Main {...otherProps}>{children}</Main>
  </>
);
