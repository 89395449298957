import styled from "styled-components";
import { LAYOUT } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export const StyledAddressContainer = styled.div(({ theme }) => ({
  alignItems: "center",
  border: `1px solid ${theme.location_search.border_color}`,
  borderRadius: `${theme.location_search.border_radius}px`,
  display: "flex",
  justifyContent: "space-between",
  margin: "10px 0",
  p: { margin: "0 20px 0 0" },
  padding: "20px",
  ...getFontStyles(theme.location_search.font, ["font_size"]),
  /** @FIXME font refactor. */
  fontFamily: theme.primary_font.bold.font_family,
}));

export const StyledDeliveryFormSubheader = styled.h3`
  font-size: 12px;
  border-bottom: solid 1px ${({ theme }) => theme.global.primary_border_color};
  padding: ${LAYOUT.GUTTER / 2}px 0;
  text-align: left;
`;
