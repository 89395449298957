import { AccountDropdown } from "@/components/account/accountDropdown";
import LoyaltyNavLink from "@/components/account/headerLink";
import { styled } from "@/components/content/stitches";
import { GenericRouteAccessor } from "@/components/genericRouteAccessor";
import { Signin } from "@/components/header/sign-in";
import { ROUTES } from "@/constants/routes";
import { useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safelyGetString } from "@/utils/stringHelpers";

export function Account() {
  const { strings } = useSelector((state) => state.app.cmsConfig);
  const config = useSelector((state) => state.app.cmsConfig.webConfig);
  const me = useSelector((state) => state.app.me.data);

  if (config.accounts.disable_accounts) {
    return null;
  }

  return (
    <>
      <GenericRouteAccessor
        allowedPages={{
          [ROUTES.HOMEPAGE]: { exactMatch: true },
          [ROUTES.STORE]: null,
          [ROUTES.PAGE]: null,
          [ROUTES.LOCATIONS]: null,
          [ROUTES.CHECKOUT]: {
            exactMatch: true,
            hideOnMobile: true,
          },
        }}
        component={
          <AccountContainer
            css={{
              "a, button": getFontStyles(config.header.font, [
                "color",
                "font_family",
                "font_size",
              ]),
            }}
          >
            {me?.id ? (
              <LoyaltyNavLink me={me} />
            ) : (
              <Signin>
                {safelyGetString(strings, "global_header.sign_in_cta")}
              </Signin>
            )}
          </AccountContainer>
        }
      />

      <AccountDropdown />
    </>
  );
}

const AccountContainer = styled("div", {
  "a, button": {
    alignItems: "center",
    background: "none",
    border: "none",
    cursor: "pointer",
    display: "flex",
    height: "$12",
    justifyContent: "center",

    "@md": {
      height: "$20",
      marginLeft: 0,
    },
  },

  "@print": {
    display: "none",
  },
});
