import { type SVGProps } from "react";
import { type IconProps } from "./types";

export const Close = ({
  title,
  ...otherProps
}: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M5 5L19 19M19 5L5 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
