import Link from "next/link";
import { Button } from "./button";
import { PinIcon } from "./icons/pin";
import { ROUTES } from "@/constants/routes";

export function FindAlocation() {
  return (
    <Link href={ROUTES.LOCATIONS} passHref={true}>
      <Button as="a">
        <PinIcon />
        Find a location
      </Button>
    </Link>
  );
}
