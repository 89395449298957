import { type MenuProductOption } from "@koala/sdk";
import {
  StyledModifierImage,
  StyledOptionContent,
  StyledOptionLabel,
  StyledOptionName,
} from "../styles";
import { AllergensMenuCard } from "@/components/allergens";
import { ALLERGEN_CARD_MODE } from "@/constants/global";
import { useSelector } from "@/redux";
import { invertNumberIfOptionInverted, toDollars } from "@/utils/basket";
import { resizeImage } from "@/utils/imageHelper";
import { deriveCalorieDisplay } from "@/utils/menu";

interface Props {
  option: MenuProductOption;
  supportsQuantitySelection: boolean;
  isAdvancedModOption: boolean;
}
export function Option({
  option,
  supportsQuantitySelection,
  isAdvancedModOption,
}: Props) {
  const { webConfig } = useSelector(({ app }) => app.cmsConfig);
  const dietaryPreferencesEnabled = webConfig.menu.dietary_preferences_enabled;

  const validOptionCost = option.cost > 0 || option.cost < 0;
  const { cost, calories } = option;

  // Prep calorie parsing logic
  const {
    calorieDisplayOverride,
    calories_separator,
    is_inverted,
    max_calories,
    selected,
  } = option;
  const calorieString: string = deriveCalorieDisplay(
    calories,
    max_calories,
    /** @TODO ensure that `calories_separator` is defined. */
    // @ts-expect-error
    calories_separator,
    is_inverted,
    calorieDisplayOverride,
    option
  );

  const containsAdvNestedMods = option.contains_adv_nested_modifiers;

  // Does this option have an image associated with it?
  const hasImage = Boolean(option?.images?.image_url_1_by_1);

  return (
    <>
      {hasImage && (
        <StyledModifierImage
          className="modifier-image"
          /** @TODO differentiate between `null`  and `undefined`. */
          // @ts-expect-error
          url={
            option?.images &&
            resizeImage(option?.images?.image_url_1_by_1, { height: 200 })
          }
          supportsQuantitySelection={supportsQuantitySelection}
        />
      )}

      <StyledOptionContent
        selected={!!option.selected}
        is_inverted={!!option.is_inverted}
        groupHasImages={hasImage}
      >
        <StyledOptionName>
          <p>{option.is_inverted ? option.name_inverted : option.name}</p>
          <StyledOptionLabel>
            {option.labels.map((label) => (
              <img
                src={label.image_url}
                alt={label.image_description}
                key={label.name}
              />
            ))}
          </StyledOptionLabel>
        </StyledOptionName>

        {option.description && (
          <p style={{ marginBottom: 10 }}>{option.description}</p>
        )}
        {dietaryPreferencesEnabled && (
          <AllergensMenuCard
            productAllergens={option}
            mode={ALLERGEN_CARD_MODE.OPTIONS}
          />
        )}

        <CaloriesAndCostText
          calorieString={calorieString}
          validOptionCost={validOptionCost}
          cost={cost}
          is_inverted={is_inverted}
          isAdvancedModOption={isAdvancedModOption}
          containsAdvNestedMods={containsAdvNestedMods}
          selected={selected}
        />
      </StyledOptionContent>
    </>
  );
}

interface CaloriesAndCostTextProps {
  calorieString: string;
  validOptionCost: boolean;
  cost: number;
  is_inverted: boolean;
  isAdvancedModOption: boolean;
  containsAdvNestedMods: boolean;
  selected: boolean;
}

function CaloriesAndCostText({
  calorieString,
  validOptionCost,
  cost,
  is_inverted,
  isAdvancedModOption,
  containsAdvNestedMods,
  selected,
}: CaloriesAndCostTextProps) {
  if (isAdvancedModOption) return null; // We don't show calories for nested mod options.

  if (calorieString || validOptionCost) {
    return (
      <p>
        {calorieString}
        {calorieString && validOptionCost ? " | " : ""}
        {validOptionCost
          ? `$${toDollars(invertNumberIfOptionInverted(cost, is_inverted))}`
          : ""}
      </p>
    );
  }

  // This is a fix for the space jumping issue on advanced nested mod options.
  // Without this the buttons will jump when no calories exist and calories are
  // added because of an adv nested modifier.
  if (containsAdvNestedMods && selected) {
    return <p>&nbsp;</p>;
  }

  return null;
}
