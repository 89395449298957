import { type MenuProduct, type TagItem, type WebConfig } from "@koala/sdk";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CrossSells } from "./cross-sells/cross-sells";
import { DeliveryDetails } from "./delivery-details";
import { Footer as CartFooter } from "./footer";
import { Items } from "./items";
import { Location } from "./location";
import { Heading } from "@/components/cart/text";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { Box } from "@/components/uielements/box/box";
import { selectBasketSlice } from "@/redux/basket";
import { selectCart } from "@/redux/basket/reducer";
import { useStrings } from "@/redux/cmsConfig";
import { selectMenuSlice } from "@/redux/menu/reducer";
import tagsActions from "@/redux/tags/actions";
import { fireKAnalyticsUpsellEvent } from "@/utils/koalaAnalytics";
import { useDialogContext } from "@/utils/useDialogContext";
import { useMergeDebounceListener } from "@/utils/useMergeDebounceListener";

export const Contents = () => {
  const { onCancel } = useDialogContext();

  const {
    deliveryMinimum,
    groupedItems,
    isCheckoutDisabled,
    items,
    requiresDeliveryMinimum,
    showFoodHalls,
    showUpsells,
    subtotal,
  } = useSelector(selectCart);

  const { content: basketContent } = useSelector(selectBasketSlice);
  const strings = useStrings();
  const { basketMenu } = useSelector(selectMenuSlice);

  const [upsellsSentToAnalytics, setUpsellsSentToAnalytics] = useState<
    string[]
  >([]);

  const dispatch = useDispatch();

  const handleTagNameLink = (tagName: string, tag: TagItem) => {
    return (
      <StyledTagName
        onClick={() => {
          dispatch(tagsActions.setActiveTag(tag));
          onCancel();
        }}
      >
        {tagName}
      </StyledTagName>
    );
  };

  const { listener: onProductVisibility } = useMergeDebounceListener<{
    product: MenuProduct;
    id: string;
  }>(
    1000,
    (items) => {
      const visibleUpsells = items
        .map((item) => item.product)
        .filter((product) => !upsellsSentToAnalytics.includes(product.id));

      if (visibleUpsells.length) {
        fireKAnalyticsUpsellEvent(items[0].id, visibleUpsells);
      }

      setUpsellsSentToAnalytics([
        ...upsellsSentToAnalytics,
        ...visibleUpsells.map((product) => product.id),
      ]);
    },
    [upsellsSentToAnalytics]
  );

  return (
    <Wrapper>
      <div>
        <Location />

        <DeliveryDetails />
      </div>

      <Content>
        <Box
          overrides={{
            padding: "var(--size-4) var(--size-6)",
          }}
        >
          <Heading
            className="koala__ui-side_cart-items-header-h3"
            data-css-override="StyledSubheader"
          >
            {strings.cart_checkout.cart_items_header}
          </Heading>
        </Box>

        {showFoodHalls
          ? // If food hall enabled, render items by their group/tag
            Object.keys(groupedItems).map((tagName, index: number) => {
              // @ts-expect-error
              const group = groupedItems[tagName];
              const tag = group[0]?.menuProduct?.filter_tags[0];
              return (
                <div key={`${tag}-${index}`}>
                  {handleTagNameLink(tagName, tag)}
                  {basketMenu.length > 0 && <Items items={group} />}
                </div>
              );
            })
          : // Else render products normally
            // @ts-expect-error: mismatched types
            basketMenu.length > 0 && <Items items={items} />}

        {showUpsells && (
          <CrossSells
            basketContent={basketContent}
            onProductVisibility={onProductVisibility}
          />
        )}
      </Content>

      <Footer>
        {requiresDeliveryMinimum && (
          <DeliveryMinimumMessage>
            <StringAccessor
              accessor="cart_checkout.delivery_min_not_met_message"
              dataObj={{
                delivery_minimum: deliveryMinimum,
              }}
            />
          </DeliveryMinimumMessage>
        )}

        <CartFooter
          subtotal={subtotal}
          isCheckoutDisabled={isCheckoutDisabled}
        />
      </Footer>
    </Wrapper>
  );
};

const StyledTagName = styled.button.attrs({
  "data-css-override": "StyledTagName",
})`
  background: none;
  border: none;
  border-block-start: 1px solid var(--sidecart__divider-color);
  color: #595959;
  font-family: ${(props: { theme: WebConfig }) =>
    props.theme.primary_font.medium.font_family};
  font-size: 0.875rem;
  padding: var(--size-4) var(--size-6);
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow-y: scroll;
`;

const Content = styled.div`
  border-block-start: 1px solid var(--sidecart__divider-color);
  display: flex;
  flex-direction: column;
`;

const Footer = styled.footer`
  background-color: var(--sidecart__background-color);
  position: sticky;
  bottom: 0;
`;

const DeliveryMinimumMessage = styled.div`
  align-items: center;
  background: ${(props: { theme: WebConfig }) =>
    props.theme.global.primary_active_color};
  color: white;
  display: flex;
  line-height: 1.1;
  justify-content: center;
  padding: var(--size-2);
  position: sticky;
  bottom: 0;
  text-align: center;
  width: 100%;
`;
