import { type DeliveryAddress, type Location } from "@koala/sdk";
import Link from "next/link";
import SwitchToDeliveryConfirm from "./deliveryConfirm";
import { DeliveryForm } from "./deliveryForm";
import { StyledFormCTA, StyledFormContainer } from "./styles";
import { genericEventHandler } from "@/analytics/events";
import { EventNames, GlobalEvents } from "@/analytics/events/constants";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { MODAL } from "@/constants/events";
import { ROUTES } from "@/constants/routes";
import { type IBasketFulfillment } from "@/types/fulfillment";
import { type IGlobalFulfillmentModal } from "@/types/global";

interface Props {
  toggleFulfillmentModal: (
    show: boolean,
    location?: Location,
    fulfillmentType?: string
  ) => void;
  checkDeliveryAddress: (
    address: DeliveryAddress,
    location: Location,
    revalidate?: boolean,
    shouldAutoRedirect?: boolean
  ) => void;
  deliveryAddressValidating: boolean;
  fulfillmentModal: IGlobalFulfillmentModal;
  basketFulfillment: IBasketFulfillment;
  revalidate: boolean;
}

export const DeliveryModalStateContainer = ({
  fulfillmentModal,
  toggleFulfillmentModal,
  checkDeliveryAddress,
  basketFulfillment,
  deliveryAddressValidating,
  revalidate,
}: Props) => {
  const address = basketFulfillment?.address;

  if (fulfillmentModal.confirm) {
    return (
      <SwitchToDeliveryConfirm
        toggleFulfillmentModal={toggleFulfillmentModal}
        /** @TODO ensure that `location` isn't null. */
        // @ts-expect-error
        fulfillmentModalLocation={fulfillmentModal.location}
      />
    );
  }

  return (
    <StyledFormContainer>
      <DeliveryForm
        checkDeliveryAddress={(
          values: DeliveryAddress,
          shouldAutoRedirect?: boolean
        ) =>
          checkDeliveryAddress(
            values,
            /** @TODO ensure that `location` isn't null. */
            // @ts-expect-error
            fulfillmentModal.location,
            revalidate,
            shouldAutoRedirect
          )
        }
        initialValues={address}
        loading={deliveryAddressValidating}
      />

      {fulfillmentModal.disableModalClose && (
        <StyledFormCTA>
          <Link href={ROUTES.LOCATIONS} passHref={true}>
            <a
              onClick={() => {
                toggleFulfillmentModal(false);
                genericEventHandler(GlobalEvents.GENERIC__CTA, {
                  name: EventNames.CHANGE_LOCATION,
                  details: MODAL.FULFILLMENT_MANAGER,
                });
              }}
            >
              <StringAccessor accessor="cart_checkout.change_location_cta" />
            </a>
          </Link>
        </StyledFormCTA>
      )}
    </StyledFormContainer>
  );
};
