import { type SVGProps } from "react";
import { type IconProps } from "./types";

export const BackEnclosed = ({
  title,
  ...otherProps
}: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>
    <path
      d="M11.995 21.999C17.4756 21.999 22 17.4756 22 12.004C22 6.52341 17.4667 2 11.9852 2C6.5145 2 2 6.52341 2 12.004C2 17.4746 6.52341 21.999 11.995 21.999V21.999ZM11.995 19.9976C7.55874 19.9976 4.01128 16.4314 4.01128 12.004C4.01128 7.56765 7.55874 4.0004 11.9852 4.0004C16.4224 4.0004 19.9887 7.56765 19.9986 12.004C20.0085 16.4314 16.4323 19.9976 11.995 19.9976V19.9976ZM13.9281 16.76C14.2667 16.4314 14.2568 15.8988 13.9281 15.59L10.1204 12.004L13.9281 8.42779C14.2667 8.11798 14.2568 7.56666 13.9182 7.25784C13.5995 6.95793 13.1066 6.97773 12.7681 7.28754L8.8346 10.9894C8.25458 11.5298 8.25458 12.487 8.8346 13.0284L12.7681 16.7303C13.068 17.0203 13.648 17.0401 13.9281 16.76V16.76Z"
      fill="currentColor"
    />
  </svg>
);
