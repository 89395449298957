import debounce from "lodash/debounce";
import { useCallback, type DependencyList } from "react";

/**
 * Provides an abstraction to debounce & merge (a.k.a buffer) events in a react compatible way.
 *
 * The following will create a buffered listener function that will merge all calls made to that
 * listener function within the duration and report the args back to the callback as an array
 *
 * ```
 * const { listener } = useMergeDebounceListener(
    1000,
    ([item1, item2]) => {
      // do something with all the items
    },
    []
  );

  listener(item1)
  // 200ms pass...
  listener(item2)
 * ```
 */
export const useMergeDebounceListener = <Item>(
  duration: number,
  callback: (items: Item[]) => void,
  deps: DependencyList
): { listener: (item: Item) => void } => {
  let buffer: Item[] = [];
  const merge = (fn: (items: Item[]) => void) => {
    return (item: Item) => {
      buffer.push(item);
      fn(buffer);
    };
  };

  const listener = useCallback(
    merge(
      debounce((items) => {
        callback(items);
        buffer = [];
      }, duration)
    ),
    deps
  );

  return {
    listener,
  };
};
