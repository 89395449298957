import { type BasketOrder, type UpsellsRequest, UpsellsStep } from "@koala/sdk";
import { type IUpsellDetail } from "@/types/upsells";

// Create a conditionally filtered array based on whether food hall is enabled
export const getfilteredCrossSells = (
  data: IUpsellDetail | null,
  foodHallEnabled: boolean
) =>
  data?.upsells
    ? data.upsells.filter((upsell) =>
        foodHallEnabled ? upsell?.products[0]?.filter_tags?.length : true
      )
    : [];

export const deriveCrossSellParameters = (
  basketContent: BasketOrder,
  quantity: number
): UpsellsRequest => {
  const productIds = basketContent.basket_items.map((item) => item.product.id);

  return {
    max_upsells: quantity,
    products: productIds,
    step: UpsellsStep.add,
    store_location_id: basketContent.location.id,
  };
};
