import kebabCase from "lodash/kebabCase";
import styled from "styled-components";
import Label from "../label";
import { autocompleteInputId } from "@/components/fulfillmentManager/deliveryManager/deliveryForm/autocomplete-input-id";
import { COLORS } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface Props {
  icon?: string;
  placeholder?: string;
  meta?: {
    submitFailed: boolean;
    error: string | null;
  };
  input?: { name: string };
}

export const SearchInput = ({
  placeholder,
  icon,
  meta = { submitFailed: false, error: null },
  input = { name: "search-input" },
}: Props) => (
  <StyledInputContainer>
    {icon && <StyledIcon icon={icon} />}
    <Label
      // TODO - eventually this could be configurable
      visuallyHidden={true}
      htmlFor={input?.name}
    >
      {placeholder}
    </Label>
    <StyledSearchInput
      {...input}
      id={input?.name}
      placeholder={placeholder}
      error={Boolean(meta.submitFailed && meta.error)}
      aria-describedby={`${kebabCase(placeholder)}-search-input`}
    />

    <p role="alert" id={`${kebabCase(placeholder)}-search-input`}>
      {meta.submitFailed && meta.error}
    </p>
  </StyledInputContainer>
);

const StyledInputContainer = styled.div({
  paddingBottom: "10px",
  position: "relative",

  p: {
    color: COLORS.ERROR_RED,
    fontSize: ".75em",
    marginTop: ".25rem",
  },
});

export const StyledSearchInput = styled.input<{
  error?: boolean;
  padding?: string;
  id?: string;
}>(({ theme, error, padding, id }) => ({
  backgroundColor: theme.location_search.background_color ?? "transparent",
  border: `1px solid ${
    error
      ? theme.location_search.error_color ?? COLORS.ERROR_RED
      : theme.location_search.border_color ?? COLORS.LIGHTERGRAY
  }`,
  borderRadius: `${theme.location_search.border_radius ?? 0}px`,
  display: "block",
  flexBasis: "66.66%",
  height: "40px",
  width: "100%",
  textOverflow: "ellipsis",
  padding: padding ?? "2px 30px 0 30px",
  ...getFontStyles(theme.location_search.font, ["font_family", "font_size"]),
  ...(id?.includes(autocompleteInputId) && {
    height: `${theme.forms.field_height}px`,
    marginBottom: "20px",
  }),
  "@media (max-width: 767px)": {
    ...(!id?.includes(autocompleteInputId) && {
      borderRight: 0,
      fontSize: "16px",
      borderRadius: `${theme.location_search.border_radius ?? 0}px 0 0 ${
        theme.location_search.border_radius ?? 0
      }px}`,
    }),
  },
}));

export const StyledRadarAddressSearchInputWrapper = styled.div<{
  error?: boolean;
  padding?: string;
  id?: string;
}>(({ theme, error, padding, id }) => ({
  ...(id?.includes(autocompleteInputId) && {
    marginBottom: "20px",
  }),

  input: {
    ...(id?.includes(autocompleteInputId) && {
      height: `${theme.forms.field_height}px`,
    }),
    padding: padding ?? "2px 30px 0 40px",
    backgroundColor: theme.location_search.background_color ?? "transparent",
    border: `1px solid ${
      error
        ? theme.location_search.error_color ?? COLORS.ERROR_RED
        : theme.location_search.border_color ?? COLORS.LIGHTERGRAY
    }`,
    borderRadius: `${theme.location_search.border_radius ?? 0}px`,
    display: "block",
    flexBasis: "66.66%",
    height: "40px",
    width: "100%",
    textOverflow: "ellipsis",
    ...getFontStyles(theme.location_search.font, ["font_family", "font_size"]),
    "&:focus": {
      boxShadow: "none",
      border: `1px solid ${
        error
          ? theme.location_search.error_color ?? COLORS.ERROR_RED
          : theme.location_search.border_color ?? COLORS.LIGHTERGRAY
      }`,
    },
  },

  "& .radar-autocomplete-results-list": {
    display: "table",
  },
}));

interface StyledIconProps {
  icon: string;
}

const StyledIcon = styled.div<StyledIconProps>`
  background-image: url(${({ icon }) => icon});
  background-position: 10px center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 2px;
  height: 40px;
  width: 30px;
`;
