import { type TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<BooleanFilter>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]>>;
};

export type IdFilter = {
  equals?: InputMaybe<Scalars["ID"]>;
  gt?: InputMaybe<Scalars["ID"]>;
  gte?: InputMaybe<Scalars["ID"]>;
  in?: InputMaybe<Array<Scalars["ID"]>>;
  lt?: InputMaybe<Scalars["ID"]>;
  lte?: InputMaybe<Scalars["ID"]>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type KeystoneAdminMeta = {
  __typename?: "KeystoneAdminMeta";
  enableSessionItem: Scalars["Boolean"];
  enableSignout: Scalars["Boolean"];
  list?: Maybe<KeystoneAdminUiListMeta>;
  lists: Array<KeystoneAdminUiListMeta>;
};

export type KeystoneAdminMetaListArgs = {
  key: Scalars["String"];
};

export type KeystoneAdminUiFieldMeta = {
  __typename?: "KeystoneAdminUIFieldMeta";
  createView: KeystoneAdminUiFieldMetaCreateView;
  customViewsIndex?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  fieldMeta?: Maybe<Scalars["JSON"]>;
  isFilterable: Scalars["Boolean"];
  isOrderable: Scalars["Boolean"];
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  label: Scalars["String"];
  listView: KeystoneAdminUiFieldMetaListView;
  path: Scalars["String"];
  search?: Maybe<QueryMode>;
  viewsIndex: Scalars["Int"];
};

export type KeystoneAdminUiFieldMetaItemViewArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type KeystoneAdminUiFieldMetaCreateView = {
  __typename?: "KeystoneAdminUIFieldMetaCreateView";
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  Edit = "edit",
  Hidden = "hidden",
}

export type KeystoneAdminUiFieldMetaItemView = {
  __typename?: "KeystoneAdminUIFieldMetaItemView";
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  Edit = "edit",
  Hidden = "hidden",
  Read = "read",
}

export type KeystoneAdminUiFieldMetaListView = {
  __typename?: "KeystoneAdminUIFieldMetaListView";
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  Hidden = "hidden",
  Read = "read",
}

export type KeystoneAdminUiListMeta = {
  __typename?: "KeystoneAdminUIListMeta";
  description?: Maybe<Scalars["String"]>;
  fields: Array<KeystoneAdminUiFieldMeta>;
  hideCreate: Scalars["Boolean"];
  hideDelete: Scalars["Boolean"];
  initialColumns: Array<Scalars["String"]>;
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars["Boolean"];
  itemQueryName: Scalars["String"];
  key: Scalars["String"];
  label: Scalars["String"];
  labelField: Scalars["String"];
  listQueryName: Scalars["String"];
  pageSize: Scalars["Int"];
  path: Scalars["String"];
  plural: Scalars["String"];
  singular: Scalars["String"];
};

export type KeystoneAdminUiSort = {
  __typename?: "KeystoneAdminUISort";
  direction: KeystoneAdminUiSortDirection;
  field: Scalars["String"];
};

export enum KeystoneAdminUiSortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type KeystoneMeta = {
  __typename?: "KeystoneMeta";
  adminMeta: KeystoneAdminMeta;
};

export type LinkList = {
  __typename?: "LinkList";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  links?: Maybe<Scalars["JSON"]>;
  organizationId?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type LinkListCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  links?: InputMaybe<Scalars["JSON"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type LinkListOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
};

export type LinkListUpdateArgs = {
  data: LinkListUpdateInput;
  where: LinkListWhereUniqueInput;
};

export type LinkListUpdateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  links?: InputMaybe<Scalars["JSON"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type LinkListWhereInput = {
  AND?: InputMaybe<Array<LinkListWhereInput>>;
  NOT?: InputMaybe<Array<LinkListWhereInput>>;
  OR?: InputMaybe<Array<LinkListWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type LinkListWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createLinkList?: Maybe<LinkList>;
  createLinkLists?: Maybe<Array<Maybe<LinkList>>>;
  createPage?: Maybe<Page>;
  createPages?: Maybe<Array<Maybe<Page>>>;
  createStagedPage?: Maybe<StagedPage>;
  createStagedPages?: Maybe<Array<Maybe<StagedPage>>>;
  createVersion?: Maybe<Version>;
  createVersions?: Maybe<Array<Maybe<Version>>>;
  deleteLinkList?: Maybe<LinkList>;
  deleteLinkLists?: Maybe<Array<Maybe<LinkList>>>;
  deletePage?: Maybe<Page>;
  deletePages?: Maybe<Array<Maybe<Page>>>;
  deleteStagedPage?: Maybe<StagedPage>;
  deleteStagedPages?: Maybe<Array<Maybe<StagedPage>>>;
  deleteVersion?: Maybe<Version>;
  deleteVersions?: Maybe<Array<Maybe<Version>>>;
  endSession: Scalars["Boolean"];
  updateLinkList?: Maybe<LinkList>;
  updateLinkLists?: Maybe<Array<Maybe<LinkList>>>;
  updatePage?: Maybe<Page>;
  updatePages?: Maybe<Array<Maybe<Page>>>;
  updateStagedPage?: Maybe<StagedPage>;
  updateStagedPages?: Maybe<Array<Maybe<StagedPage>>>;
  updateVersion?: Maybe<Version>;
  updateVersions?: Maybe<Array<Maybe<Version>>>;
};

export type MutationCreateLinkListArgs = {
  data: LinkListCreateInput;
};

export type MutationCreateLinkListsArgs = {
  data: Array<LinkListCreateInput>;
};

export type MutationCreatePageArgs = {
  data: PageCreateInput;
};

export type MutationCreatePagesArgs = {
  data: Array<PageCreateInput>;
};

export type MutationCreateStagedPageArgs = {
  data: StagedPageCreateInput;
};

export type MutationCreateStagedPagesArgs = {
  data: Array<StagedPageCreateInput>;
};

export type MutationCreateVersionArgs = {
  data: VersionCreateInput;
};

export type MutationCreateVersionsArgs = {
  data: Array<VersionCreateInput>;
};

export type MutationDeleteLinkListArgs = {
  where: LinkListWhereUniqueInput;
};

export type MutationDeleteLinkListsArgs = {
  where: Array<LinkListWhereUniqueInput>;
};

export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};

export type MutationDeletePagesArgs = {
  where: Array<PageWhereUniqueInput>;
};

export type MutationDeleteStagedPageArgs = {
  where: StagedPageWhereUniqueInput;
};

export type MutationDeleteStagedPagesArgs = {
  where: Array<StagedPageWhereUniqueInput>;
};

export type MutationDeleteVersionArgs = {
  where: VersionWhereUniqueInput;
};

export type MutationDeleteVersionsArgs = {
  where: Array<VersionWhereUniqueInput>;
};

export type MutationUpdateLinkListArgs = {
  data: LinkListUpdateInput;
  where: LinkListWhereUniqueInput;
};

export type MutationUpdateLinkListsArgs = {
  data: Array<LinkListUpdateArgs>;
};

export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type MutationUpdatePagesArgs = {
  data: Array<PageUpdateArgs>;
};

export type MutationUpdateStagedPageArgs = {
  data: StagedPageUpdateInput;
  where: StagedPageWhereUniqueInput;
};

export type MutationUpdateStagedPagesArgs = {
  data: Array<StagedPageUpdateArgs>;
};

export type MutationUpdateVersionArgs = {
  data: VersionUpdateInput;
  where: VersionWhereUniqueInput;
};

export type MutationUpdateVersionsArgs = {
  data: Array<VersionUpdateArgs>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Page = {
  __typename?: "Page";
  content?: Maybe<Scalars["JSON"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  excludeFromSitemap?: Maybe<Scalars["Boolean"]>;
  homepage?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  organizationId?: Maybe<Scalars["String"]>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoImage?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type PageCreateInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  excludeFromSitemap?: InputMaybe<Scalars["Boolean"]>;
  homepage?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  seoDescription?: InputMaybe<Scalars["String"]>;
  seoImage?: InputMaybe<Scalars["String"]>;
  seoTitle?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type PageOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  excludeFromSitemap?: InputMaybe<OrderDirection>;
  homepage?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  seoDescription?: InputMaybe<OrderDirection>;
  seoImage?: InputMaybe<OrderDirection>;
  seoTitle?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
};

export type PageRelateToOneForCreateInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  create?: InputMaybe<PageCreateInput>;
};

export type PageRelateToOneForUpdateInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  create?: InputMaybe<PageCreateInput>;
  disconnect?: InputMaybe<Scalars["Boolean"]>;
};

export type PageUpdateArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type PageUpdateInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  excludeFromSitemap?: InputMaybe<Scalars["Boolean"]>;
  homepage?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  seoDescription?: InputMaybe<Scalars["String"]>;
  seoImage?: InputMaybe<Scalars["String"]>;
  seoTitle?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type PageWhereInput = {
  AND?: InputMaybe<Array<PageWhereInput>>;
  NOT?: InputMaybe<Array<PageWhereInput>>;
  OR?: InputMaybe<Array<PageWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  excludeFromSitemap?: InputMaybe<BooleanFilter>;
  homepage?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type Query = {
  __typename?: "Query";
  keystone: KeystoneMeta;
  linkList?: Maybe<LinkList>;
  linkLists?: Maybe<Array<LinkList>>;
  linkListsCount?: Maybe<Scalars["Int"]>;
  page?: Maybe<Page>;
  pages?: Maybe<Array<Page>>;
  pagesCount?: Maybe<Scalars["Int"]>;
  stagedPage?: Maybe<StagedPage>;
  stagedPages?: Maybe<Array<StagedPage>>;
  stagedPagesCount?: Maybe<Scalars["Int"]>;
  version?: Maybe<Version>;
  versions?: Maybe<Array<Version>>;
  versionsCount?: Maybe<Scalars["Int"]>;
};

export type QueryLinkListArgs = {
  where: LinkListWhereUniqueInput;
};

export type QueryLinkListsArgs = {
  orderBy?: Array<LinkListOrderByInput>;
  skip?: Scalars["Int"];
  take?: InputMaybe<Scalars["Int"]>;
  where?: LinkListWhereInput;
};

export type QueryLinkListsCountArgs = {
  where?: LinkListWhereInput;
};

export type QueryPageArgs = {
  where: PageWhereUniqueInput;
};

export type QueryPagesArgs = {
  orderBy?: Array<PageOrderByInput>;
  skip?: Scalars["Int"];
  take?: InputMaybe<Scalars["Int"]>;
  where?: PageWhereInput;
};

export type QueryPagesCountArgs = {
  where?: PageWhereInput;
};

export type QueryStagedPageArgs = {
  where: StagedPageWhereUniqueInput;
};

export type QueryStagedPagesArgs = {
  orderBy?: Array<StagedPageOrderByInput>;
  skip?: Scalars["Int"];
  take?: InputMaybe<Scalars["Int"]>;
  where?: StagedPageWhereInput;
};

export type QueryStagedPagesCountArgs = {
  where?: StagedPageWhereInput;
};

export type QueryVersionArgs = {
  where: VersionWhereUniqueInput;
};

export type QueryVersionsArgs = {
  orderBy?: Array<VersionOrderByInput>;
  skip?: Scalars["Int"];
  take?: InputMaybe<Scalars["Int"]>;
  where?: VersionWhereInput;
};

export type QueryVersionsCountArgs = {
  where?: VersionWhereInput;
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export type StagedPage = {
  __typename?: "StagedPage";
  content?: Maybe<Scalars["JSON"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  organizationId?: Maybe<Scalars["String"]>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoImage?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type StagedPageCreateInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  seoDescription?: InputMaybe<Scalars["String"]>;
  seoImage?: InputMaybe<Scalars["String"]>;
  seoTitle?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type StagedPageOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  seoDescription?: InputMaybe<OrderDirection>;
  seoImage?: InputMaybe<OrderDirection>;
  seoTitle?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
};

export type StagedPageUpdateArgs = {
  data: StagedPageUpdateInput;
  where: StagedPageWhereUniqueInput;
};

export type StagedPageUpdateInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  seoDescription?: InputMaybe<Scalars["String"]>;
  seoImage?: InputMaybe<Scalars["String"]>;
  seoTitle?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type StagedPageWhereInput = {
  AND?: InputMaybe<Array<StagedPageWhereInput>>;
  NOT?: InputMaybe<Array<StagedPageWhereInput>>;
  OR?: InputMaybe<Array<StagedPageWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type StagedPageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  equals?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<Scalars["String"]>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type Version = {
  __typename?: "Version";
  content?: Maybe<Scalars["JSON"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  excludeFromSitemap?: Maybe<Scalars["Boolean"]>;
  homepage?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  organizationId?: Maybe<Scalars["String"]>;
  page?: Maybe<Page>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoImage?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type VersionCreateInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  excludeFromSitemap?: InputMaybe<Scalars["Boolean"]>;
  homepage?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<PageRelateToOneForCreateInput>;
  seoDescription?: InputMaybe<Scalars["String"]>;
  seoImage?: InputMaybe<Scalars["String"]>;
  seoTitle?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type VersionOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  excludeFromSitemap?: InputMaybe<OrderDirection>;
  homepage?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  organizationId?: InputMaybe<OrderDirection>;
  seoDescription?: InputMaybe<OrderDirection>;
  seoImage?: InputMaybe<OrderDirection>;
  seoTitle?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  userId?: InputMaybe<OrderDirection>;
};

export type VersionUpdateArgs = {
  data: VersionUpdateInput;
  where: VersionWhereUniqueInput;
};

export type VersionUpdateInput = {
  content?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  excludeFromSitemap?: InputMaybe<Scalars["Boolean"]>;
  homepage?: InputMaybe<Scalars["Boolean"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<PageRelateToOneForUpdateInput>;
  seoDescription?: InputMaybe<Scalars["String"]>;
  seoImage?: InputMaybe<Scalars["String"]>;
  seoTitle?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type VersionWhereInput = {
  AND?: InputMaybe<Array<VersionWhereInput>>;
  NOT?: InputMaybe<Array<VersionWhereInput>>;
  OR?: InputMaybe<Array<VersionWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  excludeFromSitemap?: InputMaybe<BooleanFilter>;
  homepage?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  organizationId?: InputMaybe<StringFilter>;
  page?: InputMaybe<PageWhereInput>;
  seoDescription?: InputMaybe<StringFilter>;
  seoImage?: InputMaybe<StringFilter>;
  seoTitle?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type VersionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type PageQueryQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type PageQueryQuery = {
  __typename?: "Query";
  pages?: Array<{
    __typename?: "Page";
    id: string;
    title?: string | null;
    slug?: string | null;
    createdAt?: any | null;
    status?: string | null;
    excludeFromSitemap?: boolean | null;
    homepage?: boolean | null;
    content?: any | null;
    seoTitle?: string | null;
    seoDescription?: string | null;
    seoImage?: string | null;
  }> | null;
};

export type HomePageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageQueryQuery = {
  __typename?: "Query";
  pages?: Array<{
    __typename?: "Page";
    id: string;
    title?: string | null;
    slug?: string | null;
    createdAt?: any | null;
    status?: string | null;
    excludeFromSitemap?: boolean | null;
    homepage?: boolean | null;
    content?: any | null;
    seoTitle?: string | null;
    seoDescription?: string | null;
    seoImage?: string | null;
  }> | null;
};

export type HasHomePageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type HasHomePageQueryQuery = {
  __typename?: "Query";
  pages?: Array<{ __typename?: "Page"; id: string }> | null;
};

export type SiteIndexedPageQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SiteIndexedPageQueryQuery = {
  __typename?: "Query";
  pages?: Array<{ __typename?: "Page"; slug?: string | null }> | null;
};

export type StagedPageQueryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StagedPageQueryQuery = {
  __typename?: "Query";
  stagedPage?: {
    __typename?: "StagedPage";
    title?: string | null;
    content?: any | null;
  } | null;
};

export type LinkListQueryQueryVariables = Exact<{ [key: string]: never }>;

export type LinkListQueryQuery = {
  __typename?: "Query";
  linkLists?: Array<{
    __typename?: "LinkList";
    id: string;
    title?: string | null;
    slug?: string | null;
    links?: any | null;
  }> | null;
};

export const PageQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "pageQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "slug" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: {
                              kind: "StringValue",
                              value: "publish",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "excludeFromSitemap" },
                },
                { kind: "Field", name: { kind: "Name", value: "homepage" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "seoTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seoDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "seoImage" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageQueryQuery, PageQueryQueryVariables>;
export const HomePageQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "homePageQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "homepage" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "excludeFromSitemap" },
                },
                { kind: "Field", name: { kind: "Name", value: "homepage" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "seoTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seoDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "seoImage" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomePageQueryQuery, HomePageQueryQueryVariables>;
export const HasHomePageQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "hasHomePageQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "homepage" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HasHomePageQueryQuery,
  HasHomePageQueryQueryVariables
>;
export const SiteIndexedPageQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "siteIndexedPageQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludeFromSitemap" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: {
                              kind: "StringValue",
                              value: "publish",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SiteIndexedPageQueryQuery,
  SiteIndexedPageQueryQueryVariables
>;
export const StagedPageQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "stagedPageQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stagedPage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StagedPageQueryQuery,
  StagedPageQueryQueryVariables
>;
export const LinkListQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "linkListQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "linkLists" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "links" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LinkListQueryQuery, LinkListQueryQueryVariables>;
