import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "@/redux";
import authActions from "@/redux/auth/actions";
import {
  addScriptToDOM,
  isScriptInDOM,
  removeScriptFromDOM,
} from "@/utils/global";
import {
  getActiveRewardsAndOffersLength,
  getUnreadPersonalMessagesLength,
} from "@/utils/loyalty";
import { pluralizeString, safelyGetString } from "@/utils/stringHelpers";

export const useRewardsState = () => {
  const { loyaltyState, myOffers, messages, redeemables } = useSelector(
    (state) => state.app.me
  );

  const currentPoints = loyaltyState?.points ?? 0;

  return {
    currentPoints: loyaltyState?.points ? loyaltyState?.points.toString() : "0",
    rewardsCount:
      getActiveRewardsAndOffersLength(redeemables, myOffers, currentPoints) ||
      0,
    inboxNotificationsCount: getUnreadPersonalMessagesLength(messages),
  };
};

export const useAccount = () => {
  const dispatch = useDispatch();
  const strings = useSelector((state) => state.app.cmsConfig.strings);
  const user = useSelector((state) => state.app.me);

  const loyalty = user.loyaltyState;
  const details = user?.data;
  const points = loyalty?.points ?? 0;

  return {
    loggedIn: details ? true : false,
    userDetails: details,
    loyalty,
    points: {
      message: pluralizeString(
        safelyGetString(strings, "rewards.rewards_counter"),
        points !== 1
      ),
      value: points,
    },
    availableRewards:
      getActiveRewardsAndOffersLength(
        user.redeemables,
        user.myOffers,
        points
      ) || 0,
    unreadMessages: getUnreadPersonalMessagesLength(user.messages),
    logout: (cancelRedirect?: boolean) =>
      dispatch(authActions.logout(cancelRedirect)),
  };
};

export const useAddScript = (scriptSource: string) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const addScript = useCallback(() => {
    if (isScriptInDOM(scriptSource)) {
      setIsLoaded(true);
    } else {
      addScriptToDOM(scriptSource, () => {
        setIsLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    addScript();

    return () => {
      removeScriptFromDOM(scriptSource);
    };
  }, [addScript]);

  return isLoaded;
};
