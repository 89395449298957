import { StyledQuantityToggle, StyledQuantityToggleButton } from "./styles";

interface Props {
  quantity: number;
  maxQuantity: number;
  onToggle: (quantity: number) => void;
}

const QuantityToggle = ({ onToggle, quantity, maxQuantity }: Props) => (
  <StyledQuantityToggle>
    <span>Qty.</span>
    <StyledQuantityToggleButton
      inactive={quantity <= 1}
      aria-label="Decrease the quantity by one"
      onClick={() => {
        // Ensure keyboard manipulation can't exceed min
        const newQuantity = quantity - 1;
        if (newQuantity < 1) {
          return;
        }

        onToggle(newQuantity);
      }}
    >
      &ndash;
    </StyledQuantityToggleButton>
    <span>{quantity}</span>
    <StyledQuantityToggleButton
      inactive={quantity >= maxQuantity}
      aria-label="Increase the quantity by one"
      onClick={() => {
        // Ensure keyboard manipulation can't exceed max
        const newQuantity = quantity + 1;
        if (newQuantity > maxQuantity) {
          return;
        }

        onToggle(newQuantity);
      }}
    >
      +
    </StyledQuantityToggleButton>
  </StyledQuantityToggle>
);

export default QuantityToggle;
