import type * as Stitches from "@stitches/react";
import { Base, Field } from "./base";
import { useConfigOverride } from "@/features/configs/configs";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface Props extends React.HTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
  required?: boolean;
  error?: string;
  css?: Stitches.CSS;
}

export function Textarea({
  label,
  name,
  required = false,
  error,
  css = {},
  ...otherProps
}: Props) {
  const baseProps = { label, name, required, error };
  const { background_color, border_color, border_radius, font } =
    useConfigOverride("forms");

  return (
    <Base {...baseProps}>
      <Field
        id={name}
        required={required}
        as="textarea"
        placeholder=" "
        aria-label={label}
        css={{
          height: "$24",
          backgroundColor: background_color ?? "transparent",
          borderColor: border_color ?? "$shade300",
          borderRadius: `${Number(border_radius)}px`,
          ...getFontStyles(font, [
            "color",
            "font_family",
            "font_weight",
            "font_style",
          ]),
          ...css,
        }}
        {...otherProps}
      />
    </Base>
  );
}
