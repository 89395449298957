import styled from "styled-components";

export const Footer = styled.footer`
  align-items: center;
  border-top: 1px solid var(--modal__border-color);
  display: flex;
  gap: var(--size-2);
  justify-content: flex-end;
  min-height: var(--size-16);
  padding: var(--size-3) var(--size-3) var(--size-3) var(--size-6);
  transform: translate3d(0, 0, 0);
`;

export const CancelButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: var(--modal__button-color);
  font-weight: bold;
  padding: 0 var(--size-5);
`;
