import {
  type BasketItem,
  CONVEYANCE_TYPES,
  type OrderDetails,
} from "@koala/sdk";
import {
  StyledReorderForm,
  StyledReorderFormHeader,
  StyledReorderFormItemList,
  StyledReorderFormSubtitle,
  StyledReorderFormTitle,
  StyledReorderModalButton,
  StyledReorderModalButtonWrapper,
  StyledReorderModalListItem,
  StyledReorderModalModifiers,
  StyledReorderModalQuantity,
} from "./styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { ItemRecipient } from "@/components/uielements/itemRecipient";
import { DATE_FORMAT } from "@/constants/dates";
import { API_FOR_CONVEYANCE_TYPES_SWAP } from "@/constants/global";
import { ROUTES } from "@/constants/routes";
import { deriveBasketModifiersList } from "@/utils/basket";
import { formatDate } from "@/utils/dates";

interface Props {
  meOrderDetails: OrderDetails;
  basketLoading: boolean;
  reorderBasket: (order: OrderDetails, label: string) => void;
  route: string;
}

export const ReorderForm = ({
  meOrderDetails,
  basketLoading,
  reorderBasket,
  route,
}: Props) => {
  const orderConveyance =
    API_FOR_CONVEYANCE_TYPES_SWAP[
      meOrderDetails.order_data.basket.conveyance_type.type
    ] || CONVEYANCE_TYPES.PICKUP;
  const reorderAnalyticsLabel =
    route === ROUTES.STORE ? "Store Menu" : "Store Locator";

  /**
   * Render the basket item content HTML structure for the reorder form
   *
   * @param basketItem BasketItem
   * @param id number
   * @returns JSX.Element
   */
  const renderBasketItemContent = (
    basketItem: BasketItem,
    id: number
  ): JSX.Element => (
    <StyledReorderModalListItem key={`${basketItem.id}-${id}`}>
      <StyledReorderModalQuantity>
        {basketItem.quantity}
      </StyledReorderModalQuantity>
      <div>
        {basketItem.product.name}
        <StyledReorderModalModifiers>
          {deriveBasketModifiersList(basketItem)}
        </StyledReorderModalModifiers>
        {basketItem.recipient && (
          <ItemRecipient
            recipient={basketItem.recipient}
            themeContext="checkout"
          />
        )}
      </div>
    </StyledReorderModalListItem>
  );

  return (
    <StyledReorderForm>
      <StyledReorderFormHeader>
        <StyledReorderFormTitle>
          {orderConveyance} from {meOrderDetails.store_location?.label}
        </StyledReorderFormTitle>
        <StyledReorderFormSubtitle>
          Order #{meOrderDetails.order_data.order.order_number} -{" "}
          {formatDate(meOrderDetails.ready_at, DATE_FORMAT.MONTH_DAY_YEAR)}
        </StyledReorderFormSubtitle>
      </StyledReorderFormHeader>

      <StyledReorderFormItemList>
        {meOrderDetails.order_data.basket.basket_items.map((basketItem, id) =>
          // @ts-expect-error TODO: update type of function
          renderBasketItemContent(basketItem, id)
        )}
      </StyledReorderFormItemList>

      <StyledReorderModalButtonWrapper>
        <StyledReorderModalButton
          buttonTheme="primary"
          submitting={basketLoading}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            reorderBasket(meOrderDetails, reorderAnalyticsLabel);
          }}
        >
          <StringAccessor accessor="reorders.add_to_cart_cta" />
        </StyledReorderModalButton>
      </StyledReorderModalButtonWrapper>
    </StyledReorderForm>
  );
};
