import { readableColor } from "polished";
import { type ReactNode } from "react";
import { Stack } from "@/components/ui";
import { useConfigOverride } from "@/redux/cmsConfig";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safePolished } from "@/utils/safePolished";

interface Props {
  children: ReactNode;
}

export function Notification({ children }: Props) {
  const { background_color, font } = useConfigOverride("header");
  const { color } = getFontStyles(font, ["color"]);

  return (
    <Stack
      position="center"
      css={{
        background: color,
        borderRadius: "100%",
        color: background_color ?? safePolished(readableColor)(color),
        display: "flex",
        height: "$5",
        fontSize: ".625em",
        lineHeight: 1,
        textAlign: "center",
        width: "$5",
      }}
    >
      {children}
    </Stack>
  );
}
