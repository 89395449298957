import { CONVEYANCE_TYPES, type Location } from "@koala/sdk";
import * as React from "react";
import {
  StickWithButton,
  StyledConfirm,
  StyledContinueButton,
} from "../../styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { useSelector } from "@/redux";
import { selectConveyance } from "@/redux/conveyanceMode/reducer";

interface Props {
  fulfillmentModalLocation: Location;
  toggleFulfillmentModal: (
    show: boolean,
    location?: Location,
    fulfillmentType?: string,
    confirm?: boolean,
    timeslotOverride?: string
  ) => void;
}

export default function SwitchToDeliveryConfirm({
  fulfillmentModalLocation,
  toggleFulfillmentModal,
}: Props) {
  const { time_wanted } = useSelector(selectConveyance);

  return (
    <StyledConfirm>
      <StringAccessor
        tag="h2"
        accessor="delivery.switch_to_delivery_header"
        html={true}
      />
      <StringAccessor
        tag="p"
        accessor="delivery.switch_to_delivery_subheader"
        html={true}
      />
      <StyledContinueButton
        type="button"
        onClick={() =>
          toggleFulfillmentModal(
            true,
            fulfillmentModalLocation,
            CONVEYANCE_TYPES.DELIVERY,
            false,
            time_wanted
          )
        }
      >
        <StringAccessor accessor="delivery.switch_to_delivery_cta" />
      </StyledContinueButton>
      <div>
        <StickWithButton onClick={() => toggleFulfillmentModal(false)}>
          <StringAccessor accessor="delivery.stick_with_pickup_cta" />
        </StickWithButton>
      </div>
    </StyledConfirm>
  );
}
