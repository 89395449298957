import { CALS_IDENTIFIER, type MenuProduct } from "@koala/sdk";
import { StyledAddDetails, StyledAddToBag } from "./styles";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import QuantityToggle from "@/components/uielements/quantityToggle";
import { toDollars } from "@/utils/basket";

interface Props {
  product: MenuProduct;
  quantity: number;
  editing: boolean;
  disabled: boolean;
  toggleQuantity: (quantity: number) => void;
  addToOrder: (product: MenuProduct) => void;
}

export function Footer({
  disabled,
  product,
  addToOrder,
  quantity,
  toggleQuantity,
  editing,
}: Props) {
  const calorieDisplay =
    product.calorie_range ||
    (product.final_calories
      ? `${product.final_calories} ${CALS_IDENTIFIER}`
      : "");

  return (
    <div>
      <StyledAddDetails>
        <QuantityToggle
          quantity={quantity}
          onToggle={toggleQuantity}
          maxQuantity={product.max_quantity || 999}
        />
        {calorieDisplay && (
          <>
            <span>|</span>
            <p aria-live="polite">{calorieDisplay}</p>
          </>
        )}
      </StyledAddDetails>
      <StyledAddToBag
        size="medium"
        onClick={() => addToOrder(product)}
        className="koala__item-detail-add-button"
        disabled={disabled}
        aria-live="polite"
        data-testid="add-product-to-basket-button"
      >
        {/* If location is disabled */}
        {disabled && (
          <StringAccessor accessor="locations.location_inactive" html={true} />
        )}

        {/* Otherwise, check ot see if we're in editing mode */}
        {!disabled &&
          (editing ? (
            <>Update Item</>
          ) : (
            <>
              <StringAccessor accessor="cart_checkout.add_text" /> - $
              {toDollars(product.final_cost, quantity)}
            </>
          ))}
      </StyledAddToBag>
    </div>
  );
}
