import { CONVEYANCE_TYPES } from "@koala/sdk";
import Link from "next/link";
import { useSelector } from "react-redux";
import styled, { type StyledProps } from "styled-components";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents, EventNames } from "@/analytics/events/constants";
import { Heading, Text } from "@/components/cart/text";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { FeatureAccessor } from "@/components/featureAccessor";
import { Button } from "@/components/uielements/button/button";
import { Render } from "@/components/uielements/render";
import { Stack } from "@/components/uielements/stack/stack";
import { K_ANALYTICS_CTA_LOCATIONS } from "@/constants/events";
import { FEATURE_FLAGS } from "@/constants/features";
import { ROUTES } from "@/constants/routes";
import { useOrganization } from "@/features/configs/organization";
import { type RootState } from "@/types/app";
import { useDialogContext } from "@/utils/useDialogContext";

export const Location = () => {
  const { location, fulfillment } = useSelector(
    (state: RootState) => state.app.basket
  );
  const { data: orgData } = useOrganization();
  const isSingleLocationEnabled =
    orgData?.locations.features.isSingleLocation.enabled;
  const { onCancel } = useDialogContext();

  return (
    <Wrapper fulfillmentType={fulfillment?.type}>
      <Stack align="leading" direction="vertical" gap="var(--size-1)">
        <Heading>Ordering from:</Heading>

        <Stack
          align="leading"
          direction="vertical"
          gap="0"
          overrides={{
            color: "#595959",
            fontSize: ".875rem",
          }}
        >
          <Text>{location?.cached_data?.label}</Text>
          <Text>{location?.cached_data?.phone_number}</Text>
        </Stack>
      </Stack>

      <FeatureAccessor
        featureFlag={[
          FEATURE_FLAGS.GLOBAL__LOCATIONS_PAGE,
          FEATURE_FLAGS.CART__CHANGE_LOCATION,
        ]}
      >
        <Render condition={!isSingleLocationEnabled}>
          <Link href={ROUTES.LOCATIONS}>
            <Button
              data-testid="change-location-button"
              onClick={() => {
                onCancel();

                genericEventHandler(GlobalEvents.GENERIC__CTA, {
                  name: EventNames.CHANGE_LOCATION,
                  details: K_ANALYTICS_CTA_LOCATIONS.SIDECART,
                });
              }}
            >
              <StringAccessor accessor="cart_checkout.change_location_cta" />
            </Button>
          </Link>
        </Render>
      </FeatureAccessor>
    </Wrapper>
  );
};

interface WrapperProps {
  fulfillmentType: CONVEYANCE_TYPES;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ fulfillmentType }: StyledProps<WrapperProps>) =>
    `--location-wrapper__display-value: ${
      fulfillmentType === CONVEYANCE_TYPES.DELIVERY ? "none" : "flex"
    }`};

  // if fulfillment type is delivery and mobile view - don't surface order location
  // and delivery address at the same time to save space in side cart
  display: var(--location-wrapper__display-value);
  flex-direction: row;
  align-items: start;
  gap: var(--size-1);
  width: 100%;
  padding: var(--size-6);

  // change location CTA is moved to right side to create more space in side cart if mobile view
  & > :last-child {
    margin-left: auto;
  }

  @media (min-width: 768px) {
    --location-wrapper__display-value: flex;
    flex-direction: column;
    padding-right: var(--size-24);

    & > :last-child {
      margin-left: 0;
    }
  }
`;
