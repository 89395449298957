import * as Dialog from "@radix-ui/react-dialog";
import { useEffect } from "react";
import { ProductEditor } from "./editor";
import { CustomizeModalContent, Overlay, StyledCustomizeModal } from "./styles";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents } from "@/analytics/events/constants";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { useDispatch, useSelector } from "@/redux";
import customizeActions from "@/redux/customize/actions";

export const Customizer = () => {
  const { open, product } = useSelector(({ app }) => app.customize);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      genericEventHandler(GlobalEvents.STORE__PRODUCT_VIEW, {
        name: product?.name,
        details: product?.global_id,
      });
    }
  }, [open, product]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <StyledCustomizeModal
          id="customize"
          className={CSS_CLASSES.PRODUCT_DETAIL.CONTAINER}
        >
          <Overlay />
          <CustomizeModalContent
            onEscapeKeyDown={() => dispatch(customizeActions.clearProduct())}
            onPointerDownOutside={() =>
              dispatch(customizeActions.clearProduct())
            }
          >
            {open && <ProductEditor />}
          </CustomizeModalContent>
        </StyledCustomizeModal>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
