import { type ReactNode } from "react";

interface Props {
  children: ReactNode;
  condition: boolean;
}
export function Render({ children, condition }: Props) {
  if (!condition) {
    return null;
  }

  return <>{children}</>;
}
