import * as Dialog from "@radix-ui/react-dialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CloseCart } from "./close-cart";
import { Contents } from "./contents";
import { EmptyCart } from "./empty-cart";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { ORDER } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import globalActions from "@/redux/global/actions";
import { type RootState } from "@/types/app";
import { useAnimatedVisibility } from "@/utils/useAnimatedVisibility";
import { DialogContext } from "@/utils/useDialogContext";

interface CartProviderProps {
  children: React.ReactNode;
  onCancel: () => void;
}

const CartProvider = ({ children, onCancel }: CartProviderProps) => {
  return (
    <DialogContext.Provider value={{ onCancel }}>
      {children}
    </DialogContext.Provider>
  );
};

export const Cart = () => {
  const { isMounted, shouldDisplay, toggle, ref } = useAnimatedVisibility();
  const basketOpen = useSelector(
    (state: RootState) => state.app.global.basketOpen
  );

  const { location, content: basketContent } = useSelector(
    (state: RootState) => state.app.basket
  );

  const dispatch = useDispatch();

  const hasItems = !!(basketContent && basketContent.basket_items.length);
  const isLocationDisabled =
    (location?.id && !location.provider_enabled) || false;

  const closeCart = () => {
    toggle();
    dispatch(globalActions.toggleBasket(false));
  };

  useEffect(() => {
    if (basketOpen) {
      toggle();
    }
  }, [basketOpen]);

  return (
    <Dialog.Root open={isMounted}>
      <Dialog.Portal>
        <Container
          // @ts-expect-error: refs are badly typed
          ref={ref}
          shouldDisplay={shouldDisplay}
          hasItems={hasItems}
        >
          <Overlay
            shouldDisplay={shouldDisplay}
            data-testid="sidecart-overlay"
          />

          <CartProvider
            onCancel={() => {
              closeCart();
            }}
          >
            <Panel
              className={CSS_CLASSES.SIDECART.CONTAINER}
              shouldDisplay={shouldDisplay}
              onEscapeKeyDown={closeCart}
              onPointerDownOutside={closeCart}
            >
              <Title>Ordering from {location.label}</Title>
              <CloseCart />

              {!isLocationDisabled && hasItems ? <Contents /> : <EmptyCart />}
            </Panel>
          </CartProvider>
        </Container>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

interface ContainerStyles {
  hasItems: boolean;
  shouldDisplay: boolean;
}

const Container = styled.div<ContainerStyles>(
  ({ theme, shouldDisplay, hasItems }) => {
    const primary = getFontStyles(theme.sidecart.font);
    const secondary = getFontStyles(theme.sidecart.secondary_font);

    return {
      "--sidecart__background-color": hasItems
        ? theme.sidecart.background_color ?? "transparent"
        : theme.sidecart.background_color_no_items,
      "--sidecart__secondary-background-color":
        theme.sidecart.background_color_no_items,
      "--sidecart__foreground-color": primary.color ?? "black",
      "--sidecart__secondary-foreground-color": secondary.color ?? "black",
      "--sidecart__divider-color": theme.sidecart.border_color ?? "transparent",
      "--sidecart-container__opacity": shouldDisplay ? 1 : 0.01,

      alignItems: "stretch",
      bottom: 0,
      display: " flex",
      justifyContent: " flex-end",
      left: 0,
      placeItems: "center",
      position: "fixed",
      right: 0,
      top: 0,
      transition: "all 0.6s linear",
      zIndex: ORDER.CART,
    };
  }
);

interface OverlayStyles {
  shouldDisplay: boolean;
}

const Overlay = styled(Dialog.Overlay)`
  ${({ shouldDisplay }: OverlayStyles) => `
    --sidecart-overlay__opacity: ${shouldDisplay ? 1 : 0.01};
  `}

  background: rgba(26, 26, 26, 0.16);
  bottom: 0;
  left: 0;
  opacity: var(--sidecart-overlay__opacity);
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s ease;
  z-index: 0;
`;

interface PanelStyles {
  shouldDisplay: boolean;
}

const Panel = styled(Dialog.Content)<PanelStyles>(
  ({ shouldDisplay, theme }) => ({
    backgroundColor: "var(--sidecart__background-color)",
    color: "var(--sidecart__foreground-color)",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    transform: `translateY(${shouldDisplay ? "0%" : "100%"})`,
    transition: "all 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
    width: "100%",
    zIndex: 1,
    ...getFontStyles(theme.sidecart.font),

    "&:focus": {
      outline: "none",
    },

    "@media (min-width: 768px)": {
      transform: `translateX(${shouldDisplay ? "0" : "100%"}) translateY(0%)`,
      width: "var(--size-96)",
    },
  })
);

const Title = styled(Dialog.Title)(({ theme }) => ({
  ...getFontStyles(theme.sidecart.secondary_font, [
    "color",
    "font_size",
    "font_weight",
  ]),
  fontFamily: theme.primary_font.bold.font_family,
  margin: 0,
  overflow: "hidden",
  padding: "var(--size-4) var(--size-12)",
  textAlign: "center",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  "@media (min-width: 768px)": {
    display: "none",
  },
}));
