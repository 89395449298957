import { type TagItem } from "@koala/sdk";
import { StyledMarketingTag, StyledTagIcon } from "./styles";

interface Props {
  position: string;
  tag: TagItem;
}

const MarketingTag = ({ tag, position }: Props) => {
  /** @TODO ensure that tag style attributes are defined. */
  return (
    <>
      {/* @ts-ignore */}
      <StyledMarketingTag
        backgroundColor={tag.background_color}
        textColor={tag.text_color}
        position={position}
      >
        {tag.icon_image_url && (
          <StyledTagIcon
            src={tag.icon_image_url}
            alt={`${tag.label} Tag Icon`}
            position={position}
          />
        )}
        {!tag.hide_label ? tag.label : ""}
      </StyledMarketingTag>
    </>
  );
};

export default MarketingTag;
