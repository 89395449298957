import styled from "styled-components";
import Icon from "@/components/uielements/icon";

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.span`
  align-items: center;
  border-top: 1px solid var(--navigation-item__border-color);
  color: var(--navigation-item__color);
  display: flex;
  height: var(--navigation-item__height, var(--size-18));
  font-family: var(--navigation-item__font-family);
  font-size: var(--navigation-item__font-size);
  line-height: normal;
  font-weight: var(--navigation-item__font-weight, bold);
  gap: var(--size-2);
  padding: var(--navigation-item__padding-y, 0)
    var(--navigation-item__padding-x, var(--size-4));

  &:active {
    opacity: 0.35;
  }
`;

export const Caret = styled(Icon.Caret)`
  flex: none;
  margin-left: auto;
`;
