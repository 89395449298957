import { useRouter } from "next/router";
import { forwardRef, useEffect, useState } from "react";
import {
  CartButton,
  CartCount,
  CartIcon,
  CartText,
  CartTextAdding,
  CartTextDefault,
  Container,
} from "./contextual-cart.styles";
import { ConnectedCartIcon } from "@/components/account/uielements";
import Icon from "@/components/uielements/icon";
import { ROUTES } from "@/constants/routes";
import { useDispatch, useSelector } from "@/redux";
import basketActions from "@/redux/basket/actions";
import globalActions from "@/redux/global/actions";
import { type Timeout } from "@/types/timeout";
import { getBasketItemsCountWithQuantity } from "@/utils/basket";
import { getLocationId } from "@/utils/locations";
import { useAnimatedVisibility } from "@/utils/useAnimatedVisibility";

interface DrawerProps {
  isVisible: boolean;
  isAdding: boolean;
  itemCount: number;
  showCart: () => void;
}

const Drawer = forwardRef(
  ({ isVisible, isAdding, itemCount, showCart }: DrawerProps, ref) => {
    /*
     * Per the useState documentation:
     * The initialState argument is the state used during the initial render. In subsequent renders, it is disregarded.
     * [...] you may provide a function instead, which will be executed only on the initial render
     *
     * https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
     *
     * we also need the process.browser check here so this doesn't run on the server
     */
    const [container] = useState(() =>
      process.browser ? document.createElement("div") : null
    );

    useEffect(() => {
      if (container) {
        document.body.appendChild(container);
      }

      return () => {
        container && document.body.removeChild(container);
      };
    }, []);

    return (
      <Container
        // @ts-expect-error ref type mismatch.
        ref={ref}
        isVisible={isVisible}
      >
        <CartButton isAdding={isAdding} onClick={showCart}>
          <CartIcon isAdding={isAdding}>
            <ConnectedCartIcon />
            <Icon.CheckMark title="Checkmark icon" />
          </CartIcon>

          <CartText>
            <CartTextDefault isAdding={isAdding}>View Cart</CartTextDefault>
            <CartTextAdding isAdding={isAdding}>
              Item Added to Cart
            </CartTextAdding>
          </CartText>

          <CartCount isVisible={isVisible}>
            <span>{itemCount}</span>
          </CartCount>
        </CartButton>
      </Container>
    );
  }
);

Drawer.displayName = "Drawer";

export const ContextualCart = () => {
  const { isMounted, shouldDisplay, ref, toggle } = useAnimatedVisibility();

  const router = useRouter();
  const dispatch = useDispatch();
  const { basket_items } = useSelector((state) => state.app.basket.content);
  const { itemAdded, location } = useSelector((state) => state.app.basket);
  const { basketOpen } = useSelector((state) => state.app.global);
  const { webConfig } = useSelector((state) => state.app.cmsConfig);

  useEffect(() => {
    if (basket_items?.length && !isMounted) {
      toggle();
    }
  }, [basket_items]);

  useEffect(() => {
    let timeout: Timeout;

    if (itemAdded) {
      timeout = setTimeout(() => {
        dispatch(basketActions.basketItemSuccess(false));
      }, 2500);
    }

    return () => clearTimeout(timeout);
  }, [itemAdded]);

  useEffect(() => {
    let timeout: Timeout;

    const itemCount = basket_items?.length ?? 0;

    if (!basketOpen && !isMounted && itemCount > 0) {
      // the timeout is for the animation transition between the side cart and sticky cta
      timeout = setTimeout(toggle, 150);
    }

    if (!basketOpen && isMounted && itemCount === 0) {
      toggle();
    }

    return () => clearTimeout(timeout);
  }, [basketOpen, basket_items]);

  return isMounted ? (
    <Drawer
      ref={ref}
      isVisible={shouldDisplay}
      isAdding={itemAdded}
      itemCount={getBasketItemsCountWithQuantity(basket_items)}
      showCart={() => {
        toggle();

        if (router.pathname === ROUTES.STORE) {
          dispatch(globalActions.toggleBasket(true));
        } else {
          void router.push(
            `/store/${getLocationId(location, webConfig)}/${
              location.label
            }?openCart=true`,
            undefined,
            { shallow: true }
          );
        }
      }}
    />
  ) : null;
};
