import * as Dialog from "@radix-ui/react-dialog";
import styled, { type DefaultTheme } from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface StyleProps {
  isVisible?: boolean;
}

export const Container = styled.div<StyleProps>`
  ${({ isVisible }) => `
    --modal-container__opacity: ${isVisible ? 1 : 0.01};
  `}

  bottom: 0;
  display: grid;
  left: 0;
  opacity: var(--modal-container__opacity);
  place-items: center;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.2s ease;
  z-index: 1000;
`;

export const ContentWrapper = styled.div<StyleProps>`
  ${({ isVisible }) => `
    --modal-content-wrapper__scale: ${isVisible ? 1 : 0.9};
  `}

  transform: scale(var(--modal-content-wrapper__scale));
  transition: all 0.2s ease;
  max-width: var(--size-128);
  width: 100%;

  @media (min-width: 768px) {
    width: var(--size-96);
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s ease;
  z-index: 0;
`;

export const Content = styled(Dialog.Content)<StyleProps>(
  ({ theme }: { theme: DefaultTheme }) => {
    const primaryFontProperties = getFontStyles(theme.modals.font);
    const secondaryFontProperties = getFontStyles(theme.modals.secondary_font);

    return {
      " --modal__background":
        theme.modals.background_color ?? theme.global.body_color,
      "--modal__border-color":
        theme.modals.border_color ?? theme.global.primary_border_color,
      "--modal__border-radius": `${theme.modals.border_radius ?? 0}px`,
      "--modal__color": primaryFontProperties.color,
      "--modal-title__color": secondaryFontProperties.color,
      "--modal-title__font-family": secondaryFontProperties.fontFamily,
      "--modal-title__font-size": secondaryFontProperties.fontSize,
      "--modal__button-color": theme.modals.active_font_color ?? "black",

      backgroundColor: "var(--modal__background)",
      border: "1px solid var(--modal__border-color)",
      borderRadius: "var(--modal__border-radius)",
      color: "var(--modal__color)",
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - 8rem)",
      width: "100%",
    };
  }
);
