import styled from "styled-components";
import { LAYOUT, SPACING_SCALE } from "@/constants/styles";

export const StyledChevronIcon = styled.span`
  top: 0px;
  padding-left: ${LAYOUT.GUTTER / 4}px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledStarIcon = styled.span`
  margin-right: ${LAYOUT.GUTTER / 4}px;
`;

export const CustomAccountIcon = styled.img`
  object-fit: contain;
  max-height: ${SPACING_SCALE.LARGE}px;
  max-width: ${SPACING_SCALE.LARGE}px;
`;
