import Link from "next/link";
import styled from "styled-components";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { ROUTES } from "@/constants/routes";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { toDollars } from "@/utils/basket";
import { fireGaEvent, gaCats, gaActions } from "@/utils/googleAnalytics";
import { useDialogContext } from "@/utils/useDialogContext";

interface Props {
  isCheckoutDisabled?: boolean;
  subtotal: number;
}

export const Footer = ({ subtotal, isCheckoutDisabled }: Props) => {
  const { onCancel } = useDialogContext();

  return (
    <Container>
      <Link href={ROUTES.CHECKOUT}>
        <Continue
          data-testid="go-to-checkout-button"
          // @ts-expect-error
          disabled={isCheckoutDisabled}
          aria-disabled={isCheckoutDisabled}
          size="large"
          onClick={() => {
            if (!isCheckoutDisabled) {
              fireGaEvent(gaCats.order, gaActions.clickCheckOut);
              onCancel();
            }
          }}
        >
          <StringAccessor
            accessor="cart_checkout.continue_to_checkout_cta"
            dataObj={{ subtotal: `$${toDollars(subtotal)}` }}
          />
        </Continue>
      </Link>

      <Cancel onClick={onCancel}>
        <StringAccessor accessor="cart_checkout.back_to_menu_cta" />
      </Cancel>
    </Container>
  );
};

const Container = styled.footer`
  border-block-start: 1px solid var(--sidecart__divider-color);
  display: flex;
  flex-direction: column;
  padding: var(--size-3) var(--size-6) var(--size-3);
  gap: var(--size-3);
`;

const Continue = styled(StyledPrimaryButton).attrs({
  "data-css-override": "StyledCheckoutButton StyledPrimaryButton",
})`
  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
      opacity: 0.8;
      pointer-events: none;
    `}
`;

const Cancel = styled.button(({ theme }) => ({
  appearance: "none",
  background: "none",
  border: "none",
  cursor: "pointer",
  display: "none", // don't surface the "Back to menu" button on mobile to save space
  height: "var(--size-10)",
  transition: "opacity 0.2s ease",
  ...getFontStyles(theme.sidecart.font, [
    "color",
    "font_family",
    "font_style",
    "line_height",
  ]),

  "&:hover": {
    opacity: 0.7,
  },

  "@media (min-width: 768px)": {
    display: "block",
  },
}));
