import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";

export const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid var(--modal__border-color);
  display: grid;
  grid-template-columns: var(--size-6) 1fr var(--size-6);
  padding: var(--size-6);
`;

export const Title = styled(Dialog.Title)`
  color: var(--modal__title-color);
  font-family: var(--modal-title__font-family);
  font-size: var(--modal-title__font-size);
  margin: 0;
  text-align: center;
`;

export const CloseButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  color: var(--modal__color);
  padding: 0;

  & svg {
    display: block;
  }
`;
