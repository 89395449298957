import { CONVEYANCE_TYPES } from "@koala/sdk";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import { DeliveryModalStateContainer } from "./deliveryManager";
import { PickupConfirm } from "@/components/fulfillmentManager/pickupManager";
import GenericModal from "@/components/uielements/genericModal";
import { MODAL } from "@/constants/events";
import conveyanceModeActions from "@/redux/conveyanceMode/actions";
import globalActions from "@/redux/global/actions";
import { type RootState } from "@/types/app";

class FulfillmentManager extends Component<ReduxProps> {
  render() {
    const {
      toggleFulfillmentModal,
      fulfillmentModal,
      checkDeliveryAddress,
      basketFulfillment,
      deliveryAddressValidating,
    } = this.props;

    if (!fulfillmentModal.display) {
      return null;
    }

    return (
      <GenericModal
        modalOpen={fulfillmentModal.display}
        toggleModal={toggleFulfillmentModal}
        disableModalClose={fulfillmentModal.disableModalClose}
        name={MODAL.FULFILLMENT_MANAGER}
      >
        {/* Display Pickup Manager */}
        {fulfillmentModal.type === CONVEYANCE_TYPES.PICKUP && <PickupConfirm />}
        {/* Display Delivery Manager */}
        {fulfillmentModal.type === CONVEYANCE_TYPES.DELIVERY && (
          <DeliveryModalStateContainer
            checkDeliveryAddress={checkDeliveryAddress}
            toggleFulfillmentModal={toggleFulfillmentModal}
            fulfillmentModal={fulfillmentModal}
            basketFulfillment={basketFulfillment}
            deliveryAddressValidating={deliveryAddressValidating}
            // @ts-expect-error ensure that `revalidate` isn't null.
            revalidate={fulfillmentModal.revalidate}
          />
        )}
      </GenericModal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  basketFulfillment: state.app.basket.fulfillment,
  deliveryAddressValidating: state.app.conveyanceMode.loading,
  fulfillmentModal: state.app.global.fulfillmentModal,
});

const mapDispatchToProps = {
  toggleFulfillmentModal: globalActions.toggleFulfillmentModal,
  checkDeliveryAddress: conveyanceModeActions.checkDeliveryAddress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(FulfillmentManager);
