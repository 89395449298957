import { memo, forwardRef, type ChangeEvent } from "react";
import { connect } from "react-redux";
import {
  StyledCardIcon,
  StyledCheckoutComponent,
  StyledLinkButton,
  StyledStepContent,
  StyledStepHeader,
} from "./styles";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { CSS_CLASSES } from "@/constants/cssClassNames";
import { type RootState } from "@/types/app";
import { type IOrderStatusState, OrderStatuses } from "@/types/orderStatus";
import { isUserLoggedInForCheckout } from "@/utils/auth";

export * from "./styles";

interface UiElementProps {
  children: any;
  className?: string;
  id?: string;
}

interface UiElementExtendedProps extends UiElementProps {
  alwaysEnabled?: boolean;
}

export const StepContainer = memo(
  ({ children, className, id }: UiElementProps) => (
    <StyledCheckoutComponent className={className} id={id}>
      {children}
    </StyledCheckoutComponent>
  )
);

// Wrap in forwardRef per
// https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component
export const LinkButton = memo(
  forwardRef((props: UiElementProps & any, ref) => (
    <StyledLinkButton {...props} ref={ref}>
      {props.children}
    </StyledLinkButton>
  ))
);

/**
 * Card Icon
 *
 */
export const CardIcon = memo(
  (props: { type: "api" | "placeholder"; icon: string }) => (
    <StyledCardIcon
      {...props}
      src={`/static/img/card-icons/${props.type}/${props.icon}.svg`}
      alt={`${props.icon} logo`}
      role="presentation"
    />
  )
);

/**
 * Radio Option
 *
 */
interface RadioOptionProps {
  children: React.ReactNode;
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

export const RadioOption = ({
  children,
  id,
  onChange,
  checked,
  name,
}: RadioOptionProps) => (
  <>
    <input
      id={id}
      type="radio"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id}>{children}</label>
  </>
);

/**
 * Connected UI Elements
 *
 */
interface StoreProps {
  orderStatus: IOrderStatusState;
  isUserLoggedIn: boolean;
}
const _StepHeader = memo(
  ({
    alwaysEnabled,
    children,
    orderStatus,
    isUserLoggedIn,
  }: UiElementExtendedProps & StoreProps) => (
    <StyledStepHeader
      className={CSS_CLASSES.CHECKOUT.STEP_HEADER_CONTAINER}
      enabled={
        (orderStatus.status !== OrderStatuses.ORDER_NOT_INITIALIZED &&
          isUserLoggedIn) ||
        alwaysEnabled
      }
    >
      {children}
    </StyledStepHeader>
  )
);
export const StepHeader = connect(
  (state: RootState): StoreProps => ({
    orderStatus: state.app.orderStatus,
    isUserLoggedIn: isUserLoggedInForCheckout(
      state.app.me.data,
      state.app.auth.guestUser
    ),
  })
)(_StepHeader);

const _StepContent = memo(
  ({
    alwaysEnabled,
    children,
    orderStatus,
    isUserLoggedIn,
  }: UiElementExtendedProps & StoreProps) => (
    <StyledStepContent
      enabled={
        (orderStatus.status !== OrderStatuses.ORDER_NOT_INITIALIZED &&
          isUserLoggedIn) ||
        alwaysEnabled
      }
    >
      {children}
    </StyledStepContent>
  )
);
export const StepContent = connect(
  (state: RootState): StoreProps => ({
    orderStatus: state.app.orderStatus,
    isUserLoggedIn: isUserLoggedInForCheckout(
      state.app.me.data,
      state.app.auth.guestUser
    ),
  })
)(_StepContent);

interface ApplyButtonProps {
  loadingStatus: OrderStatuses;
  type: "button" | "submit" | "reset";
  onClick?: any;
  style?: object;
}
const _ConnectedApplyButton = memo(
  ({
    children,
    orderStatus,
    loadingStatus,
    ...otherProps
  }: UiElementProps & StoreProps & ApplyButtonProps) => (
    <StyledPrimaryButton
      submitting={orderStatus.status === loadingStatus}
      {...otherProps}
    >
      {children}
    </StyledPrimaryButton>
  )
);
export const ConnectedApplyButton = connect(
  (state: RootState): StoreProps => ({
    orderStatus: state.app.orderStatus,
    isUserLoggedIn: isUserLoggedInForCheckout(
      state.app.me.data,
      state.app.auth.guestUser
    ),
  })
)(_ConnectedApplyButton);
