import Link from "next/link";
import { type ReactNode } from "react";
import LoyaltyAccessor from "@/components/account/loyaltyAccessor";
import { Stack } from "@/components/ui";
import {
  LOYALTY_ACCESSOR_TYPES,
  type LOYALTY_FEATURES,
} from "@/constants/loyalty";
import { type LOYALTY_ROUTES } from "@/constants/routes";

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  href: string;
  accessor?: LOYALTY_FEATURES | LOYALTY_ROUTES;
}

export function AccountLink({
  children,
  href,
  accessor,
  ...otherProps
}: Props) {
  const component = (
    <Stack
      as="a"
      css={{
        height: "$10",
      }}
      {...otherProps}
    >
      {children}
    </Stack>
  );
  if (accessor) {
    return (
      <LoyaltyAccessor
        checkType={LOYALTY_ACCESSOR_TYPES.ROUTE}
        checkName={accessor}
        component={
          <Link href={href} passHref={true}>
            {component}
          </Link>
        }
      />
    );
  } else {
    return (
      <Link href={href} passHref={true}>
        {component}
      </Link>
    );
  }
}
