import type * as Stitches from "@stitches/react";
import { styled } from "@/components/content/stitches";

interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  css?: Stitches.CSS;
}

export function Box({ children, css = {} }: Props) {
  return <Container css={css}>{children}</Container>;
}

const Container = styled("div", {});
