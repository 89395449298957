import styled from "styled-components";
import { COLORS, LAYOUT, ORDER } from "@/constants/styles";

interface StyledToastLayoutProps {
  status?: string;
}

export const StyledToastLayout = styled.div.attrs({
  "data-css-override": "StyledToastLayout",
})<StyledToastLayoutProps>`
  background-color: ${({ status, theme }) =>
    status !== "error" ? theme.global.primary_active_color : "red"};
  display: block;
  padding: ${LAYOUT.GUTTER}px ${LAYOUT.GUTTER * 2}px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: ${ORDER.TOAST};

  @media (max-width: 767px) {
    padding: ${LAYOUT.GUTTER / 2}px ${LAYOUT.GUTTER * 2}px;
  }
`;

export const ToastContent = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};
  font-size: 16px;
  position: relative;
  text-align: center;

  p {
    margin: 0;
    width: calc(100% - ${LAYOUT.GUTTER * 2}px);
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const StyledForceDismissButton = styled.button`
  border: none;
  background: rgba(255, 255, 255, 0.15);
  color: ${COLORS.WHITE};
  cursor: pointer;
  display: block;
  font-size: 24px;
  height: 100%;
  padding: 0 ${LAYOUT.GUTTER}px;
  position: absolute;
  right: 0;
  top: 0px;
  transition: all 0.2s linear;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`;
