import { getContrast, lighten } from "polished";
import styled, { type StyledProps } from "styled-components";
import { LAYOUT } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safePolished } from "@/utils/safePolished";

export const StyledCheckoutComponent = styled.div(({ theme }) => ({
  background: theme.checkout.background_color ?? "transparent",
  border: `1px solid ${theme.checkout.border_color ?? "transparent"}`,
  borderRadius: `${theme.checkout.border_radius ?? 0}px`,
  width: "100%",
  ...getFontStyles(theme.checkout.font),
}));

interface StyledStepHeaderProps {
  enabled?: boolean;
}

export const StyledStepHeader = styled.div<StyledStepHeaderProps>`
  ${({ enabled, theme }) => `
    --checkout--step-header__border-color: ${
      enabled ? theme.checkout.border_color : "transparent"
    };
    --checkout--step-header__opacity: ${enabled ? 1 : 0.75};
  `}

  border-bottom: 1px solid var(--checkout--step-header__border-color);
  display: flex;
  justify-content: space-between;
  opacity: var(--checkout--step-header__opacity);
  padding: var(--size-4);

  @media (min-width: 768px) {
    padding: var(--size-6);
  }
`;

interface StyledStepContentProps {
  enabled?: boolean;
}

export const StyledStepContent = styled.div<StyledStepContentProps>`
  ${({ enabled }) => `
    --checkout--step-content__display: ${enabled ? "block" : "none"};
  `}

  display: var(--checkout--step-content__display);
  padding: var(--size-4);

  @media (min-width: 768px) {
    padding: var(--size-6);
  }
`;

export const StyledCardIcon = styled.img`
  height: 26px;
  margin-right: ${LAYOUT.GUTTER / 4}px;
`;

interface LinkButtonProps {
  margin?: string | null;
  textAlign?: string;
}
export const StyledLinkButton = styled.button<LinkButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  line-height: normal;
  margin: 0;
  text-align: ${({ textAlign }) => textAlign || "center"};
  text-decoration: underline;
  padding: 0;

  // Ensure minimum ADA contrast on text links
  // @TODO - theme.checkout
  ${({ theme }) =>
    getContrast(
      theme.location_card.background_color ?? "#ffffff",
      theme.dietary_preferences_tag.active_font_color ?? "#000000"
    ) > 4.5
      ? `
    color: ${theme.dietary_preferences_tag.active_font_color ?? "#000000"};
  `
      : `
    color: ${theme.location_card.font.color ?? "#000000"};
    text-decoration: underline;
  `}

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledRadioLegend = styled.legend`
  // Visually hidden considerations
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

interface StyledRadioRowProps {
  mobilePadding?: number;
}

export const StyledRadioRow = styled.div<StyledRadioRowProps>`
  align-items: center;
  display: flex;
  padding-bottom: ${LAYOUT.GUTTER / 2}px;
  width: 100%;
  text-align: left;

  @media (max-width: 767px) {
    padding-bottom: ${({ mobilePadding }: StyledProps<StyledRadioRowProps>) =>
      mobilePadding || LAYOUT.GUTTER / 2}px;
  }

  input,
  label {
    cursor: pointer;
    height: ${LAYOUT.GUTTER * 2}px;
  }

  input {
    appearance: revert;
    height: 24px;
    width: 24px;
  }

  label {
    align-items: center;
    display: flex;
    flex: 1;
    margin-left: ${LAYOUT.GUTTER / 2}px;
  }

  img {
    height: 26px;
    margin-right: ${LAYOUT.GUTTER / 2}px;
  }
`;

export const StyledHeaderText = styled.h2(({ theme }) => ({
  margin: 0,
  ...getFontStyles(theme.checkout.secondary_font),
}));

export const StyledSubheaderText = styled(StyledHeaderText)`
  margin: 0 0 ${LAYOUT.GUTTER / 2}px 0;
`;

export const StyledRemoveButton = styled.button(({ theme }) => {
  const { color: backgroundColor } = getFontStyles(theme.checkout.font);

  return {
    background: "none",
    border: "none",
    cursor: "pointer",
    height: `${theme.forms.field_height}px`,
    padding: "8px",
    width: "40px",

    span: {
      background: backgroundColor
        ? safePolished(lighten)(0.25, backgroundColor)
        : "inherit",
      color: theme.checkout.background_color ?? "inherit",
      borderRadius: "50%",
      display: "block",
      fontSize: "16px",
      height: "24px",
      lineHeight: "24px",
      transition: "all 150ms linear",
      width: "24px",

      "&:hover": {
        background: backgroundColor ?? "inherit",
      },
    },
  };
});
