import Link from "next/link";
import { useRouter } from "next/router";
import { type ReactNode } from "react";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents } from "@/analytics/events/constants";
import { ConnectedPersonIcon } from "@/components/account/uielements";
import { styled } from "@/components/content/stitches";
import { ROUTES } from "@/constants/routes";
import { deriveReferrer } from "@/utils/global";

interface Props {
  children?: ReactNode;
}

export function Signin({ children }: Props) {
  const router = useRouter();
  const loginReferrer = deriveReferrer(router);

  return (
    <Link passHref={true} href={`${ROUTES.LOGIN}?referrer=${loginReferrer}`}>
      <Container
        data-css-override="StyledLinkWithIcon"
        onClick={() => genericEventHandler(GlobalEvents.AUTH__CLICK_SIGN_IN)}
      >
        <ConnectedPersonIcon />
        {children}
      </Container>
    </Link>
  );
}

const Container = styled("a", {
  alignItems: "center",
  background: "none",
  cursor: "pointer",
  display: "flex",
  gap: "$2-5",
});
