import { type SVGProps } from "react";
import { type IconProps } from "./types";

export const Reward = ({
  title,
  ...otherProps
}: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>
    <path
      d="M19.5 12v6.75a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V12M20.25 7.5H3.75a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h16.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75zM12 7.5v12M16.243 6.44C15.182 7.5 12 7.5 12 7.5s0-3.182 1.06-4.243a2.25 2.25 0 0 1 3.183 3.182v0zM7.757 6.44C8.818 7.5 12 7.5 12 7.5s0-3.182-1.06-4.243A2.25 2.25 0 0 0 7.756 6.44v0z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>
);
