import { type ConnectedProps, connect } from "react-redux";
import { withTheme } from "styled-components";
import { StyledItemRecipient } from "./styles";
import { type RootState } from "@/types/app";

interface Props extends ReduxProps {
  recipient: string;
  themeContext: "sidecart" | "checkout";
}

const UserIcon = withTheme((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={props.theme[props.themeContext]?.font.color}
      fillRule="evenodd"
      d="M8.005 8.203c1.125 0 2.063-.995 2.063-2.286 0-1.25-.943-2.224-2.063-2.224-1.114 0-2.062.984-2.057 2.229 0 1.286.932 2.281 2.057 2.281zm3.354 4.771c.709 0 1-.203 1-.656 0-1.339-1.671-3.183-4.359-3.183-2.688 0-4.36 1.844-4.36 3.183 0 .453.292.656 1 .656h6.72z"
      clipRule="evenodd"
    />
  </svg>
));

const _ItemRecipient = ({
  basketLocation,
  locationDetail,
  meOrderDetails,
  themeContext,
  recipient,
}: Props) => {
  if (
    meOrderDetails?.store_location?.supports_item_recipients ||
    basketLocation.supports_item_recipients ||
    // @ts-expect-error ensure that `locationDetail` isn't `null`.
    (!basketLocation?.id && locationDetail.supports_item_recipients)
  ) {
    return (
      <StyledItemRecipient>
        <UserIcon themeContext={themeContext} />

        <span>{recipient}</span>
      </StyledItemRecipient>
    );
  }

  return null;
};

const mapStateToProps = (state: RootState) => ({
  basketLocation: state.app.basket.location,
  locationDetail: state.app.locations.detail,
  meOrderDetails: state.app.me.orderDetails,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export const ItemRecipient = connector(_ItemRecipient);
