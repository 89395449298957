import styled from "styled-components";

export const Main = styled.div`
  overflow-y: scroll;
  padding: var(--size-6);

  @media (min-width: 768px) {
    max-height: var(--size-96);
  }
`;
