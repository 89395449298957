import styled from "styled-components";

interface InteractiveProps {
  canEdit: boolean;
}

export const ItemContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: var(--size-4);
`;

export const Image = styled.img<InteractiveProps>(({ canEdit }) => ({
  aspectRatio: "1 / 1",
  cursor: canEdit ? "pointer" : "inherit",
  flex: "0 0 var(--size-20)",
  objectFit: "cover",
}));

export const Summary = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
});

export const Label = styled.span<InteractiveProps>(({ canEdit }) => ({
  appearance: "none",
  background: "none",
  border: "none",
  color: "var(--config-global__primary-active-color)",
  cursor: canEdit ? "pointer" : "default",
  padding: 0,
  textAlign: "left",

  "&:hover": {
    textDecoration: canEdit ? "underline" : "inherit",
  },
}));

export const Price = styled.span({
  marginLeft: "auto",
});

export const Details = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const Modifier = styled.span.attrs({
  "data-css-override": "StyledProductModifiers",
})({
  color: "var(--sidecart__foreground-color)",
  fontSize: "max(0.75rem, 0.75em)",
  maxWidth: "100%",
});

export const RemoveItemButton = styled.button({
  unset: "all",
  outline: "revert",
  appearance: "none",
  background: "none",
  border: "none",
  cursor: "pointer",
  lineHeight: 1.3,
});

export const CartModalDescription = styled.p({
  fontSize: "var(--config-text__primary-text-size)",
  margin: 0,
});
