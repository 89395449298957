import loadGoogleMapsApi from "load-google-maps-api";
import { useEffect, useState } from "react";
import usePlacesAutocomplete, { type HookArgs } from "use-places-autocomplete";
import { GOOGLE_LIBRARIES } from "@/constants/global";
import { SUPPORTED_COUNTRIES } from "@/constants/locations";
import { useConfigOverride } from "@/redux/cmsConfig";
import { type GoogleAddressComponentFields } from "@/types/fulfillment";
import * as ErrorReporter from "@/utils/errorReporter";

/**
 * takes an array of address components and returns an address object
 */
export const mapAutocompleteAddress = (
  components: google.maps.GeocoderAddressComponent[]
): GoogleAddressComponentFields => {
  const newFormattedAddress: GoogleAddressComponentFields = {};

  components.forEach((component) => {
    const key = component.types[0];

    // @ts-expect-error: key is cast as as string, but since we're working within a returned object, we can ignore this
    newFormattedAddress[key] = component.short_name;
  });

  return newFormattedAddress;
};

/**
 * A wrapper hook to handle loading google maps and returning autocomplete values
 */
export function useAddressAutocomplete() {
  const [options, setOptions] = useState<HookArgs>({
    initOnMount: false,
  });
  const [ready, setReady] = useState(false);
  const { map_key } = useConfigOverride("locations");
  const {
    init,
    value,
    suggestions: { loading, data, status },
    setValue: updateValue,
    clearSuggestions,
  } = usePlacesAutocomplete(options);

  async function tryLoadingGoogleMaps() {
    try {
      const googleMaps = await loadGoogleMapsApi({
        key: map_key,
        libraries: [GOOGLE_LIBRARIES.PLACES],
      });

      setOptions({
        requestOptions: {
          sessionToken: new googleMaps.places.AutocompleteSessionToken(),
          types: ["geocode"],
          componentRestrictions: {
            country: Object.values(SUPPORTED_COUNTRIES),
          },
        },
        googleMaps,
        debounce: 500,
      });

      init();
      setReady(true);
    } catch (e) {
      ErrorReporter.captureException(e);
      console.error(e);
    }
  }

  useEffect(() => {
    void tryLoadingGoogleMaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clearSuggestions,
    data,
    loading,
    ready,
    status,
    updateValue,
    value,
  };
}
