import { type WebConfig } from "@koala/sdk";
import styled from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface ItemStyles {
  hasImage?: boolean;
}
export const Item = styled.div.attrs({
  "data-css-override": "StyledSidecartUpsellItem",
})<ItemStyles>(({ theme, hasImage = false }) => ({
  ...getFontStyles(theme.sidecart.font),
  backgroundColor: theme.sidecart.background_color ?? "transparent",
  borderRadius: `${theme.sidecart.border_radius ?? 0}px`,
  minHeight: "var(--size-20)",
  alignItems: hasImage ? "start" : "center",
  justifyContent: "center",
  border: "1px solid var(--sidecart__divider-color, transparent)",
  display: "grid",
  gridTemplateColumns: hasImage
    ? "var(--size-16) 1fr var(--size-16)"
    : "1fr var(--size-16)",

  gap: "var(--size-4)",
  overflow: "hidden",
  padding: "var(--size-2) 0 var(--size-2) var(--size-2)",
  textAlign: "left",
  width: "100%",
}));

export const Details = styled.div.attrs({
  "data-css-override": "UpsellText",
})`
  overflow: hidden;
  margin: var(--size-1) 0 var(--size-1) 0;
`;

export const Title = styled.h3({
  color: "inherit",
  cursor: "pointer",
  font: "inherit",
  lineHeight: "inherit",
  margin: 0,
});

export const Description = styled.p`
  font-size: max(0.75rem, 0.75em);
  margin: 2px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  //truncate by number of lines (2)
  @supports (display: -webkit-box) {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Units = styled.div`
  align-items: baseline;
  display: flex;
  font-size: 0.75rem;

  span {
    color: rgba(49, 61, 72, 0.5);
    line-height: 1.58;
  }

  h3 {
    flex: 1;
    margin: var(--size-1) 0 0;
    text-align: left;
    font-weight: normal;
    font-family: ${(props: { theme: WebConfig }) =>
      props.theme.primary_font.bold.font_family};
  }
`;

export const Image = styled.img`
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  transition: all 0.2s ease;
  height: 64px;
  width: 64px;
  ${(props: { theme: WebConfig }) => `
     border-radius: ${(props.theme.sidecart.border_radius ?? 0) / 2}px;
  `}

  &:hover {
    opacity: 0.8;
  }
`;

export const QuickAddButton = styled.button`
  height: 64px;
  width: 64px;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: var(--config-primary-button__background-color, black);
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;

  svg {
    display: block;
  }
`;
