import { type SVGProps } from "react";
import { type IconProps } from "./types";

export const Facebook = ({
  title,
  ...otherProps
}: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M12.9898 13.1716V19.2H10.2206V13.1716H7.91998V10.7273H10.2206V9.83792C10.2206 6.53614 11.5999 4.80005 14.5182 4.80005C15.4129 4.80005 15.6366 4.94384 16.1265 5.061V7.47875C15.578 7.38289 15.4235 7.32963 14.8537 7.32963C14.1774 7.32963 13.8153 7.52135 13.4851 7.89946C13.1549 8.27756 12.9898 8.93259 12.9898 9.86987V10.7326H16.1265L15.2851 13.177H12.9898V13.1716Z"
      fill="currentColor"
    />
  </svg>
);
