import { type SVGProps } from "react";
import { type IconProps } from "./types";

export const History = ({
  title,
  ...otherProps
}: IconProps & SVGProps<SVGSVGElement>) => (
  <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>{title}</title>

    <path
      d="M15.897 14.25 12 12M12 7.5V12M6.734 9.348h-3.75v-3.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.166 17.834a8.25 8.25 0 1 0 0-11.668L2.984 9.348"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
