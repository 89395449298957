import { CONVEYANCE_TYPES } from "@koala/sdk";
import styled from "styled-components";
import { genericEventHandler } from "@/analytics/events";
import { EventNames, GlobalEvents } from "@/analytics/events/constants";
import { Heading, Text } from "@/components/cart/text";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { Button } from "@/components/uielements/button/button";
import { Stack } from "@/components/uielements/stack/stack";
import { K_ANALYTICS_CTA_LOCATIONS } from "@/constants/events";
import { useDispatch, useSelector } from "@/redux";
import globalActions from "@/redux/global/actions";
import { useDialogContext } from "@/utils/useDialogContext";

export const DeliveryDetails = () => {
  const dispatch = useDispatch();
  const { onCancel } = useDialogContext();

  const fulfillment = useSelector((state) => state.app.basket.fulfillment);
  const strings = useSelector((state) => state.app.cmsConfig.strings);
  const location = useSelector((state) => state.app.basket.location);

  if (!fulfillment?.address || fulfillment.type !== CONVEYANCE_TYPES.DELIVERY) {
    return null;
  }

  return (
    <Wrapper>
      <Stack direction="vertical" gap="var(--size-1)" align="leading">
        <Heading>
          <StringAccessor accessor="cart_checkout.delivery_address_header" />
        </Heading>

        <Stack
          align="leading"
          direction="vertical"
          gap="0"
          overrides={{
            color: "#595959",
            fontSize: ".875rem",
          }}
        >
          <Address>
            <Text>
              {fulfillment.address.street_address}
              {fulfillment.address.street_address_2 && (
                <>
                  <br />
                  {fulfillment.address.street_address_2}
                </>
              )}
              <br />
              {`${fulfillment.address.city}, ${
                fulfillment.address.state || ""
              } ${fulfillment.address.zip_code}`}
            </Text>
          </Address>
        </Stack>
      </Stack>

      {strings.cart_checkout?.change_location_cta && (
        <Button
          onClick={() => {
            // TODO: Remove that function call when all modal changes go out.
            // That call is temp fix for focus tab when we have sidecart open and trying to open delivery modal
            onCancel();

            dispatch(
              globalActions.toggleFulfillmentModal(
                true,
                location,
                CONVEYANCE_TYPES.DELIVERY
              )
            ),
              genericEventHandler(GlobalEvents.GENERIC__CTA, {
                name: EventNames.CHANGE_DELIVERY_ADDRESS,
                details: K_ANALYTICS_CTA_LOCATIONS.SIDECART,
              });
          }}
        >
          <StringAccessor accessor="cart_checkout.change_delivery_address_cta" />
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  gap: "var(--size-1)",
  borderTop: "1px solid var(--sidecart__divider-color)",
  width: "100%",
  padding: "var(--size-6)",

  // Move Change Location CTA to right side to create more space in side cart when mobile view
  "& > :last-child": {
    marginLeft: "auto",
  },

  "@media (min-width: 768px)": {
    flexDirection: "column",
    padding: "var(--size-6) var(--size-24) var(--size-6) var(--size-6)",

    "& > :last-child": {
      marginLeft: 0,
    },
  },
});

const Address = styled.address({
  fontStyle: "normal",
});
