import { type DeliveryAddress, type StoredAddress } from "@koala/sdk/v4";
import styled from "styled-components";
import { RadioInput } from "@/components/ui/form/radio";
import { useSavedAddresses } from "@/features/loyalty/addresses";

interface Props {
  onUpdate: (address: StoredAddress) => void;
  value?: DeliveryAddress | null;
}

export function SavedAddresses({ onUpdate, value }: Props) {
  const { data, isSuccess } = useSavedAddresses();

  if (!isSuccess) {
    return null;
  }

  return (
    <form>
      <Container>
        {data.data.map((address, index) => (
          <Item key={`address-${index}`}>
            <RadioInput
              value={String(address.id)}
              checked={Boolean(
                value && Number(address.id) === Number(value?.id)
              )}
              name="saved-address"
              onChange={() => onUpdate(address)}
            >
              {/* Address details */}
              {address.street_address}
              {address.street_address_2 && (
                <>,&nbsp;{address.street_address_2}</>
              )}
              ,&nbsp;{address.city}, {address?.zip_code}
            </RadioInput>
          </Item>
        ))}
      </Container>
    </form>
  );
}

const Container = styled.ul({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  margin: 0,
  padding: 0,
});

const Item = styled.li({
  listStyle: "none",
});
