import { darken, lighten, readableColor } from "polished";
import styled from "styled-components";
import { COLORS } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { safePolished } from "@/utils/safePolished";

export const StyledQuantityToggle = styled.div`
  span {
    font-family: ${({ theme }) => theme.primary_font.medium.font_family};
    font-size: 16px;
    padding-left: 12px;
  }
`;

interface StyledQuantityToggleButtonProps {
  inactive?: boolean;
}

export const StyledQuantityToggleButton =
  styled.button<StyledQuantityToggleButtonProps>(({ theme, inactive }) => {
    const { color } = getFontStyles(theme.modals.font);
    return {
      transition: "all 150ms linear",
      border: "none",
      borderRadius: "50%",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: "bold",
      height: "30px",
      lineHeight: "30px",
      margin: "0 0 0 12px",
      padding: "0",
      pointerEvents: inactive ? "none" : "auto",
      width: "30px",
      background: inactive
        ? lighten(0.2, theme.modals.font.color ?? COLORS.DARKERGRAY)
        : color,

      color:
        /** @FIXME font refactor (what happens when color isn't a hex value). */
        theme.modals.background_color ?? safePolished(readableColor)(color),
      "&:hover": {
        background: inactive
          ? `${lighten(0.2, color ?? COLORS.DARKERGRAY)}`
          : `${safePolished(darken)(0.2, color ?? COLORS.PRIMARY_NAVY)}`,
      },
    };
  });
