import { ModalContainer } from "./container";
import { ModalContent } from "./content";
import { ModalFooter, ModalCancel } from "./footer";
import { ModalTitle } from "./title";

export const Modal = {
  Container: ModalContainer,
  Title: ModalTitle,
  Footer: ModalFooter,
  Content: ModalContent,
  Cancel: ModalCancel,
};
