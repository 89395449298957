import { type HTMLAttributes, type PropsWithChildren } from "react";
import styled from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface Props extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  name?: string;
  value?: string;
}

export const RadioInput = ({
  children,
  checked,
  ...otherProps
}: PropsWithChildren<Props>) => (
  <Label>
    <Input type="radio" checked={checked} {...otherProps} />
    <span>{children}</span>
  </Label>
);

const Label = styled.label(({ theme }) => ({
  display: "flex",
  gap: ".625rem",
  ...getFontStyles(theme.forms.font),
}));

const Input = styled.input(({ theme }) => ({
  all: "unset",
  border: "2px solid",
  borderColor: theme.forms.border_color ?? "#ddd",
  boxSizing: "border-box",
  borderRadius: "100%",
  height: "1.125rem",
  outline: "revert",
  position: "relative",
  width: "1.125rem",

  "&:checked": {
    borderColor: theme.global.primary_active_color ?? "black",
  },

  "&:checked:after": {
    backgroundColor: theme.global.primary_active_color ?? "black",
    borderRadius: "100%",
    content: "''",
    inset: "1px",
    position: "absolute",
  },
}));
