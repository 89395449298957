export function PickupIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pickup Icon</title>
      <path
        d="M13 4H3C2.72386 4 2.5 4.22386 2.5 4.5V12.5C2.5 12.7761 2.72386 13 3 13H13C13.2761 13 13.5 12.7761 13.5 12.5V4.5C13.5 4.22386 13.2761 4 13 4Z"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 4C5.5 3.33696 5.76339 2.70107 6.23223 2.23223C6.70107 1.76339 7.33696 1.5 8 1.5C8.66304 1.5 9.29893 1.76339 9.76777 2.23223C10.2366 2.70107 10.5 3.33696 10.5 4"
        stroke="currentColor"
        strokeWidth="0.96875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
