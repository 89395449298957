import { type OrderDetails } from "@koala/sdk";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { ReorderConfirmMissingItems } from "./confirm-missing-items";
import { ReorderForm } from "./form";
import GenericModal from "@/components/uielements/genericModal";
import { MODAL } from "@/constants/events";
import { ROUTES } from "@/constants/routes";
import { selectBasketSlice } from "@/redux/basket";
import basketActions from "@/redux/basket/actions";
import { selectAccountSlice } from "@/redux/me";
import meActions from "@/redux/me/actions";

export const ReorderModal = () => {
  const { pathname } = useRouter();

  const dispatch = useDispatch();
  const { orderConfirmMissingItems, loading: basketLoading } =
    useSelector(selectBasketSlice);
  const { reorderModalOpen, orderDetails } = useSelector(selectAccountSlice);

  const { reorderBasket } = basketActions;
  const { toggleReorderReceipt, clearMeOrderDetails } = meActions;

  const reorderAnalyticsLabel =
    pathname === ROUTES.STORE ? "Store Menu" : "Store Locator";

  return (
    <GenericModal
      modalOpen={reorderModalOpen}
      name={MODAL.REORDER_PAST_ORDER}
      toggleModal={(show) => void dispatch(toggleReorderReceipt(show))}
      onClose={() => void dispatch(clearMeOrderDetails())}
    >
      {!orderConfirmMissingItems && orderDetails && (
        <ReorderForm
          meOrderDetails={orderDetails}
          reorderBasket={(order: OrderDetails, label: string) => {
            dispatch(reorderBasket(order, label));
          }}
          basketLoading={basketLoading}
          route={pathname}
        />
      )}

      {orderConfirmMissingItems && (
        <ReorderConfirmMissingItems
          onConfirm={() => {
            dispatch(
              /** @TODO ensure that `orderDetails` is defined. */
              // @ts-expect-error
              reorderBasket(orderDetails, reorderAnalyticsLabel, false, true)
            );
          }}
          onCancel={() => {
            dispatch(toggleReorderReceipt(false));
          }}
          basketLoading={basketLoading}
        />
      )}
    </GenericModal>
  );
};
